import { UserModel } from './../model/users.model';
import { CheckinModel } from './../model/checkin.model';
import { Component, OnInit } from '@angular/core';
import { CheckInService } from '../service/check-in.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import * as _ from "underscore";


@Component({
  selector: 'app-ps-check-in',
  templateUrl: './ps-check-in.component.html',
  styleUrls: ['./ps-check-in.component.css']
})
export class PsCheckInComponent implements OnInit {
  public loading = false;
  public checkinList: Array<CheckinModel> = new Array<CheckinModel>();
  public checkin = new CheckinModel();
  public isCheck: boolean = false;
  public userLogin: UserModel = new UserModel();
  public showDate: string = "";
  constructor(
    private router: Router,
    private checkInService: CheckInService
  ) { }

  ngOnInit() {
    this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
    if (undefined == this.userLogin || !this.userLogin) {
      this.router.navigate(['/login']);
    }
    this.getCheckToday(this.userLogin.idUser)
    this.getcheckinList()
  }

  getCheckToday(idUser) {
    var checkin = new CheckinModel();
    const dateNow = new Date();
    checkin.idUser = idUser;
    checkin.date = moment(dateNow).format('DD-MM-YYYY');
    this.showDate = moment(dateNow).format('dddd Do MMMM YYYY');
    this.loading = true;
    this.checkInService.getCheckinList(checkin).then(res => {
      if (res.responseCode == 200) {
        this.checkin = res.data[0];
        this.isCheck = true
        this.loading = false;
      } else {
        this.loading = false;
      }
    })
  }

  getcheckinList() {
    this.loading = true;
    let date = moment(new Date()).format('DD-MM-YYYY');
    this.checkin.idUser = this.userLogin.idUser;
    this.checkInService.getCheckinList(this.checkin).then(res => {
      if (res.responseCode == 200) {
        this.checkinList = res.data;
        this.checkinList = _.reject(this.checkinList, function (item) {
          return item.date === date; // or some complex logic
        });
      };
      // this.checkinList.forEach(ele => {

      // })
      this.loading = false;
    });
    // })
  }

  onCheckboxChagen() {
    const dateNow = new Date();
    this.checkin.idUser = this.userLogin.idUser;
    this.checkin.startTime = moment(dateNow).format('HH:mm');
    this.checkin.date = moment(dateNow).format('DD-MM-YYYY');
    this.showDate = moment(dateNow).format('dddd Do MMMM YYYY');
    // this.checkin.startTime = moment(new Date()).format('HH:mm');
    // this.checkin.date = moment(new Date()).format('DD-MM-YYYY');
    this.checkInService.postCheckin(this.checkin).then(res => {
      if (res.responseCode == 200) {
        this.loading = false;
        this.isCheck = true
      }
    })
  }

  onCheckOut(check: CheckinModel) {
    console.log("checkin.startTime",this.checkin.startTime);
    this.router.navigate(['/new-timesheets-ps/' + check._id]);
  }

  checkValue(event: any) {
    console.log(event)
    if (event) {
      this.isCheck = true;
      // this.defaultCode = code;
      // this.timeSheetList.forEach(ele => {
      //     ele.projectCode = code
      // })
      // console.log(this.defaultCode)
    } else {
      // this.defaultCode = '';
      // var i = 0;
      // this.timeSheetList.forEach(ele => {
      //     if (i != 0) {
      //         ele.projectCode = ''
      //     }
      // })
    }

  }

}
