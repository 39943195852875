import { PsNewLeaveWorkComponent } from './ps-new-leave-work/ps-new-leave-work.component';
import { MyProjectComponent } from './my-project/my-project.component';
import { PsNewTimeSheetComponent } from './ps-new-time-sheet/ps-new-time-sheet.component';
import { HomePsComponent } from './home-ps/home-ps.component';
import { EditFunctionComponent } from './edit-function/edit-function.component';
import { CreateNewFunctionsComponent } from './create-new-functions/create-new-functions.component';
import { IndexComponent } from './index/index.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { EditProjectComponent } from './edit-project/edit-project.component';
import { HomeUserComponent } from './home-user/home-user.component';
import { ViewUserAllComponent } from './view-user-all/view-user-all.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { ViewProjectAllComponent } from './view-project-all/view-project-all.component';
import { CreateNewProjectComponent } from './create-new-project/create-new-project.component';
import { EditTimesheetComponent } from './edit-timesheet/edit-timesheet.component';
import { ViewAllTimeSheetComponent } from './view-all-time-sheet/view-all-time-sheet.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ProjectComponent } from './project/project.component';
import { CreateTimeSheetComponent } from './create-time-sheet/create-time-sheet.component';
import { ViewTimeSheetComponent } from './view-time-sheet/view-time-sheet.component';
import { RepasswordComponent } from './repassword/repassword.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { ViewProfileComponent } from './view-profile/view-profile.component';
import { CreateConfigComponent } from './create-config/create-config.component';
import { EditConfigComponent } from './edit-config/edit-config.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'index', component: IndexComponent },
  { path: 'login', component: LoginComponent },
  // { path: 'project/:role', component: ProjectComponent},
  { path: 'home', component: ProjectComponent },
  { path: 'new-timesheets', component: CreateTimeSheetComponent },
  { path: 'view-timesheets-all', component: ViewAllTimeSheetComponent },
  { path: 'view-timesheets', component: ViewTimeSheetComponent },
  { path: 'edit-timesheets/:id', component: EditTimesheetComponent },
  { path: 'new-projects', component: CreateNewProjectComponent },
  { path: 'change-password', component: RepasswordComponent },
  { path: 'view-all-project', component: ViewProjectAllComponent },
  { path: 'new-users', component: CreateUserComponent },
  { path: 'view-users', component: ViewUserAllComponent },
  { path: 'edit-profile', component: EditProfileComponent },
  { path: 'home-user', component: HomeUserComponent },
  { path: 'view-profile/:id', component: ViewProfileComponent },
  { path: 'new-config', component: CreateConfigComponent },
  { path: 'edit-config', component: EditConfigComponent },
  { path: 'edit-project/:id', component: EditProjectComponent },
  { path: 'edit-users/:id', component: EditUserComponent },
  { path: 'new-function', component: CreateNewFunctionsComponent },
  { path: 'edit-function/:id', component: EditFunctionComponent },
  { path: 'edit-myproject', component: MyProjectComponent },

  { path: 'home-ps', component: HomePsComponent },
  { path: 'new-timesheets-ps/:id', component: PsNewTimeSheetComponent },
  { path: 'new-leave-ps', component: PsNewLeaveWorkComponent },
  { path: '**', redirectTo: 'index' }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
