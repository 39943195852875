import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from '../shared/url-constant';
import { UserModel } from '../model/users.model';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterExtService } from '../service/routerExtService.service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { EmployeeService } from '../service/employee.service';
import { EmployeeModel } from '../model/employee.model';
import * as _ from 'underscore'

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  public userLogin: UserModel = new UserModel();
  public member = new UserModel();
  public fileData: File = null;
  public thumbnail: any;
  public image;
  public base64textString;
  public croppedImageImg;

  public imageChangedEvent: any = '';
  public croppedImage: any = '';

  public dynamicArray: Array<any> = [];
  public newDynamic: any = {};
  public loading: boolean = false;
  public success: boolean = false;
  public successSkill: boolean = false;
  public successPerson: boolean = false;
  public successHobbies: boolean = false;
  public modeEditPerson: boolean = false;
  public modeEditSkill: boolean = false;
  public modeEditPic: boolean = false;
  public modeEditHobbies: boolean = false;

  public personal = new EmployeeModel();

  public dynamicHobArray: Array<any> = [];


  constructor(
    public http: HttpClient,
    private sanitizer: DomSanitizer,
    private routerService: RouterExtService,
    private router: Router,
    private ng2ImgMax: Ng2ImgMaxService,
    private employeeService: EmployeeService
  ) { }

  ngOnInit() {

    this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
    this.getEditProfile();
    this.getProfile();
  }

  getProfile() {
    let userModel = new UserModel()
    userModel.idUser = this.userLogin.idUser;
    this.employeeService.getEmployeeByCriteria(userModel).then(res => {
      if (res.responseCode == 200) {
        this.member = res.data[0];
        if (this.member.img == "" || this.member.img == undefined) {
          this.member.img = null
          this.croppedImage = null
        } else {
          this.croppedImage = this.member.img
        }
      }
    });
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }

  changeListener($event): void {
    var fileTemp: File
    let image = $event.target.files[0];
    this.ng2ImgMax.resizeImage(image, 300, 300).subscribe(
      result => {
        fileTemp = result;
        fileTemp = new File([fileTemp], fileTemp.name);
        this.readThis(fileTemp);
      },
      error => {
        console.log('Oh no!', error);
      }
    );
  }

  readThis(inputValue: any): void {
    this.success = false;
    var file: File = inputValue;
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      this.image = myReader.result;
      this.http.post(API.POST_PIC, { file: this.image, idUser: this.userLogin.idUser, _id: this.userLogin._id })
        .subscribe(res => {
          this.getProfile();
          // alert('SUCCESS !!');
          this.success = true;
          this.modeEditPic = false;
          setTimeout(() => {
            this.success = false;
          }, 1000);
          this.imageChangedEvent = '';
          // location.reload();
        })
    }
    myReader.readAsDataURL(file);
  }

  editPicture() {
    this.modeEditPic = true;
  }

  getImageData() {
    if (this.imageChangedEvent != '' && this.imageChangedEvent != null) {
      fetch(this.croppedImage)
        .then(res =>
          res.blob()
        ).then(blob => {
          var fileTemp: File
          fileTemp = new File([blob], 'a');
          this.readThis(fileTemp);
        });
    }
  }

  addRow(index) {
    this.newDynamic = { name: "", value: "" };
    this.dynamicArray.push(this.newDynamic);
    // console.log(this.dynamicArray);
    return true;
  }

  deleteRow(index) {
    if (this.dynamicArray.length == 1) {
      this.dynamicArray.splice(index, 1);
      return true;
    } else {
      this.dynamicArray.splice(index, 1);
      // console.log(this.dynamicArray);
      return true;
    }
  }

  getEditProfile() {
    let userModel = new UserModel()
    userModel.idUser = this.userLogin.idUser;
    this.employeeService.getEmployeeByCriteria(userModel).then(res => {
      if (res.responseCode == 200) {
        this.member = res.data[0];
        this.personal = res.data[0];
        this.dynamicArray = this.member.skill
        this.dynamicHobArray = this.member.hobbies;
        // console.log(this.dynamicArray)
        this.member.skill.forEach(element => {
          if (element.value == '') {
            element.star = 0;
          } else {
            element.star = Array(element.value).fill(1).map((x, i) => i);
          }
        });
      }
    });
  }



  saveSkill() {
    this.successSkill = false;
    this.loading = true;
    let emp = new EmployeeModel();
    emp._id = this.userLogin._id;
    emp.skill = _.reject(this.dynamicArray, function (num) { return num.name == ''; });
    this.employeeService.putEmployee(emp).then(res => {
      if (res.responseCode == 200) {
        this.getEditProfile();
      }
      this.successSkill = true;
      this.modeEditSkill = false;
      setTimeout(() => {
        this.successSkill = false;
      }, 1000);
      this.loading = false;
    });
  }

  editSkill() {
    this.modeEditSkill = true;
  }

  savePersonal(personal) {
    this.loading = true;
    this.successPerson = false;
    personal._id = this.userLogin._id;
    this.employeeService.putEmployee(personal).then(res => {
      if (res.responseCode == 200) {
        this.getEditProfile();
      }
      this.modeEditPerson = false;
      this.successPerson = true;
      setTimeout(() => {
        this.successPerson = false;
      }, 1000);
      this.loading = false;
    });
  }

  editPersonal(personal) {
    this.modeEditPerson = true;
  }

  addRowHob(index) {
    this.newDynamic = { name: "", value: "" };
    this.dynamicHobArray.push(this.newDynamic);
    // console.log(this.dynamicHobArray);
    return true;
  }

  deleteRowHob(index) {
    if (this.dynamicHobArray.length == 1) {
      this.dynamicHobArray.splice(index, 1);
      return true;
    } else {
      this.dynamicHobArray.splice(index, 1);
      return true;
    }
  }

  saveHobbies(personal) {
    this.loading = true;
    this.successHobbies = false;
    personal._id = this.userLogin._id;
    personal.hobbies = _.reject(this.dynamicHobArray, function (num) { return num.name == ''; });
    this.employeeService.putEmployee(personal).then(res => {
      if (res.responseCode == 200) {
        this.getEditProfile();
      }
      this.modeEditHobbies = false;
      this.successHobbies = true;
      setTimeout(() => {
        this.successHobbies = false;
      }, 1000);
      this.loading = false;
    });
  }

  editHobbies() {
    this.modeEditHobbies = true;
  }



  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  cancel() {
    this.router.navigate(['/home-user']);
  }

}
