import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PassedParamsService } from 'src/app/service/passed-params';

@Component({
    selector: 'app-menu-admin',
    templateUrl: './menu-admin.component.html',
    styleUrls: ['./menu-admin.component.css']
})
export class MenuAdminComponent implements OnInit {
    public selectTab = "";

    constructor(
        private paramsService: PassedParamsService,
        private router: Router
    ) {
        this.selectTab = paramsService.getAdminMenu();
    }

    ngOnInit() {
    }

    selectTabAction(tab: string) {
        this.selectTab = tab;
        this.paramsService.setAdminMenu(tab);
        this.router.navigateByUrl('/', { skipLocationChange: true }).then( () => this.router.navigate(['/home']))
    }
}
