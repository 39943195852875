export class UserModel {
    public _id: any;
    public username: string = '';
    public fName: string = '';
    public lName: string = '';
    public password: string = '';
    public role: string = '';
    public idUser: string = '';
    public position: string = '';
    public img: string = '';
    public imgBase: any = '';
    public active: any = '';
    public skill: any;
    public skillArray: any = '';
    public hobbies: any;
    public nickname: string = '';
}