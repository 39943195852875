import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from '../shared/url-constant';
import { Response } from '../model/response';
import { PsLeaveModel } from '../model/psLeave.model';
@Injectable({
  providedIn: 'root'
})
export class LeaveWorkService {

  constructor(
    public http: HttpClient
  ) { }

  postLeave(leave) {
    return this.http.post<Response>(API.POST_LEAVE_WORK, { message: leave }).toPromise();
  }

  getLeaveList(leave: PsLeaveModel) {
    let httpParams = new HttpParams();
    if (undefined != leave.idUser && leave.idUser) {
      httpParams = httpParams.set('idUser', leave.idUser);
    }
    if (undefined != leave.startDate && leave.startDate) {
      httpParams = httpParams.set('startDate', leave.startDate);
    }
    if (undefined != leave.endDate && leave.endDate) {
      httpParams = httpParams.set('endDate', leave.endDate);
    }
    if (undefined != leave.typeLeave && leave.typeLeave) {
      httpParams = httpParams.set('typeLeave', leave.typeLeave);
    }
    const reqOptions = {
      params: httpParams
    };
    return this.http.get<Response>(API.GET_LEAVE_WORK, reqOptions).toPromise();
  }

  delLeave(leave: PsLeaveModel) {
    let httpParams = new HttpParams();
    if (leave.idUser != "" && leave.idUser != undefined) {
      httpParams = httpParams.append('idUser', leave.idUser);
    }
    if (leave._id != "" && leave._id != undefined) {
      httpParams = httpParams.append('_id', leave._id);
    }
    const requestOptions = {
      params: httpParams
    };

    return this.http.get<any>(API.DEL_PS_LEAVE, requestOptions);

  }

  getPsLeaveListReport(leave: PsLeaveModel) {
    let httpParams = new HttpParams();

    if (undefined != leave.idUser && leave.idUser) {
      httpParams = httpParams.set('idUser', leave.idUser);
    }
    const reqOptions = {
      params: httpParams
    };
    return this.http.get<Response>(API.GET_LEAVE_WORK_REPORT, reqOptions).toPromise();
  }
}
