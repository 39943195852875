import { RoutineModel } from './../model/routine.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from '../shared/url-constant';
import { Response } from '../model/response';
import { UserModel } from '../model/users.model';

@Injectable({
    providedIn: 'root'
})
export class RoutineService {
    userLogin: UserModel = new UserModel();

    constructor(
        public http: HttpClient
    ) {

    }

    postRoutine(routine) {
        this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
        if (this.userLogin.role.toUpperCase() == "PROFESSIONAL SERVICE") {
            let httpParams = new HttpParams();

            if (routine.checkinId != "" && routine.checkinId != undefined) {
                httpParams = httpParams.append('checkinId', routine.checkinId);
            }
            const requestOptions = {
                params: httpParams
            };
            return this.http.post<Response>(API.POST_PS_ROUTINE, { message: routine }, requestOptions).toPromise();
        } else {
            return this.http.post<Response>(API.POST_ROUTINE, { message: routine }).toPromise();
        }

    }

    getRoutineCriteria(routine: RoutineModel) {
        let httpParams = new HttpParams();
        if (undefined != routine.projectCode && routine.projectCode) {
            httpParams = httpParams.set('projectCode', routine.projectCode);
        }
        if (undefined != routine.idUser && routine.idUser) {
            httpParams = httpParams.set('idUser', routine.idUser);
        }
        if (undefined != routine.month && routine.month) {
            httpParams = httpParams.set('month', routine.month);
        }
        if (undefined != routine._id && "" != routine._id) {
            httpParams = httpParams.append('_id', routine._id);
        }
        if (undefined != routine.year && "" != routine.year) {
            httpParams = httpParams.append('year', routine.year);
        }
        if (routine.ot == true || routine.ot == false) {
            httpParams = httpParams.append('ot', routine.ot == true? "true" : "false");
        }
        const reqOptions = {
            params: httpParams
        };
        return this.http.get<Response>(API.GET_ROUTINE, reqOptions).toPromise();
    }

    getRoutineOrigin(routine: RoutineModel) {
        let httpParams = new HttpParams();
        if (undefined != routine.projectCode && "" != routine.projectCode) {
            httpParams = httpParams.set('projectCode', routine.projectCode);
        }
        if (undefined != routine.idUser && "" != routine.idUser) {
            httpParams = httpParams.set('idUser', routine.idUser);
        }
        if (undefined != routine.month && "" != routine.month) {
            httpParams = httpParams.set('month', routine.month);
        }
        if (undefined != routine._id && "" != routine._id) {
            httpParams = httpParams.append('_id', routine._id);
        }
        const reqOptions = {
            params: httpParams
        };
        this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
        if (this.userLogin.role.toUpperCase() == "PROFESSIONAL SERVICE") {
            return this.http.get<any>(API.GET_PS_ROUTINE_ORIGIN, reqOptions).toPromise();
        } else {
            return this.http.get<Response>(API.GET_ROUTINE_ORIGIN, reqOptions).toPromise();
        }

    }

    delRoutine(routine: RoutineModel) {
        let httpParams = new HttpParams();
        if (routine.idUser != "" && routine.idUser != undefined) {
            httpParams = httpParams.append('idUser', routine.idUser);
        }
        if (routine._id != "" && routine._id != undefined) {
            httpParams = httpParams.append('_id', routine._id);
        }
        if (routine.month != "" && routine.month != undefined) {
            httpParams = httpParams.append('month', routine.month);
        }
        const requestOptions = {
            params: httpParams
        };
        this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
        if (this.userLogin.role.toUpperCase() == "PROFESSIONAL SERVICE") {
            return this.http.get<any>(API.DEL_PS_ROUTINE_ORIGIN, requestOptions);
        } else {
            return this.http.get<any>(API.DEL_ROUTINE_ORIGIN, requestOptions);
        }

    }

    getListRoutineView(routineModel: RoutineModel) {
        let httpParams = new HttpParams();
        if (routineModel.projectCode != null && routineModel.projectCode != undefined && routineModel.projectCode != "") {
            httpParams = httpParams.append('projectCode', routineModel.projectCode);
        }
        if (routineModel.idUser != null && routineModel.idUser != undefined && routineModel.idUser != "") {
            httpParams = httpParams.append('idUser', routineModel.idUser);
        }
        if (routineModel.year != null && routineModel.year != undefined && routineModel.year != "") {
            httpParams = httpParams.append('year', routineModel.year);
        }
        if (routineModel.month != null && routineModel.month != undefined && routineModel.month != "") {
            httpParams = httpParams.append('month', routineModel.month);
        }
        if (routineModel.monthArray != null && routineModel.monthArray != undefined) {
            httpParams = httpParams.append('monthArray', routineModel.monthArray.toString());
        }
        const requestOptions = {
            params: httpParams
        };
        this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
        if (this.userLogin.role.toUpperCase() == "PROFESSIONAL SERVICE") {
            return this.http.get<any>(API.GET_PS_ROUTINE, requestOptions).toPromise();
        } else {
            return this.http.get<any>(API.GET_ROUTINE_VIEW, requestOptions).toPromise();
        }

    }

    updateRoutine(routine) {
        this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
        this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
        if (this.userLogin.role.toUpperCase() == "PROFESSIONAL SERVICE") {
            return this.http.put<Response>(API.PUT_PS_ROUTINE, { message: routine }).toPromise();
        } else {
            return this.http.put<Response>(API.PUT_ROUTINE, { message: routine }).toPromise();
        }

    }

    getDistinctProjectCode(idUser) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('idUser', idUser);
        const requestOptions = {
            params: httpParams
        };
        return this.http.get<any>(API.GET_DISTINCT_CODE, requestOptions).toPromise();
    }

    getDistinctUser(projectCode) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('projectCode', projectCode);
        const requestOptions = {
            params: httpParams
        };
        return this.http.get<any>(API.GET_DISTINCT_USER, requestOptions).toPromise();
    }

    getListRoutineViewPs(routineModel: RoutineModel) {
        let httpParams = new HttpParams();
        if (routineModel.projectCode != null && routineModel.projectCode != undefined && routineModel.projectCode != "") {
            httpParams = httpParams.append('projectCode', routineModel.projectCode);
        }
        if (routineModel.idUser != null && routineModel.idUser != undefined && routineModel.idUser != "") {
            httpParams = httpParams.append('idUser', routineModel.idUser);
        }
        if (routineModel.year != null && routineModel.year != undefined && routineModel.year != "") {
            httpParams = httpParams.append('year', routineModel.year);
        }
        if (routineModel.month != null && routineModel.month != undefined && routineModel.month != "") {
            httpParams = httpParams.append('month', routineModel.month);
        }
        if (routineModel.monthArray != null && routineModel.monthArray != undefined) {
            httpParams = httpParams.append('monthArray', routineModel.monthArray.toString());
        }
        const requestOptions = {
            params: httpParams
        };
        return this.http.get<any>(API.GET_PS_ROUTINE, requestOptions).toPromise();


    }

    getPSRoutineCriteria(routine: RoutineModel) {
        let httpParams = new HttpParams();
        if (undefined != routine.projectCode && routine.projectCode) {
            httpParams = httpParams.set('projectCode', routine.projectCode);
        }
        if (undefined != routine.idUser && routine.idUser) {
            httpParams = httpParams.set('idUser', routine.idUser);
        }
        if (undefined != routine.month && routine.month) {
            httpParams = httpParams.set('month', routine.month);
        }
        if (undefined != routine._id && "" != routine._id) {
            httpParams = httpParams.append('_id', routine._id);
        }
        if (undefined != routine.year && "" != routine.year) {
            httpParams = httpParams.append('year', routine.year);
        }
        if (routine.ot == true || routine.ot == false) {
            httpParams = httpParams.append('ot', routine.ot == true? "true" : "false");
        }
        const reqOptions = {
            params: httpParams
        };
        return this.http.get<Response>(API.GET_PS_ROUTINE_CRITERIA, reqOptions).toPromise();
    }
    
}
