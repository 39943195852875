import { LovModel } from './../model/lov';
import { RoutineService } from './../service/routine.service';
import { Component, OnInit } from '@angular/core';
import { RoutineModel } from '../model/routine.model';
import * as _ from "underscore";
import { UserModel } from '../model/users.model';
import { LovService } from '../service/lov.service';
import { EmployeeService } from '../service/employee.service';
import { DomSanitizer } from '../../../node_modules/@angular/platform-browser';
import { Router } from '../../../node_modules/@angular/router';

@Component({
  selector: 'app-dash-board',
  templateUrl: './dash-board.component.html',
  styleUrls: ['./dash-board.component.css']
})
export class DashBoardComponent implements OnInit {
  public routineList: Array<RoutineModel> = new Array<RoutineModel>();
  public memberList: Array<UserModel> = new Array<UserModel>();
  public projectList: Array<LovModel> = new Array<LovModel>();
  public loading = false;
  public search = {
    userName: '',
    position: 'A'
  };
  public quarterA = [
    'January',
    'February',
    'March',
  ]
  public quarterB = [
    'April',
    'May',
    'June',
  ]
  public quarterC = [
    'July',
    'August',
    'September',
  ]

  public quarterD = [
    'October',
    'November',
    'December',
  ]
  public monthList = [
    { code: '01', name: 'January' },
    { code: '02', name: 'February' },
    { code: '03', name: 'March' },
    { code: '04', name: 'April' },
    { code: '05', name: 'May' },
    { code: '06', name: 'June' },
    { code: '07', name: 'July' },
    { code: '08', name: 'August' },
    { code: '09', name: 'September' },
    { code: '10', name: 'October' },
    { code: '11', name: 'November' },
    { code: '12', name: 'December' },
  ]
  public searchProject = {
    projectCode: '',
    status: 'A',
    quarter: 'A'
  };
  public projectCode: string;
  public listProject: any[];

  public userLogin: UserModel = new UserModel();
  public isAdmin: boolean = true;
  public modeEdit: boolean = false;
  public selectedwallet = 'goal'
  public mandayAll;
  public mandayPoint;
  constructor(
    private routineService: RoutineService,
    private lovService: LovService,
    private employeeService: EmployeeService,
    private LovService: LovService,
    private sanitizer: DomSanitizer,
    private router: Router
  ) { }
  public quarter = 4; //Get form DB
  public g = 6000; //Get form DB
  public a = 3422; //Get form DB
  public goal = this.g.toString().split("");
  public actual = this.a.toString().split("");
  public color = [
    "#FFFFCC"
  ]

  public distinct: string = '';
  ngOnInit() {
    this.loading = true;
    this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
    if (this.userLogin.role.toUpperCase() != "ADMIN") {
      this.isAdmin = false;
    }
    this.searchProject.quarter = '4';
    this.getRoutineAll(this.searchProject);
    this.getGoal();
    // this.getMemberList('A');
    this.getProjectName();
  }

  getRoutineAll(searchProject) {
    let routine: RoutineModel = new RoutineModel();
    routine.year = '2019'
    if ("" != searchProject.projectCode && undefined != searchProject.projectCode) {
      routine.projectCode = searchProject.projectCode
    }
    if ("" != searchProject.quarter && undefined != searchProject.quarter) {
      if (searchProject.quarter == '1') {
        routine.monthArray = this.quarterA;
      } else if (searchProject.quarter == '2') {
        routine.monthArray = this.quarterB;
      } else if (searchProject.quarter == '3') {
        routine.monthArray = this.quarterC;
      } else if (searchProject.quarter == '4') {
        routine.monthArray = this.quarterD;
      }
    }

    this.loading = true;
    this.routineService.getListRoutineView(routine).then(result => {
      if (result.responseCode == 200) {
        this.routineList = result.data;
        this.routineList.forEach(ele => {
          if (ele.img != undefined && ele.img != "") {
          } else {
            ele.img = null
          }
        })
        let listProjectOBJ = _.groupBy(this.routineList, 'projectCode');

        this.listProject = _.map(listProjectOBJ, function (group) {
          return {
            projectName: group[0].projectName,
            projectCode: group[0].projectCode,
            time: _.reduce(group,
              function (memoizer, value) {
                if (value.time != null && value.time != undefined && value.time != "") {
                  return parseInt(memoizer) + parseInt(value.time);
                } else {
                  return parseInt(memoizer) + 0;
                }
              }, 0),
            timesOt: _.reduce(group,
              function (memoizer, value) {
                if (value.timesOt != null && value.timesOt != undefined && value.timesOt != "") {
                  return parseInt(memoizer) + parseInt(value.timesOt);
                } else {
                  return parseInt(memoizer) + 0;
                }

              }, 0),
            allTime: _.reduce(group,
              function (memoizer, value) {
                if (value.timesOt != null && value.timesOt != undefined && value.timesOt != "") {
                  return parseInt(memoizer) + parseInt(value.time) + parseInt(value.timesOt);
                } else {
                  return parseInt(memoizer) + parseInt(value.time)
                }
              }, 0)
          }
        });

        let listUserProject = [];
        var uniqueUser = _.uniq(this.routineList, function (elem) {
          return JSON.stringify(_.pick(elem, ['idUser', 'projectCode']));
        });
        listUserProject = _.groupBy(uniqueUser, 'projectName');
        console.log(listUserProject)
        this.mandayAll = 0;
        this.listProject.forEach(ele => {
          ele.time = this.calTime(parseInt(ele.time));
          ele.timeManday = ele.time / 8;
          ele.allTime = this.calTime(ele.allTime);
          ele.timesOt = this.calTime(ele.timesOt);
          ele.name = ele.projectCode.substr(0, 1);
          ele.countUSer = listUserProject[ele.projectCode] ? listUserProject[ele.projectCode] : [];
          ele.positionGroup = _.groupBy(ele.countUSer, 'position')
          ele.pa = ele.positionGroup['PA']
          ele.qa = ele.positionGroup['QA']
          ele.pm = ele.positionGroup['PM']
          ele.sa = ele.positionGroup['SA']
        })

        this.mandayAll = this.listProject.reduce((s, f) => {
          return s + f.timeManday;
        }, 0);
        let a = this.mandayAll.toString().split('.')
        this.mandayPoint = a[0].split("");
      }
      this.loading = false;
    });
  }

  getGoal() {
    let lov = new LovModel();
    lov.lovCode_key0 = 'CONFIG';
    this.lovService.getLov(lov).then(result => {
      this.g = result.data[0].lovVal01_data;
      this.a = result.data[0].lovVal02_data;
    });
  }

  calTime(mins) {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = h < 10 ? 0 + h : h;
    m = m < 10 ? 0 + m : m;
    return `${h}.${m}`;
  }

  editGoal() {
    this.modeEdit = true;
  }

  saveGoal(g, a) {
    this.goal = g.toString().split("");
    this.actual = a.toString().split("");
    this.modeEdit = false;
  }

  selectedtab(tab) {
    this.loading = true;
    this.selectedwallet = tab;

    this.searchProject = {
      projectCode: '',
      status: 'A',
      quarter: '4'
    };
    this.getRoutineAll(this.searchProject);
  }

  getMemberList(position) {
    let user = new UserModel();
    if (position !== 'A') {
      user.position = position
    }
    this.employeeService.getEmployeeByCriteria(user).then(res => {
      if (res.responseCode == 200) {
        this.memberList = res.data;
        this.memberList.forEach(ele => {
          if (ele.img != undefined && ele.img != "") {
          } else {
            ele.img = null
          }
        });
      }
    })
  }

  getProjectName() {
    let lov = new LovModel();
    lov.lovType_key2 = "PROJECT_NAME";
    lov.activeFlg_key1 = "Y";
    this.LovService.getLov(lov).then(res => {
      if (res.responseCode == 200) {
        this.projectList = res.data;
        this.projectList.forEach(ele => {
          if (ele.activeFlg_key1 == 'Y') {
            ele.activeFlg_key1 = 'ACTIVE'
          } else {
            ele.activeFlg_key1 = 'INACTIVE'
          }

        })
      }
    })
  }
}
