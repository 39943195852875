export class ProjectFunctionModel {
    public _id: string;
    public projectCode: string = '';
    public functionCode: string;
    public functionDesc: string;
    public activeFlg: string = '';
    public created: string;
    public createdBy: string;
    public updated: string;
    public updatedBy: string;
}