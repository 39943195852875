import { EmployeeModel } from './../model/employee.model';
import { CheckinModel } from './../model/checkin.model';
import { CheckInService } from './../service/check-in.service';
import { CreateTimeSheetService } from './../create-time-sheet/create-time-sheet.service';
import { RoutineModel, RoutineOTModel } from './../model/routine.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserModel } from '../model/users.model';
import { LovModel } from '../model/lov';
import { NgbModal, NgbTimeStruct, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import * as _ from "underscore";
import * as __ from "lodash";
import { FieldRequiredTSOtModel, FieldRequiredTSModel } from '../model/fieldRequired';
import { ProjectFunctionModel } from '../model/projectFunction';
import { EmployeeService } from '../service/employee.service';

@Component({
  selector: 'app-ps-new-time-sheet',
  templateUrl: './ps-new-time-sheet.component.html',
  styleUrls: ['./ps-new-time-sheet.component.css']
})
export class PsNewTimeSheetComponent implements OnInit {

  public timeSheetList: Array<RoutineModel> = new Array();
  public timeSheet: RoutineModel = new RoutineModel();
  public userLogin: UserModel = new UserModel();
  public projectList: Array<LovModel> = new Array();
  public fieldErrList: FieldRequiredTSModel = new FieldRequiredTSModel();
  public projectFunctionArr: Array<ProjectFunctionModel> = new Array();
  public requiredObj: FieldRequiredTSModel = new FieldRequiredTSModel();
  public defaultCode: string = '';
  public isCheck: boolean = false;
  public selectDateMore: NgbDateStruct;
  public disFunctionName: boolean = true;
  public modal = {
    title: "",
    msg: "",
    msg2: "",
    confirm: "",
    cancel: ""
  }

  public isChange: boolean = false;
  public selectTime: NgbTimeStruct = { hour: 0, minute: 0, second: 0 }
  public minuteStep = 1;
  public startTime = "09:00";
  public endTime = "18:00";
  public loading: boolean = false;
  public routeParams: any;
  public checkInId: any;

  public checkIn: CheckinModel = new CheckinModel();
  public selectDate: NgbDateStruct;
  public personalProjectList: Array<LovModel> = new Array();
  public warn = {
    date: false,
    startTime: false,
    endTime: false
  }
  constructor(
    private router: Router,
    private createTimeSheetService: CreateTimeSheetService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private checkInService: CheckInService,
    private employeeService: EmployeeService
  ) {
  }

  ngOnInit() {
    this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
    if (undefined == this.userLogin || !this.userLogin) {
      this.router.navigate(['/login']);
    }
    this.route.params.subscribe(params => {


      this.routeParams = params;
      this.checkInId = this.routeParams.id;

      if (this.checkInId != null && this.checkInId != undefined && this.checkInId != "undefined") {
        this.getCheckIn(this.checkInId);
      } else {
        this.router.navigate(['/home-ps']);
      }

    });
    // this.getProjectName();
    this.getListProject()
  }

  async getListProject() {
    let emp = new EmployeeModel()
    emp.idUser = this.userLogin.idUser;
    this.employeeService.getEmployeeProjectList(emp).then(res => {
      if (res.responseCode == 200) {
        this.personalProjectList = res.data;
      }
    });
  }

  getCheckIn(checkInId) {
    var checkin = new CheckinModel();
    checkin._id = checkInId;
    this.checkInService.getCheckinList(checkin).then(res => {
      if (res.responseCode == 200) {
        this.checkIn = res.data[0];
        let dateString = moment(this.checkIn.date, "DD-MM-YYYY").format("MM-DD-YYYY");
        // let date = new Date(dateString);
        // this.selectDate = {
        //   year: parseInt(date.getFullYear().toString()),
        //   month: parseInt((date.getMonth() + 1).toString()),
        //   day: parseInt(date.getDate().toString())
        // };
        this.timeSheet.date = this.checkIn.date;
        this.timeSheet.startTime = this.checkIn.startTime;
        this.timeSheet.endTime = "17:30";
      }
    })

  }


  getProjectName() {
    let lov = new LovModel();
    lov.lovType_key2 = "PROJECT_NAME";
    lov.activeFlg_key1 = "Y";
    this.createTimeSheetService.getLov(lov).then(res => {
      if (res.responseCode == 200) {
        this.projectList = res.data;
      }
    })
  }

  getFuncProject(projectCode) {
    this.disFunctionName = false;
    if (projectCode != "") {
      let func = new ProjectFunctionModel();
      func.projectCode = projectCode;
      func.activeFlg = "Y";
      this.createTimeSheetService.searchFunction(func).then(res => {
        if (res.responseCode == 200) {
          this.projectFunctionArr = res.data;
        } else {
          this.projectFunctionArr = new Array();
        }
      })
    }

  }


  change() {
    this.isChange = true;
  }

  checkValue(event: any, code) {
    if (event) {

      this.defaultCode = code;
      this.timeSheetList.forEach(ele => {
        ele.projectCode = code
      })

    } else {
      this.defaultCode = '';
      var i = 0;
      this.timeSheetList.forEach(ele => {
        if (i != 0) {
          ele.projectCode = ''
        }
      })
    }

  }

  onCheckboxChagen(event, code, i) {

    if (event.checked) {

      this.isCheck = true;
    }
    if (!event.checked) {

      this.isCheck = false;
    }
    this.defaultCode = code;
    this.timeSheetList.forEach(ele => {
      ele.projectCode = code
    })

  }

  cancel(content) {
    if (this.isChange) {
      this.modal.title = "Confirm Navigation";
      this.modal.msg = "You have unsave changes. If you leave this page, your changes will be lost.";
      this.modal.msg2 = "Are You sure you want to leave this page?";
      this.modal.confirm = "Leave this page"
      this.modal.cancel = "Stay on this page"
      this.modalService.open(content, { backdrop: 'static' }).result.then((result) => {
        if (result == 'Y') {
          this.router.navigate(['/home-ps']);
        }
      }, (reason) => { });
    } else {
      this.router.navigate(['/home-ps']);
    }
  }

  checkRequiredTs(value, elementName) {
    if (undefined == value || !value || null == value || "" == value) {
      this.fieldErrList[elementName] = true;
    } else {
      this.fieldErrList[elementName] = false;
    }
  }

  checkChooseTime(startTime, endTime) {
    if ((undefined != startTime && startTime) && (undefined != endTime && endTime)) {
      let compareTime = moment(endTime, 'HH:mm').isSameOrBefore(moment(startTime, 'HH:mm'));
      if (compareTime) {
        this.fieldErrList.endTimeErr = true;
      } else {
        this.fieldErrList.endTimeErr = false;
      }

      let datetimeA = new Date('01/01/2000 ' + startTime);
      let datetimeB = new Date('01/01/2000 ' + endTime);
      let times = moment(datetimeB).diff(datetimeA, 'minutes');
      let finallyTime = this.calWorkTime(times);
      this.timeSheet.time = finallyTime.toString();
    }
  }

  calWorkTime(times) {
    let calTimesHr = parseInt((parseInt(times) / 60).toString());
    let calTimesMin = parseInt(times) % 60;
    let calTimes;
    if (calTimesMin < 10) {
      calTimes = calTimesHr + ".0" + calTimesMin;
    } else {
      calTimes = calTimesHr + "." + calTimesMin;
    }
    let decimal = calTimes.split('.');
    let time = parseInt(decimal[0]);
    let totalTime = 0;
    if (time > 4) {
      time = time - 5;
      totalTime = 4;
      if (time > 4) {
        time = time - 5;
        totalTime += 4;
        if (time > 6) {
          time = time - 7;
          totalTime += 6;
          if (time > 4) {
            totalTime += 4;
          } else {
            totalTime += time;
          }
        } else {
          totalTime += time;
        }
      } else {
        totalTime += time;
      }
    } else {
      totalTime = time;
    }

    if (totalTime == 16) {
      if (undefined != decimal[1] && decimal[1] && parseInt(decimal[1]) != 0) {
        decimal[1] = '0';
      }
    }
    totalTime = totalTime * 60;
    if (undefined != decimal[1] && decimal[1] && parseInt(decimal[1]) != 0) {
      // totalTime = parseFloat(totalTime.toString() + '.' + decimal[1]);
      totalTime = totalTime + parseInt(decimal[1]);
    }
    return totalTime;
  }

  async createTimeSheet(content) {
    this.loading = true;
    let cannotCreate = false
    let sheet: RoutineModel = __.cloneDeep(this.timeSheet);
    // sheet.selectDate = this.selectDate;
    let idUser = this.userLogin.idUser;
    let userName = this.userLogin.username;
    let today = new Date();
    // await draftCreate.forEach((sheet, index) => {
    sheet.idUser = idUser;
    //------------------------------------ CHECK REQUIRED --------------------------------------//
    if (undefined == sheet.projectCode || !sheet.projectCode) {
      cannotCreate = true;
      this.fieldErrList.projectName = true;
    }
    if (undefined == sheet.startTime || !sheet.startTime) {
      cannotCreate = true;
      this.fieldErrList.startTime = true;
    }
    if (undefined == sheet.endTime || !sheet.endTime) {
      cannotCreate = true;
      this.fieldErrList.endTime = true;
    }
    if (undefined == sheet.detail || !sheet.detail) {
      cannotCreate = true;
      this.fieldErrList.desc = true;
    }
    if (undefined == sheet.functionCode || !sheet.functionCode) {
      cannotCreate = true;
      this.fieldErrList.functionCode = true;
    }
    this.checkChooseTime(sheet.startTime, sheet.endTime);
    sheet.time = this.timeSheet.time;

    if (this.fieldErrList.endTimeErr) {
      cannotCreate = true;
    }

    //------------------------------------ END CHECK REQUIRED --------------------------------------//
    //---------------------------------------- CHECK TIME ------------------------------------------//

    //-------------------------------------- END CHECK TIME ----------------------------------------//
    //-------------------------------------- ADD NAME STAMP ----------------------------------------//
    sheet.createBy = userName;
    sheet.createDate = today;
    var currMonthName  = moment().format('MMMM');
    sheet.month = currMonthName
    //------------------------------------ END ADD NAME STAMP --------------------------------------//
    if (!cannotCreate) {
      sheet.checkinId = this.checkInId;
      this.createTimeSheetService.postRoutine(sheet).then(res => {
        if (res.responseCode == 200) {

          this.modal.title = "Success";
          this.modal.msg = "Create Time Sheet Success.";
          this.modal.confirm = "OK"
          this.modalService.open(content, { size: 'sm', backdrop: 'static' }).result.then((result) => {
            if (result == 'Y') {
              this.router.navigate(['/home-ps']);
            }
          }, (reason) => { });
        }
        this.loading = false;
      })
    } else {
      this.loading = false;
    }
  }

  checkRequiredDate(index) {

    if (undefined != this.timeSheetList[index].selectDate && this.timeSheetList[index].selectDate) {
      this.fieldErrList[index].date = false;
      this.selectDateMore = this.timeSheetList[index].selectDate;
    } else {
      this.fieldErrList[index].date = true;
    }
  }

  /********************************** TIME PICKER ************************************/
  parseTimeFomat(time: NgbTimeStruct): string {
    let hour = time.hour.toString();
    let minute = time.minute.toString();
    if (hour.length == 1) {
      hour = "0" + hour;
    }
    if (minute.length == 1) {
      minute = "0" + minute;
    }
    let timeFormat = hour + ":" + minute;
    return timeFormat;
  }

  // openTimeStart(content, index) {
  //   let timeFormat = "";
  //   if (undefined != this.timeSheetList[index].startTime
  //     && this.timeSheetList[index].startTime) {
  //     let start = (this.timeSheetList[index].startTime).split(':');
  //     this.selectTime.hour = parseInt(start[0]);
  //     this.selectTime.minute = parseInt(start[1]);
  //   } else {
  //     this.selectTime.hour = 9;
  //     this.selectTime.minute = 0;
  //   }
  //   this.modal.title = "Start Time";
  //   this.modalService.open(content, { size: 'sm', backdrop: 'static' }).result.then((result) => {
  //     if (result == 'Y') {
  //       timeFormat = this.parseTimeFomat(this.selectTime);
  //       this.timeSheetList[index].startTime = timeFormat;
  //       this.checkRequiredTs(index, this.timeSheetList[index].startTime, "startTime");
  //       this.checkChooseTime(this.timeSheetList[index].startTime, this.timeSheetList[index].endTime, index)
  //     }
  //   }, (reason) => { });
  // }

  // openTimeEnd(content, index) {
  //   let timeFormat = "";
  //   if (undefined != this.timeSheetList[index].endTime
  //     && this.timeSheetList[index].endTime) {
  //     let start = (this.timeSheetList[index].endTime).split(':');
  //     this.selectTime.hour = parseInt(start[0]);
  //     this.selectTime.minute = parseInt(start[1]);
  //   } else {
  //     this.selectTime.hour = 18;
  //     this.selectTime.minute = 0;
  //   }
  //   this.modal.title = "End Time";
  //   this.modalService.open(content, { size: 'sm', backdrop: 'static' }).result.then((result) => {
  //     if (result == 'Y') {
  //       timeFormat = this.parseTimeFomat(this.selectTime)
  //       this.timeSheetList[index].endTime = timeFormat;
  //       this.checkRequiredTs(index, this.timeSheetList[index].endTime, "endTime");
  //       this.checkChooseTime(this.timeSheetList[index].startTime, this.timeSheetList[index].endTime, index);
  //     }
  //   }, (reason) => { });
  // }

  /********************************** TIME PICKER ************************************/
  openTimeStart(content) {
    let timeFormat = "";
    if (undefined != this.timeSheet.startTime
      && this.timeSheet.startTime) {
      if (this.timeSheet.startTime == "Invalid date") {
        this.timeSheet.startTime = "08:30";
        this.warn.startTime = true;
      }
      let start = (this.timeSheet.startTime).split(':');
      this.selectTime.hour = parseInt(start[0]);
      this.selectTime.minute = parseInt(start[1]);
    } else {
      this.selectTime.hour = 8;
      this.selectTime.minute = 30;
    }
    this.modal.title = "Start Time";
    this.modalService.open(content, { size: 'sm', backdrop: 'static' }).result.then((result) => {
      if (result == 'Y') {
        timeFormat = this.parseTimeFomat(this.selectTime);
        this.timeSheet.startTime = timeFormat;
        this.warn.startTime = false;
        this.checkRequiredTs(this.timeSheet.startTime, "startTime");
        this.checkChooseTime(this.timeSheet.startTime, this.timeSheet.endTime);
      }
    }, (reason) => { });
  }

  openTimeEnd(content) {
    let timeFormat = "";
    if (undefined != this.timeSheet.endTime
      && this.timeSheet.endTime) {
      if (this.timeSheet.endTime == "Invalid date") {
        this.timeSheet.endTime = "17:30";
        this.warn.endTime = true;
      }
      let start = (this.timeSheet.endTime).split(':');
      this.selectTime.hour = parseInt(start[0]);
      this.selectTime.minute = parseInt(start[1]);
    } else {
      this.selectTime.hour = 17;
      this.selectTime.minute = 30;
    }
    this.modal.title = "End Time";
    this.modalService.open(content, { size: 'sm', backdrop: 'static' }).result.then((result) => {
      if (result == 'Y') {
        timeFormat = this.parseTimeFomat(this.selectTime);
        this.timeSheet.endTime = timeFormat;
        this.warn.endTime = false;
        this.checkRequiredTs(this.timeSheet.endTime, "endTime");
        this.checkChooseTime(this.timeSheet.startTime, this.timeSheet.endTime);
      }
    }, (reason) => { });
  }
}
