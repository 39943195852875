export class ValidationService {

    public static REQUIRED = 'required';
    public static PATTERN_EN = 'patternEN';
    public static PATTERN_TH = 'patternTH';
    public static PATTERN_EMAIL = 'patternEmail';
    public static PATTERN_ABBREV = 'patternAbbrev';
    public static PATTERN_TIME = 'patternTime';
    public static PATTERN_ZIPCODE = 'patternZipCode';
    public static PATTERN_NUMBER = 'patternNumber';
    
  
    static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
      let config = {
        'required': 'This field is required.',
        //'patternEN': 'This field is allowed characters: a-z,A-Z, 0-9,space ,@ -, . -_ / * :',
        // 'patternEN': 'This field is allowed characters: a-z,A-Z, 0-9,space ,@ -, . -_ / : ()',
        'patternEN': 'This field is allowed characters: a-z A-Z 0-9 space (), @ - . _ / : &',
        //'patternTH': 'This field is allowed characters  a-z,A-Z,ภาษาไทย ,เว้นวรรค และ ,space ,@ -, . -_ / * :',
        // 'patternTH': 'This field is allowed characters  a-z,A-Z, 0-9, ภาษาไทย ,เว้นวรรค และ ,space ,@ -, . -_ / : ()',
        'patternTH': 'This field is allowed characters a-z A-Z,0-9,ภาษาไทย ตัวเลข เว้นวรรค และ (), @ - . _ / : &',
        // 'patternPhone': 'This field is not phone format',
        'patternEmail': 'This field is not email format',
        'patternAbbrev' : 'This field is allowed character : A-Z , 0-9 and allowed to filled 2-5 character',
        'patternTime':'This field is format time only.', 
        'patternZipCode' : 'This field is allowed characters: 0-9 and less than or equal to 5 characters.',
        'patternNumber' : 'This field is allowed characters 0-9 and with 2 places of decimal number.'
      };
     
      return config[validatorName];
    }
  
    static patternEN(control) {
      // RFC 2822 compliant regex
      // /^[a-z,A-Z,0-9,\u0020,\u0028-\u002F,\u003A,\u0040,\u005F,\&]+$/.test(control.value);
      
      //const patternMach = /^[a-z,A-Z,0-9,\u0020,\u002d,\u002F,\u003A,\u0040,\u005F,\u002a,\u002e,(,)]+$/.test(control.value);
      // const patternMach = /^[a-z,A-Z,0-9,\u0020,\u002d,\u002F,\u003A,\u0040,\u005F,\u002e,(,)]+$/.test(control.value);
      const patternMach = /^[a-z,A-Z,0-9,\u0020,\u002d,\u002F,\u003A,\u0040,\u005F,\u002e,(,),+,&]+$/.test(control.value);
      // if (control.value.match(/^[a-z,A-Z,0-9,\u0020,\u0028-\u002F,\u003A,\u0040,\u005F,\&]+$/)) {
      if (patternMach) {
        return null;
      } else {
        return { 'patternEN': true };
      }
    }
  
    static patternTH(control) {
      // RFC 2822 compliant regex
      //const patternMach = (/^[\u0E01-\u0E5B,\u0020,\u002d,\u002F,\u003A,\u0040,\u005F,\u002e,0-9,a-z,A-Z,(,)]+$/).test(control.value);
      // const patternMach = (/^[\u0E01-\u0E5B,\u0020,\u002d,\u002F,\u003A,\u0040,\u005F,\u002e,0-9,a-z,A-Z,(,)]+$/).test(control.value);
      // const patternMach = (/^[\u0E01-\u0E3E,\u0E40-\u0E5B,\u0020,\u002d,\u002F,\u003A,\u0040,\u005F,\u002e,0-9,a-z,A-Z,(,)]+$/).test(control.value);
      const patternMach = (/^[\u0E01-\u0E3E,\u0E40-\u0E5B,\u0020,\u002d,\u002F,\u003A,\u0040,\u005F,\u002e,0-9,a-z,A-Z,(,),+,&]+$/).test(control.value);
      if (patternMach) {
        return null;
      } else {
        return { 'patternTH': true };
      }
    }
  
    static patternAbbrev(control) {
      // RFC 2822 compliant regex
      const patternMach = (/^[A-Z,0-9]{2,5}$/).test(control.value);
      if (patternMach) {
        return null;
      } else {
        return { 'patternAbbrev': true };
      }
    }
  
    static patternPhone(control) {
      // RFC 2822 compliant regex
      // if (control.value.match(/^[0]{1}[0-9]{8,}$/)) {
      //   return null;
      // } else {
      //   return { 'patternPhone': true };
      // }
      return null;
    }
    static patternTime(control) {
      // RFC 2822 compliant regex
      // if (control.value.match(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)) {
      //   return null;
      // } else {
      //   return { 'patternTime': true };
      // }
      const patternMach = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(control.value) ;
      if (patternMach) {
        return null;
      } else {
        return { 'patternTime': true };
      }
  
    }
  
    static patternEmail(control) {
      // RFC 2822 compliant regex
      if (control.value.match(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])$/)) {
        return null;
      } else {
        return { 'patternEmail': true };
      }
    }
  
    static patternZipCode(control) {
      // RFC 2822 compliant regex
      if (control.value.match(/^[0-9]{5}$|^[0-9]{1,4}\*$/) || control.value=="") {
        return null;
      } else {
        return { 'patternZipCode': true };
      }
    }
  
    static patternNumber(control) {
      // RFC 2822 compliant regex
      const patternMach = /^[0-9]+(\.[0-9]){0,2}\*$/.test(control.value) ;
      if (patternMach) {
        return null;
      } else {
        return { 'patternNumber': true };
      }
      
    }
  
    static patternRemark(control) {
      const patternMach = (/^[\u0E01-\u0E3E,\u0E40-\u0E5B,\u0020,\u002d,\u002F,\u003A,\u0040,\u005F,\u002e,\u005B,\u005D,\n,0-9,a-z,A-Z,(,),+,&,!,#,$,%,&,',*,+,\\\/,=,?,^,_,`,{,|,},~,-,;,",฿]+$/).test(control.value);
      if (patternMach) {
        return null;
      } else {
        return { 'patternRemark': true };
      }
    }
  
    //Phase 2
    isEmpty(val){
      return (val === 'undefined' || val === '' || val === undefined || val === null || val === {} || val.length <= 0) ? true : false;
    }

    isTHLanguage(val) {
      const patternMatch = (/^[\u0E01-\u0E3E,\u0E40-\u0E5B,\u0020,\u002d,\u002F,\u003A,\u0040,\u005F,\u002e,0-9,(,),+,%,&]+$/).test(val);
      return patternMatch;
    }

    isENLanguage(val) {
      const patternMatch = (/^[A-Z a-z,0-9,(,),+,%,&]+$/).test(val);
      return patternMatch;
    }
  
    static isPatternNameTh(val){
      const patternMach = (/^[\u0E01-\u0E3E,\u0E40-\u0E5B,\u0020,\u002d,\u002F,\u003A,\u0040,\u005F,\u002e,0-9,a-z,A-Z,(,),+,&]+$/).test(val);
      /* console.log(patternMach)
      if(patternMach){
        return false;
      }else{
        return true;
      } */
      return patternMach;
    }
  
    static isPatternNameEn(val){
      const patternMach = /^[a-z,A-Z,0-9,\u0020,\u002d,\u002F,\u003A,\u0040,\u005F,\u002e,(,),+,&]+$/.test(val);
      return patternMach;
    }
  
    isPatternNumber(val){
      const patternMach = /^[0-9]*$/g.test(val) ;
      return patternMach;
    }
  
    isPatternNumberPassport(val){
      const patternMach = /=/g.test(val) ;
      return patternMach;
    }
  
    isPatternPhone(val){
      const patternMach = /^[0]{1}[0-9]{9}$/.test(val) ;
      return patternMach;
    }
  
    isPatternPhoneOffice(val){
      const patternMach = /^[0]{1}[0-9]{8}$/.test(val) ;
      return patternMach;
    }
  
    isPatternEmail(val){
      const patternMach = (/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/).test(val);
      return patternMach;
    }
  
    isPatternAbbrev(val){
      const patternMach = (/^[A-Z,0-9]{2,5}$/).test(val);
      return patternMach;
    }
  
    isPatternVatbranchNo(val){
      const patterMach = (/^[0-9]{5}$/).test(val);
      return patterMach;
    }
  
    isPatternTime(val){
      const patterMach = (/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/).test(val);
      return patterMach;
    }
  
    isPatterIdNumber(val){
      const patternMach = /^[0-9]{13}$/.test(val) ;
      if(patternMach){ 
        let sum=0;
        for(let i=0; i < 12; i++) {
          sum += parseFloat(val.charAt(i))*(13-i); 
        }
        if(((11-sum%11)%10!=parseFloat(val.charAt(12)))) {
            return false; 
        }
        return true;
    }
    return patternMach   ;
    }
  
    
    static isPatternZipcode(val){
      const patternMach = /^[0-9]{5}$|^[0-9]{1,4}\*$/.test(val) ;
      return patternMach   ;
    }
    static isPatterIdNumber(val){
  
       
      // const patternMach = /^[0-9]{5}$|^[0-9]{1,4}\*$/.test(val) ;
      const patternMach = /^[0-9]{13}$/.test(val) ;
      if(patternMach){ 
          let sum=0;
          for(let i=0; i < 12; i++) {
            sum += parseFloat(val.charAt(i))*(13-i); 
          }
          if(((11-sum%11)%10!=parseFloat(val.charAt(12)))) {
              return false; 
          }
          return true;
      }
      return patternMach   ;
    }

    isPatternWebsite(val){
      const patternMatch = (/(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/).test(val);
      return patternMatch;
    }
  
    isPatternNameThCompany(val){
      const patterMach = (/^[\u0E01-\u0E3E,\u0E40-\u0E5B,\u0020,\u002d,\u002F,\u003A,\u0040,\u005F,\u002e,0-9,a-z,A-Z,(,),+,&]+$/).test(val);
      // const patterMach = (/^[a-z,A-Z,ก-ฮ,ะ-ู,เ-์,\u0020,(,),@ -, . -_ / : (),&]+$/).test(val);
      return patterMach;
    }
  
    isPatternNameTh(val){
      const patterMach = (/^[\u0020ก-ฮะ-ูเ-์]+$/).test(val);
      return patterMach;
    }
  
    isPatternNameEnCompany(val){
      const patterMach = ( /^[a-z,A-Z,0-9,\u0020,\u002d,\u002F,\u003A,\u0040,\u005F,\u002e,(,),+,&]+$/).test(val);
      // const patterMach = (/^[a-z,A-Z,\u0020,(,),0-9,@ -, . -_ / : (),&]+$/).test(val);
      return patterMach;
    }
  
    isPatternNameEn(val){
      const patterMach = (/^[a-zA-Z\u0020()]+$/).test(val);
      return patterMach;
    }
  
    isPatternZipcode(val){
      const patterMach = (/^([0-9])*$/).test(val);
      return patterMach;
    }
  
    static isPatternMobilePhone(val){
      const patternMach = /^[0]{1}[0-9]{9}$/.test(val) ;
      return patternMach;
    }
  
    static isPatternPhone(val){
      const patternMach = /^[0]{1}[0-9]{8,}$/.test(val) ;
      return patternMach;
    }
  
    static isPatternEmail(val){
      const patternMach = (/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])$/).test(val);
      return patternMach;
    }
  
    static  isPatternNumber(val){
      const patternMach = /^[0-9]*$/g.test(val) ;
      return patternMach;
    }
  
    static isPatternRemark(val){
      const patternMach = (/^[\u0E01-\u0E3E,\u0E40-\u0E5B,\u0020,\u002d,\u002F,\u003A,\u0040,\u005F,\u002e,\u005B,\u005D,\n,0-9,a-z,A-Z,(,),+,&,!,#,$,%,&,',*,+,\\\/,=,?,^,_,`,{,|,},~,-,;,",฿]+$/).test(val);
      return patternMach;
    }
    
  }
  