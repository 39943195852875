export class FieldRequiredTSModel {
    projectName: boolean = false;
    date: boolean = false;
    startTime: boolean = false;
    endTime: boolean = false;
    desc: boolean = false;
    ot: Array<FieldRequiredTSOtModel> = new Array();
    endTimeErr: boolean = false;
    functionCode: boolean = false;
    specialCase?:boolean = false;
}

export class FieldRequiredTSOtModel {
    startTimeOt: boolean = false;
    endTimeOt: boolean = false;
    descOt: boolean = false;
    endTimeOtErr: boolean = false;
}

export class FieldRequireCreateTSModel {
    projectCode: boolean = false;
    projectName: boolean = false;
    projectDest: boolean = false;
}

export class FieldRequireCreateUser {
    fName: boolean = false;
    lName: boolean = false;
    username: boolean = false;
    password: boolean = false;
    position: boolean = false;
    role: boolean = false;
}
