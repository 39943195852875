export class EmployeeModel {
    public _id: any;
    public username: string;
    public fName: string;
    public lName: string;
    public password: string;
    public role: string;
    public idUser: string;
    public position: string;
    public active: string;
    public passwordToken: string;
    public skill: any;
    public tel: string;
    public email: string;
    public line: string;
    public hobbies: any;
    public projectList: any;
}