import { BrowserModule } from '@angular/platform-browser';
import { NgModule, forwardRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ImageUploadModule } from "angular2-image-upload";
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { ImageCropperModule } from 'ngx-image-cropper';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

/****************** PROVIDERS *****************/
import { LoginService } from './login/login.service';
import { ProjectService } from './project/project.service';
import { RoutineService } from './service/routine.service';
import { NgbDateMomentParserFormatter } from './service/ngb-date-format.service';
import { LovService } from './service/lov.service';

/****************** COMPONANT *****************/
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { ProjectComponent } from './project/project.component';
import { CreateTimeSheetComponent } from './create-time-sheet/create-time-sheet.component';
import { CreateTimeSheetService } from './create-time-sheet/create-time-sheet.service';
import { ViewTimeSheetComponent } from './view-time-sheet/view-time-sheet.component';
import { ViewTimeSheetService } from './view-time-sheet/view-time-sheet.service';
import { ViewAllTimeSheetComponent } from './view-all-time-sheet/view-all-time-sheet.component';
import { EditTimesheetComponent } from './edit-timesheet/edit-timesheet.component';
import { EmployeeService } from './service/employee.service';
import { EditTimesheetService } from './edit-timesheet/edit-timesheet-service.service';
import { CreateNewProjectComponent } from './create-new-project/create-new-project.component';
import { CreateNewProjectService } from './create-new-project/create-new-project.service';
import { RepasswordComponent } from './repassword/repassword.component';
import { RepasswordService } from './repassword/repassword.service';
import { ViewProjectAllComponent } from './view-project-all/view-project-all.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { RouterExtService } from './service/routerExtService.service';
import { ViewUserAllComponent } from './view-user-all/view-user-all.component';
import { DashBoardComponent } from './dash-board/dash-board.component';
import { HomeUserComponent } from './home-user/home-user.component';
import { ViewProfileComponent } from './view-profile/view-profile.component';
import { MemberAllComponent } from './member-all/member-all.component';
import { ConfigulationComponent } from './configulation/configulation.component';
import { ConfigService } from './service/config.service';
import { CreateConfigComponent } from './create-config/create-config.component';
import { EditConfigComponent } from './edit-config/edit-config.component';
import { ExcelServiceFunction } from './service/excel-with-function.service';
import { ExcelService } from './service/excel.service';
import { ValidationService } from './service/validation.service';
import { EditProjectComponent } from './edit-project/edit-project.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { IndexComponent } from './index/index.component';
import { CreateTimeSheetFuncComponent } from './create-time-sheet-func/create-time-sheet-func.component';
import { ProjectFunctionService } from './service/project-function.service';
import { CreateNewFunctionsComponent } from './create-new-functions/create-new-functions.component';
import { EditFunctionComponent } from './edit-function/edit-function.component';
import { HomePsComponent } from './home-ps/home-ps.component';
import { PsCheckInComponent } from './ps-check-in/ps-check-in.component';
import { PsNewTimeSheetComponent } from './ps-new-time-sheet/ps-new-time-sheet.component';
import { MyProjectComponent } from './my-project/my-project.component';
import { PsProjectListComponent } from './ps-project-list/ps-project-list.component';
import { PsLeaveWorkComponent } from './ps-leave-work/ps-leave-work.component';
import { PsNewLeaveWorkComponent } from './ps-new-leave-work/ps-new-leave-work.component';
import { MenuAdminComponent } from './header/menu-pm/menu-admin.component';
import { PassedParamsService } from './service/passed-params';
@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        LoginComponent,
        ProjectComponent,
        CreateTimeSheetComponent,
        ViewAllTimeSheetComponent,
        ViewTimeSheetComponent,
        EditTimesheetComponent,
        CreateNewProjectComponent,
        RepasswordComponent,
        ViewProjectAllComponent,
        CreateUserComponent,
        EditProfileComponent,
        ViewUserAllComponent,
        DashBoardComponent,
        HomeUserComponent,
        ViewProfileComponent,
        MemberAllComponent,
        ConfigulationComponent,
        CreateConfigComponent,
        EditConfigComponent,
        EditProjectComponent,
        EditUserComponent,
        IndexComponent,
        CreateTimeSheetFuncComponent,
        CreateNewFunctionsComponent,
        EditFunctionComponent,
        HomePsComponent,
        PsCheckInComponent,
        PsNewTimeSheetComponent,
        MyProjectComponent,
        PsProjectListComponent,
        PsLeaveWorkComponent,
        PsNewLeaveWorkComponent,
        MenuAdminComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        NgbModule.forRoot(),
        Ng2ImgMaxModule,
        ImageCropperModule,
        ImageUploadModule.forRoot(),
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.doubleBounce, //doubleBounce
            backdropBackgroundColour: 'rgba(0,0,0,0.1)',
            backdropBorderRadius: '4px',
            primaryColour: '#ffffff',
            secondaryColour: '#ffffff',
            tertiaryColour: '#ffffff',
            fullScreenBackdrop: true
        })
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        LovService,
        RoutineService,
        LoginService,
        ProjectService,
        CreateTimeSheetService,
        ViewTimeSheetService,
        EmployeeService,
        EditTimesheetService,
        CreateNewProjectService,
        RepasswordService,
        RouterExtService,
        ConfigService,
        ExcelService,
        ExcelServiceFunction,
        ProjectFunctionService,
        ValidationService,
        PassedParamsService,
        {
            provide: NgbDateParserFormatter,
            useFactory: () => { return new NgbDateMomentParserFormatter("DD MMM YYYY") }
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
