import { CheckinModel } from './../model/checkin.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from '../shared/url-constant';
import { Response } from '../model/response';

@Injectable({
  providedIn: 'root'
})
export class CheckInService {

  constructor(
    public http: HttpClient
  ) { }

  getCheckinList(ch: CheckinModel) {
    let httpParams = new HttpParams();
    if (undefined != ch._id && ch._id) {
      httpParams = httpParams.set('_id', ch._id);
    }
    if (undefined != ch.idUser && ch.idUser) {
      httpParams = httpParams.set('idUser', ch.idUser);
    }
    if (undefined != ch.date && ch.date) {
      httpParams = httpParams.set('date', ch.date);
    }
    const reqOptions = {
      params: httpParams
    };
    return this.http.get<Response>(API.GET_CHECK_IN, reqOptions).toPromise();
  }

  postCheckin(checkin) {
    return this.http.post<Response>(API.POST_CHECK_IN, { message: checkin }).toPromise();
  }

}
