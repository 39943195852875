import { EmployeeModel } from './../model/employee.model';
import { EmployeeService } from './../service/employee.service';
import { Component, OnInit } from '@angular/core';
import { UserModel } from '../model/users.model';
import { Router } from '../../../node_modules/@angular/router';
import { NgbModal } from '../../../node_modules/@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-user-all',
  templateUrl: './view-user-all.component.html',
  styleUrls: ['./view-user-all.component.css']
})
export class ViewUserAllComponent implements OnInit {
  public loading = false;
  public memberList: Array<UserModel> = new Array<UserModel>();
  public search = {
    userName: '',
    position: 'A',
    status: 'A',
    skill: '',
    role:'A'
  };
  public modal = {
    title: "",
    msg: "",
    msg2: "",
    confirm: "",
    cancel: ""
  }
  constructor(
    private employeeService: EmployeeService,
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.getMemberList();
  }

  getMemberList() {
    this.loading = true;
    var user = new UserModel();
    // user.active = 'Y'
    this.employeeService.getEmployeeByCriteria(user).then(res => {
      if (res.responseCode == 200) {
        this.memberList = res.data;
        this.memberList.forEach(ele => {
          if (ele.active == 'Y') {
            ele.active = 'ACTIVE';
            ele.skillArray = '';
            ele.skill.forEach(item => {
              ele.skillArray = ele.skillArray + item.name.toString() + ',';
            });
          } else {
            ele.active = 'INACTIVE';
          }
        })
        this.search = {
          userName: '',
          position: 'A',
          status: 'A',
          skill: '',
          role: 'A',
        };
        this.loading = false;
      }
    })
  }

  searchUser(param) {
    this.loading = true;
    let user = new UserModel();
    if (param.userName != "" || param.userName != undefined) {
      user.username = param.userName;
    }
    if (param.position != 'A') {
      user.position = param.position;
    }
    if (param.role != 'A') {
      user.role = param.role;
    }
    if (param.status != 'A') {
      user.active = param.status;
    }
    if (param.skill != "" || param.skill != undefined) {
      user.skill = param.skill;
    }
    this.employeeService.getEmployeeByCriteria(user).then(res => {
      if (res.responseCode == 200) {
        this.memberList = res.data;
        this.memberList.forEach(ele => {
          if (ele.active == 'Y') {
            ele.active = 'ACTIVE';
            ele.skillArray = '';
            ele.skill.forEach(item => {
              ele.skillArray = ele.skillArray + item.name.toString() + ',';
            });
          } else {
            ele.active = 'INACTIVE';
          }
        })
        this.loading = false;
      } else {
        this.memberList = new Array<UserModel>();
        this.loading = false;
      }
    })
  }

  newUser() {
    this.router.navigate(['/new-users']);
  }

  viewUser(id) {
    this.router.navigate(['view-profile/' + id]);
  }

  deleteUser(content, id) {
    this.modal.title = "Confirm Delete.";
    this.modal.msg = "";
    this.modal.msg2 = "Are you sure to delete this?";
    this.modal.confirm = "Yes"
    this.modal.cancel = "No"
    this.modalService.open(content, { backdrop: 'static' }).result.then((result) => {
      if (result == 'Y') {
        let emp = new EmployeeModel();
        emp._id = id;
        emp.active = 'N';
        this.employeeService.putEmployee(emp).then(res => {
          alert('success');
          this.getMemberList();
        });
      }
    }, (reason) => { });
  }

  editUser(id) {
    this.router.navigate(['/edit-users/' + id]);
  }
}
