import { LovService } from './../service/lov.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PsLeaveModel, FieldErrPsLeave } from '../model/psLeave.model';
import { NgbModal, NgbTimeStruct, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { UserModel } from '../model/users.model';
import * as moment from 'moment';
import * as __ from "lodash";
import { LovModel } from '../model/lov';
import { LeaveWorkService } from '../service/leave-work.service';

@Component({
  selector: 'app-ps-new-leave-work',
  templateUrl: './ps-new-leave-work.component.html',
  styleUrls: ['./ps-new-leave-work.component.css']
})
export class PsNewLeaveWorkComponent implements OnInit {
  public psLeaveModel: PsLeaveModel = new PsLeaveModel();
  public fieldErrList: FieldErrPsLeave = new FieldErrPsLeave();
  public isChange: boolean = false;
  public userLogin: UserModel = new UserModel();
  public leaveTypeList: Array<LovModel> = new Array();
  public modal = {
    title: "",
    msg: "",
    msg2: "",
    confirm: "",
    cancel: ""
  }
  public selectTime: NgbTimeStruct = { hour: 0, minute: 0, second: 0 }
  public minuteStep = 15;
  public startTime = "09:00";
  public endTime = "18:00";
  public loading: boolean = false;
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private lovService: LovService,
    private leaveWorkService: LeaveWorkService
  ) { }

  ngOnInit() {
    this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
    this.psLeaveModel.startTime = this.startTime;
    this.psLeaveModel.endTime = this.endTime;
    this.psLeaveModel.idUser = this.userLogin.idUser;
    this.getLeaveType();
  }

  getLeaveType() {
    let lov = new LovModel()
    lov.lovType_key2 = 'LEAVE_TYPE'
    this.lovService.getLov(lov).then(res => {
      if (res.responseCode == 200) {
        this.leaveTypeList = res.data;
      }
    });
  }

  cancel(content) {
    // if (this.isChange) {
    //     this.modal.title = "Confirm Navigation";
    //     this.modal.msg = "You have unsave changes. If you leave this page, your changes will be lost.";
    //     this.modal.msg2 = "Are You sure you want to leave this page?";
    //     this.modal.confirm = "Leave this page"
    //     this.modal.cancel = "Stay on this page"
    //     this.modalService.open(content, { backdrop: 'static' }).result.then((result) => {
    //         if (result == 'Y') {
    this.router.navigate(['/home-ps']);
    // }
    // }, (reason) => { });
    // } else {
    //     this.router.navigate(['/home-user']);
    // }
  }

  change() {
    this.isChange = true;
  }


  parseTimeFomat(time: NgbTimeStruct): string {
    let hour = time.hour.toString();
    let minute = time.minute.toString();
    if (hour.length == 1) {
      hour = "0" + hour;
    }
    if (minute.length == 1) {
      minute = "0" + minute;
    }
    let timeFormat = hour + ":" + minute;
    return timeFormat;
  }

  openTimeStart(content, index) {
    let timeFormat = "";
    if (undefined != this.psLeaveModel.startTime
      && this.psLeaveModel.startTime) {
      let start = (this.psLeaveModel.startTime).split(':');
      this.selectTime.hour = parseInt(start[0]);
      this.selectTime.minute = parseInt(start[1]);
    } else {
      this.selectTime.hour = 9;
      this.selectTime.minute = 0;
    }
    this.modal.title = "Start Time";
    this.modalService.open(content, { size: 'sm', backdrop: 'static' }).result.then((result) => {
      if (result == 'Y') {
        timeFormat = this.parseTimeFomat(this.selectTime);
        this.psLeaveModel.startTime = timeFormat;
        // this.timeSheetList[index].startTime = timeFormat;
        // this.checkRequiredTs(index, this.timeSheetList[index].startTime, "startTime");
        // this.checkChooseTime(this.timeSheetList[index].startTime, this.timeSheetList[index].endTime, index)
      }
    }, (reason) => { });
  }

  openTimeEnd(content, index) {
    let timeFormat = "";
    if (undefined != this.psLeaveModel.endTime
      && this.psLeaveModel.endTime) {
      let start = (this.psLeaveModel.endTime).split(':');
      this.selectTime.hour = parseInt(start[0]);
      this.selectTime.minute = parseInt(start[1]);
    } else {
      this.selectTime.hour = 18;
      this.selectTime.minute = 0;
    }
    this.modal.title = "End Time";
    this.modalService.open(content, { size: 'sm', backdrop: 'static' }).result.then((result) => {
      if (result == 'Y') {
        timeFormat = this.parseTimeFomat(this.selectTime)
        this.psLeaveModel.endTime = timeFormat;
        // this.checkRequiredTs(index, this.timeSheetList[index].endTime, "endTime");
        // this.checkChooseTime(this.timeSheetList[index].startTime, this.timeSheetList[index].endTime, index);
      }
    }, (reason) => { });
  }

  createLeave(content) {
    this.loading = true;
    let cannotCreate = false;
    if (undefined == this.psLeaveModel.selectDateFrom || !this.psLeaveModel.selectDateFrom) {
      cannotCreate = true;
      this.fieldErrList.selectDateFromErr = true;
    }
    if (undefined == this.psLeaveModel.selectDateTo || !this.psLeaveModel.selectDateTo) {
      cannotCreate = true;
      this.fieldErrList.selectDateToErr = true;
    }
    if (undefined == this.psLeaveModel.startTime || !this.psLeaveModel.startTime) {
      cannotCreate = true;
      this.fieldErrList.startTimeErr = true;
    }
    if (undefined == this.psLeaveModel.endTime || !this.psLeaveModel.endTime) {
      cannotCreate = true;
      this.fieldErrList.endTimeToErr = true;
    }
    if (undefined == this.psLeaveModel.typeLeave || this.psLeaveModel.typeLeave == "") {
      cannotCreate = true;
      this.fieldErrList.typeLeaveErr = true;
    }



    if (!cannotCreate) {
      this.psLeaveModel.selectDateFrom.month = this.psLeaveModel.selectDateFrom.month - 1;
      this.psLeaveModel.selectDateTo.month = this.psLeaveModel.selectDateTo.month - 1;
      this.psLeaveModel.startDate = moment(this.psLeaveModel.selectDateFrom).format("DD-MM-YYYY");
      this.psLeaveModel.endDate = moment(this.psLeaveModel.selectDateTo).format("DD-MM-YYYY");
      let draftCreate: PsLeaveModel = __.cloneDeep(this.psLeaveModel);
      console.log(draftCreate)
      this.leaveWorkService.postLeave(draftCreate).then(res => {
        if (res.responseCode == 200) {
          console.log("Success");
          this.modal.title = "Success";
          this.modal.msg = "Create Leave Success.";
          this.modal.confirm = "OK"
          this.modalService.open(content, { size: 'sm', backdrop: 'static' }).result.then((result) => {
            if (result == 'Y') {
              this.router.navigate(['/home-ps']);
            }
          }, (reason) => { });
        }
        this.loading = false;
      })
    }else{
      this.loading = false;
    }

  }

  checkRequiredDate() {
    // console.log(this.timeSheetList[index].selectDate)
    if (undefined != this.psLeaveModel.selectDateFrom && this.psLeaveModel.selectDateFrom) {
      this.fieldErrList.selectDateFromErr = false;
    } else {
      this.fieldErrList.selectDateFromErr = true;
    }

    if (undefined != this.psLeaveModel.selectDateTo && this.psLeaveModel.selectDateTo) {
      this.fieldErrList.selectDateToErr = false;
    } else {
      this.fieldErrList.selectDateToErr = true;
    }

    if (undefined != this.psLeaveModel.typeLeave && this.psLeaveModel.typeLeave) {
      this.fieldErrList.typeLeaveErr = false;
    } else {
      this.fieldErrList.typeLeaveErr = true;
    }
  }

}
