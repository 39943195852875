import { UserModel } from './../model/users.model';
import { RoutineModel } from './../model/routine.model';
import { Injectable } from '@angular/core';
import { LovModel } from '../model/lov';
import { LovService } from '../service/lov.service';
import { RoutineService } from '../service/routine.service';
import { EmployeeService } from '../service/employee.service';

@Injectable({
    providedIn: 'root'
})
export class ViewTimeSheetService {

    constructor(
        private lovService: LovService,
        private routineService: RoutineService,
        private employeeService: EmployeeService
    ) { }

    getLov(lov: LovModel) {
        return this.lovService.getLov(lov);
    }

    getRoutine(routine: RoutineModel) {
        return this.routineService.getRoutineCriteria(routine);
    }

    getMemberList(user: UserModel) {
        return this.employeeService.getEmployeeByCriteria(user);
    }

    getListRoutineView(routine: RoutineModel) {
        return this.routineService.getListRoutineView(routine);
    }

    getDistinctProjectCode(idUser) {
        return this.routineService.getDistinctProjectCode(idUser);
    }

    getListRoutineViewForAdmin(routine: RoutineModel, selectRole: any) {
        if (selectRole.toUpperCase() == "GENERAL") {
            return this.routineService.getListRoutineView(routine);
        } else if (selectRole.toUpperCase() == "PROFESSIONAL SERVICE") {
            return this.routineService.getListRoutineViewPs(routine);
        } else {
            return this.routineService.getListRoutineView(routine);
        }
    }

    getRoutineByTypeSheet(routine: RoutineModel, selectRole: any) {
        if (selectRole.toUpperCase() == "GENERAL") {
            return this.routineService.getRoutineCriteria(routine);
        } else if (selectRole.toUpperCase() == "PROFESSIONAL SERVICE") {
            return this.routineService.getPSRoutineCriteria(routine);
        } else {
            return this.routineService.getRoutineCriteria(routine);
        }
    }
}
