import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class PassedParamsService {

	private adminMenu: string = "";
	private menu: string = "";

	constructor() {
		this.adminMenu = "timeSheet";
		this.menu = "timeSheet";
	}

	getAdminMenu() {
		return this.adminMenu
	}

	setAdminMenu(menu: string) {
		this.adminMenu = menu;
	}

	getMenu() {
		return this.menu
	}

	setMenu(menu: string) {
		this.menu = menu;
	}
}
