import { Injectable } from '@angular/core';
import { UserModel } from '../model/users.model';
import { HttpParams, HttpClient } from '@angular/common/http';
import { API } from '../shared/url-constant';
import { EmployeeService } from './../service/employee.service';

@Injectable({
    providedIn: 'root'
})
export class RepasswordService {

    constructor(
        public http: HttpClient,
        private employeeService: EmployeeService
    ) { }
  
    getUserLogin(user: UserModel) {
        let httpParams = new HttpParams()
            .set('username', user.username.toUpperCase());
        const requestOptions = {
            params: httpParams
        };
        return this.http.get<any>(API.TS_LOGIN, requestOptions).toPromise();
    }
     
    changePassword(emp) {
        return this.employeeService.putEmployee(emp);
    }
}
