import { Component, OnInit } from '@angular/core';
import { UserModel } from '../model/users.model';
import { EmployeeService } from '../service/employee.service';
import { FieldRequireCreateUser } from '../model/fieldRequired';
import { Router, ActivatedRoute } from '../../../node_modules/@angular/router';
import * as CryptoJS from 'crypto-js';
import { TOKEN } from '../shared/url-constant';

@Component({
    selector: 'app-edit-user',
    templateUrl: './edit-user.component.html',
    styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {
    public loading: boolean = false;
    public userLogin: UserModel = new UserModel();
    public employeeModel = new UserModel();
    public member = new UserModel();
    public fieldErrList: FieldRequireCreateUser = new FieldRequireCreateUser();
    public routineId: any;
    public success: boolean = false;

    constructor(
        private employeeService: EmployeeService,
        private router: Router,
        private route: ActivatedRoute,
    ) { }
  
    ngOnInit() {
        this.route.params.subscribe(params => {
            this.routineId = params.id;
        });
        this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
        this.getProfile(this.routineId);
    }

    getProfile(id) {
        this.loading = true;
        let userModel = new UserModel()
        userModel.idUser = id
        this.employeeService.getEmployeeByCriteria(userModel).then(res => {
            if (res.responseCode == 200) {
                this.member = res.data[0];
                this.employeeModel = res.data[0];
                this.loading = false;
            }
    
        });
    }
  
    cancel() {
        this.router.navigate(['/home']);
    }

    updateUser(personal) {
        this.loading = true;
        // personal.idUser = this.userLogin.idUser;
        personal.last_upd = new Date();
        personal.updated_by = this.userLogin.username;
        this.employeeService.putEmployee(personal).then(res => {
            if (res.responseCode == 200) {
                this.success = true;
                setTimeout(() => {
                    this.success = false;
                }, 1000);
            }
            this.loading = false;
        });
    }

    resetPassword(personal) {
        const random = new Array(12).join().replace(/(.|$)/g, function () {
            let r = ((Math.random() * 36) | 0)
                .toString(36)
                [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
            return r;
        });
        personal.password = CryptoJS.AES.encrypt(random, TOKEN.LOGIN).toString();
        personal.last_upd = new Date();
        personal.updated_by = this.userLogin.username;
        this.employeeService.putEmployee(personal).then(res => {
            if (res.responseCode == 200) {
                this.success = true;
                alert("Reset Password Success!: " + random);
            }
            this.loading = false;
        });
    }
}
