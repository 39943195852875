import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from '../shared/url-constant';
import { Response } from '../model/response';
import { LovModel } from '../model/lov';

@Injectable({
    providedIn: 'root'
})
export class LovService {

    constructor(
        public http: HttpClient
    ) { }

    getLov(lov: LovModel) {
        let httpParams = new HttpParams();
        if (undefined != lov.activeFlg_key1 && lov.activeFlg_key1) {
            httpParams = httpParams.set('activeFlg_key1', lov.activeFlg_key1);
        }
        if (undefined != lov.lovType_key2 && lov.lovType_key2) {
            httpParams = httpParams.set('lovType_key2', lov.lovType_key2);
        }
        if (undefined != lov.lovVal01_data && lov.lovVal01_data) {
            httpParams = httpParams.set('lovVal01_data', lov.lovVal01_data);
        }
        if (undefined != lov.lovName_data && lov.lovName_data) {
            httpParams = httpParams.set('lovName_data', lov.lovName_data);
        }
        if (undefined != lov.lovId_key3 && lov.lovId_key3) {
            httpParams = httpParams.set('lovId_key3', lov.lovId_key3);
        }
        if (undefined != lov.lovCode_key0 && lov.lovCode_key0) {
            httpParams = httpParams.set('lovCode_key0', lov.lovCode_key0);
        }
        if (undefined != lov.lovVal12_data && lov.lovVal12_data) {
            httpParams = httpParams.set('lovVal12_data', lov.lovVal12_data);
        }
        if (undefined != lov.lovVal24_data && lov.lovVal24_data) {
            httpParams = httpParams.set('lovVal24_data', lov.lovVal24_data);
        }
        if (undefined != lov._id && lov._id) {
            httpParams = httpParams.set('_id', lov._id);
        }
        const reqOptions = {
            params: httpParams
        };
        return this.http.get<Response>(API.GET_LOV, reqOptions).toPromise();
    }

    postLov(lov) {
        return this.http.post<Response>(API.POST_LOV, { message: lov }).toPromise();
    }

    deleteProjectLov(lov){
        return this.http.post<Response>(API.PUT_LOV, { message: lov }).toPromise();
    }

    putLov(lov) {
        return this.http.post<Response>(API.PUT_LOV, { message: lov }).toPromise();
    }

}
