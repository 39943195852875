// Service
import { Injectable } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { UserModel } from '../model/users.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from '../shared/url-constant';

/** A router wrapper, adding extra functions. */
@Injectable()
export class RouterExtService {
    public userLogin: UserModel = new UserModel();
    private previousUrl: string = undefined;
    private currentUrl: string = undefined;

    constructor(
        private router: Router,
        public http: HttpClient
    ) {
        this.currentUrl = this.router.url;
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.previousUrl = this.currentUrl;
                this.currentUrl = event.url;
            }
        });
    }

    public getPreviousUrl() {
        return this.previousUrl;
    }

    getPicFile() {
        this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
        let httpParams = new HttpParams();
        httpParams = httpParams.set('idUser', this.userLogin.idUser);
        const reqOptions = {
            params: httpParams
        };
        return this.http.get(API.GET_PIC, reqOptions)

    }
}