import { EmployeeModel } from './../model/employee.model';
import { LovModel } from './../model/lov';
import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { UserModel } from '../model/users.model';
import { API } from '../shared/url-constant';
import { RoutineModel } from '../model/routine.model';
import { RoutineService } from '../service/routine.service';
import { LovService } from '../service/lov.service';
import { EmployeeService } from '../service/employee.service';

@Injectable({
  providedIn: 'root'
})
export class ViewAllTimeSheetService {

  constructor(
    public http: HttpClient,
    private routineService: RoutineService,
    private lovService: LovService,
    private employeeService: EmployeeService
  ) { }

  getRoutine(routine: RoutineModel) {
    return this.routineService.getRoutineOrigin(routine);
  }


  delRoutine(routine: RoutineModel) {
    return this.routineService.delRoutine(routine);
  }

  getListRoutineView(routine: RoutineModel) {
    return this.routineService.getListRoutineView(routine);
  }

  getLov(lov: LovModel) {
    return this.lovService.getLov(lov);
  }

  getEmployeeProjectList(emp: EmployeeModel) {
    return this.employeeService.getEmployeeProjectList(emp);
  }


}
