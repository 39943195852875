import { Component, OnInit } from '@angular/core';
import { LovModel } from '../model/lov';
import { CreateTimeSheetFuncService } from './create-time-sheet-func.service';
import { ProjectFunctionModel } from '../model/projectFunction';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeModel } from '../model/employee.model';
import { UserModel } from '../model/users.model';

@Component({
  selector: 'app-create-time-sheet-func',
  templateUrl: './create-time-sheet-func.component.html',
  styleUrls: ['./create-time-sheet-func.component.css']
})
export class CreateTimeSheetFuncComponent implements OnInit {
  public loading: boolean = false;
  public isSelectFiltersProjectCode: boolean = false;

  public functionModel: ProjectFunctionModel = new ProjectFunctionModel();
  public funcProjectList: Array<ProjectFunctionModel> = new Array<ProjectFunctionModel>();
  public projectList: Array<LovModel> = new Array<LovModel>();
  public userLogin: UserModel = new UserModel();
  public personalProjectList: Array<LovModel> = new Array();
  public modal = {
    title: "",
    msg: "",
    msg2: "",
    confirm: "",
    cancel: ""
  }
  constructor(
    private createTimeSheetFuncService: CreateTimeSheetFuncService,
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
    // this.getProjectName();
    this.getListProject()
    let searchTemp = JSON.parse(localStorage.getItem('searchFunction'));
    if (searchTemp != undefined && searchTemp != null && searchTemp != "") {
      this.functionModel = searchTemp
      this.searchFunction(this.functionModel)
    }

  }

  async getListProject() {
    let emp = new EmployeeModel()
    emp.idUser = this.userLogin.idUser;
    this.createTimeSheetFuncService.getEmployeeProjectList(emp).then(res => {
      if (res.responseCode == 200) {
        this.personalProjectList = res.data;
      }
    });
  }

  getProjectName() {
    this.loading = true;

    let lov = new LovModel();
    lov.lovType_key2 = "PROJECT_NAME";
    lov.activeFlg_key1 = "Y";
    this.createTimeSheetFuncService.getLov(lov).then(res => {
      if (res.responseCode == 200) {
        this.projectList = res.data;
      }
    });
    this.loading = false;
  }

  searchFunction(functionModel) {
    this.functionModel = functionModel;
    this.loading = true;
    this.isSelectFiltersProjectCode = false;
    if (this.functionModel.projectCode == "" || this.functionModel.projectCode == null) {
      this.isSelectFiltersProjectCode = true;
    }
    if (!this.isSelectFiltersProjectCode) {
      localStorage.setItem('searchFunction', JSON.stringify(this.functionModel));
      this.createTimeSheetFuncService.searchFunction(this.functionModel).then(res => {
        if (res.responseCode == 200) {
          this.funcProjectList = res.data;
        } else {
          this.funcProjectList = new Array<ProjectFunctionModel>();
        }
      });
    }
    this.loading = false;
  }

  newFunction() {
    localStorage.removeItem('searchFunction')
    this.router.navigate(['/new-function']);
  }

  editFunction(id) {
    this.router.navigate(['/edit-function/' + id]);
  }

  deleteProject(content, id) {
    this.modal.title = "Confirm Delete.";
    this.modal.msg = "";
    this.modal.msg2 = "Are you sure to delete this?";
    this.modal.confirm = "Yes"
    this.modal.cancel = "No"
    this.modalService.open(content, { backdrop: 'static' }).result.then((result) => {
      if (result == 'Y') {
        this.createTimeSheetFuncService.deleteFunction(id).then(res => {
          // if (res. == 200) {
          alert('success');
          // }
        });
        // let emp = new EmployeeModel();
        // emp._id = id;
        // emp.active = 'N';
        // this.employeeService.putEmployee(emp).then(res => {

        //   this.getMemberList();
        // });
      }
    }, (reason) => { });
  }


}
