import { RoutineService } from '../service/routine.service';
import { LovModel } from '../model/lov';
import { Injectable } from '@angular/core';
import { LovService } from '../service/lov.service';
import { ProjectFunctionModel } from '../model/projectFunction';
import { ProjectFunctionService } from '../service/project-function.service';

@Injectable({
    providedIn: 'root'
})
export class EditTimesheetService {

    constructor(
        private lovService: LovService,
        private routineService: RoutineService,
        private projectFunctionService: ProjectFunctionService
    ) { }
  
    getLov(lov: LovModel) {
        return this.lovService.getLov(lov);
    }

    updateRoutine(routine) {
        return this.routineService.updateRoutine(routine);
    }

    getRoutine(routine) {
        return this.routineService.getRoutineOrigin(routine);
    }

    searchFunction(func: ProjectFunctionModel) {
        return this.projectFunctionService.searchFunction(func);
    }
}
