import { Component, OnInit } from '@angular/core';
import { UserModel } from '../model/users.model';
import { Router } from '@angular/router';
import { RouterExtService } from '../service/routerExtService.service';
import { DomSanitizer } from '../../../node_modules/@angular/platform-browser';
import { EmployeeService } from '../service/employee.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    public userLogin: UserModel = new UserModel();
    public isAdmin: boolean = false;
    public thumbnail: any;
    public member: UserModel = new UserModel();
    public isPageAdmin: boolean = false;
    constructor(
        private router: Router,
        private routerService: RouterExtService,
        private sanitizer: DomSanitizer,
        private employeeService: EmployeeService
    ) { }

    ngOnInit() {
        this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
        // this.thumbnail = this.userLogin.img
        if (undefined != this.userLogin && this.userLogin) {
            this.userLogin.fName = this.userLogin.fName.toUpperCase();
            this.userLogin.lName = this.userLogin.lName.toUpperCase();
            if (this.userLogin.role.toUpperCase() == "ADMIN") {
                this.isAdmin = true;
            }
            this.getProfile();
        } else {
            this.router.navigate(['/login']);
        }
        if (this.router.url == '/home') {
            this.isPageAdmin = true;
        }
    }

    logout() {
        localStorage.removeItem('curentUserLogin');
        localStorage.removeItem('searchRoutine');
        localStorage.removeItem('searchFunction');
        console.log(localStorage.getItem('curentUserLogin'))
        this.router.navigate(['/login']);
    }

    routeHome() {
        this.isPageAdmin=  false;
        this.router.navigate(['/home-user']);
    }

    changePassword() {
        this.isPageAdmin =  false;
        this.router.navigate(['/change-password']);
    }

    changeRole() {
        this.isPageAdmin =  true;
        this.router.navigate(['/home']);
    }

    changeFirstHome() {
        this.isPageAdmin =  false;
        this.router.navigate(['/home-user']);
    }

    editProfile() {
        this.isPageAdmin =  false;
        this.router.navigate(['/edit-profile']);
    }

    viewProfile(id) {
        this.isPageAdmin =  false;
        this.router.navigate(['/view-profile/' + id]);
    }

    getProfile() {
        // this.routerService.getPicFile().subscribe((baseImage: any) => {
        // if (this.userLogin.img != "" && this.userLogin.img != undefined) {
        //     // let url = //'data:image/jpeg;base64,' + baseImage.data;
        //     this.thumbnail = this.userLogin.img //this.sanitizer.bypassSecurityTrustUrl(url);
        // } else {
        //     this.thumbnail = 'https://mdbootstrap.com/img/Photos/Avatars/img%20(20).jpg'
        // }
        // });
        let userModel = new UserModel()
        userModel.idUser = this.userLogin.idUser;
        this.employeeService.getUserLogin(userModel).then(res => {
            if (res.responseCode == 200) {
                this.member = res.data[0];
                if (this.member.img == "" || this.member.img == undefined) {
                    // this.member.img = 'https://mdbootstrap.com/img/Photos/Avatars/img%20(20).jpg'
                    this.thumbnail = null
                } else {
                    this.thumbnail = this.member.img
                }
            }
        });
    }



}
