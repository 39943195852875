export class XlsxExportModel {
    name: string;
    lastName: string;
    year: string;
    data: Array<MonthDetailModel>;
    allDay : Array<any>
}

export class MonthDetailModel {
    orderBy: number;
    month: string;
    data: Array<DetailModel>;
    sumTotal: number = 0;
}

export class DetailModel {
    date: string;
    projectName : string;
    feature: string;
    detail: string;
    start: string;
    end: string;
    total:string;
    ot : string;
    specialCase : string;

    detailRow: number = 0;
    orderBy: number = 0;
}

export class XlsxExportLeaveModel {
    public _id: string = ""
    public idUser: string = "";
    public startTime: string = "";
    public startDate: string = "";
    public endTime: string = "";
    public year: string = "";
    public typeLeave: string = "";
    public typeLeaveName: string = "";
    public endDate: string = "";
    public detail: string = "";
    public name: string = "";
    public lName: string = "";
}