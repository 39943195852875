import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserModel } from '../model/users.model';
import * as CryptoJS from 'crypto-js';
// import { TOKEN } from 'src/environments/environment';
import { TOKEN } from '../shared/url-constant';

import { LoginService } from './login.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    public showSplash = true; // <-- show animation
    public showContent = false;
    public error: boolean = false;
    public empty: boolean = false;
    public userInfo: UserModel = new UserModel();
    public userObj: UserModel = new UserModel();

    constructor(
        private router: Router,
        private loginService: LoginService,
    ) {
        setTimeout(() => {
            this.showSplash = false
            this.showContent = true;
        }, 1000); // <-- hide animation after 3s
    }

    ngOnInit() {
    }

    login(user: UserModel) {
        this.error = false;
        if ((user.username != undefined && user.username) && (user.password != undefined && user.password)) {
            this.empty = false;
            let userData: UserModel = new UserModel();
            userData.username = user.username;
            this.loginService.getUserLogin(userData).subscribe(result => {
                if (result.responseCode == 200) {
                    this.userInfo = result.data[0];
                    let dePass = (CryptoJS.AES.decrypt(this.userInfo.password, TOKEN.LOGIN)).toString(CryptoJS.enc.Utf8);
                    if (dePass == user.password) {
                        localStorage.setItem('curentUserLogin', JSON.stringify(this.userInfo));
                        // this.router.navigate(['/project/', this.userInfo.role]);
                        if (this.userInfo.role.toUpperCase() == "ADMIN") {
                            // this.router.navigate(['/view-timesheets-all']);
                            // this.router.navigate(['/home']);
                            this.router.navigate(['/home-user']);
                        } else if (this.userInfo.role.toUpperCase() == "PROFESSIONAL SERVICE") {
                            this.router.navigate(['/home-ps']);
                        } else {
                            this.router.navigate(['/home-user']);
                            // this.router.navigate(['/view-timesheets-all']);
                        }
                    } else {
                        this.error = true;
                    }
                } else {
                    this.error = true;
                }
            });
        } else {
            this.empty = true;
        }
    }

    checkErrorEmpty(user: UserModel) {
        if ((undefined != user.username && user.username)
            && (undefined != user.password && user.password)) {
            this.empty = false;
        }
    }

}
