import { XlsxExportLeaveModel } from './../model/excel.model';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";
import { XlsxExportModel } from '../model/excel.model';
import { async } from '@angular/core/testing';
import * as JSZip from "jszip"


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const EXCEL_FILENAME = 'ENTRONICA_TimeSheet_'

@Injectable()
export class ExcelServiceFunction {

    public fontHeader: Partial<ExcelProper.Font> = { name: 'Tahoma', bold: true, size: 8 };
    public fontContent: Partial<ExcelProper.Font> = { name: 'Tahoma', size: 8 };
    public alignmentCenter: Partial<ExcelProper.Alignment> = { vertical: 'middle', horizontal: 'center' };
    public alignmentTopCenter: Partial<ExcelProper.Alignment> = { vertical: 'top', horizontal: 'center' };
    public alignmentTopLeft: Partial<ExcelProper.Alignment> = { vertical: 'top', horizontal: 'left', wrapText: true };
    public alignmentBottomLeft: Partial<ExcelProper.Alignment> = { vertical: 'bottom', horizontal: 'left' };
    public borderOutline: Partial<ExcelProper.Borders> = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}};
    public borderbottomline: Partial<ExcelProper.Borders> = {bottom: {style: 'medium'}};
    public headerFooterStyles: ExcelProper.Fill = {type: 'pattern', pattern:'solid', fgColor:{argb:'d9d9d9'}};

    public exportAsXLSXFile(json: XlsxExportModel, projectCode: string, sheetName: string) {
        var workbook: ExcelProper.Workbook = new Excel.Workbook();

        
        json.data.forEach(monthData => {
            let worksheet = workbook.addWorksheet(monthData.month);
            worksheet.pageSetup.margins = {
                left: 0.5, right: 0.5, top: 0.75, bottom: 0.75, header: 0, footer: 0
            };
            worksheet.pageSetup.paperSize = 9;
            worksheet.pageSetup.horizontalCentered = true;
            worksheet.addRow(['PROJECT:', projectCode]);
            worksheet.addRow(['Operator:', json.name + " " + json.lastName]);
            worksheet.addRow(['Month:', monthData.month + ' ' + json.year]);
            worksheet.addRow([""]);
            worksheet.columns = [
                { key: 'date', width: 9.7 },
                { key: 'feature', width: 20.7 },
                { key: 'detail', width: 60.7 },
                { key: 'start', width: 7.7 },
                { key: 'end', width: 7.7 },
                { key: 'total', width: 7.7 }
            ];
            worksheet.mergeCells('A5:F6');
            worksheet.getCell('A5').value = "TIME SHEET";
            worksheet.getCell('A5').fill = this.headerFooterStyles;
            worksheet.addRow(["DATE","JOB FEATURE","JOB DETAIL","START","END","TOTAL"]);
            
            worksheet.addRows(monthData.data);
            worksheet.columns.forEach(column => {
                worksheet.getColumn(column.key).font = this.fontContent;
                if (column.key == 'detail') {
                    worksheet.getColumn(column.key).alignment = this.alignmentTopLeft;
                }else {
                    worksheet.getColumn(column.key).alignment = this.alignmentTopCenter;
                }
            });
            for (let i = 5; i < 8; i++) {
                worksheet.getRow(i).font = this.fontHeader;
                worksheet.getRow(i).alignment = this.alignmentCenter;
            }
            for (let i = 1; i < 4; i++) {
                worksheet.getRow(i).font = this.fontContent;
                worksheet.getCell('A'+i).font = this.fontHeader;
                worksheet.mergeCells( 'B' + i + ':F' + i );
                worksheet.getCell('B'+i).border = this.borderbottomline;
                worksheet.getRow(i).alignment = this.alignmentBottomLeft;
            }
            let sizeData = monthData.data.length
            let addrCell = 8 + sizeData;
            for (let i = 0; i < 2; i++) {
                worksheet.mergeCells( 'A' + (addrCell+i) + ':E' + (addrCell+i) );
                worksheet.getRow((addrCell+i)).font = this.fontHeader;
                worksheet.getCell( 'A' + (addrCell+i) ).fill = this.headerFooterStyles;
                worksheet.getCell( 'F' + (addrCell+i) ).fill = this.headerFooterStyles;
                worksheet.getRow((addrCell+i)).alignment = this.alignmentCenter;
            }
            worksheet.getCell( 'A' + addrCell ).value = "TOTAL TIME";
            worksheet.getCell( 'F' + addrCell ).value = (monthData.sumTotal).toFixed(2);
            worksheet.getCell( 'A' + (addrCell+1) ).value = "TOTAL DAY";
            worksheet.getCell( 'F' + (addrCell+1) ).value = (monthData.sumTotal/8).toFixed(2);

            let cols = ["D", "E", "F"]
            let rowSign = worksheet.rowCount+5;
            cols.forEach(col => {
                worksheet.getCell( col+(rowSign) ).border = this.borderbottomline;
            });
            worksheet.mergeCells( 'D'+(rowSign+1) + ':F'+(worksheet.rowCount+1) );
            worksheet.getCell( 'D'+(rowSign+1) ).value = "(  SUPERVISOR SIGNATURE  )";
            worksheet.getRow(rowSign+1).font = this.fontHeader;
            worksheet.getRow(rowSign+1).alignment = this.alignmentCenter;
            rowSign = worksheet.rowCount+3;
            cols.forEach(col => {
                worksheet.getCell( col+(rowSign) ).border = this.borderbottomline;
            });
            worksheet.mergeCells( 'D'+(rowSign+1) + ':F'+(worksheet.rowCount+1) );
            worksheet.getCell( 'D'+(rowSign+1) ).value = "DATE";
            worksheet.getRow(rowSign+1).font = this.fontHeader;
            worksheet.getRow(rowSign+1).alignment = this.alignmentCenter;

            let j = 0;
            for (let i = 1; i <= worksheet.rowCount; i++) {
                worksheet.getRow(i).height = 16;
                if (i > 4 && i <= addrCell+1) {
                    let cols = ["A", "B", "C", "D", "E", "F"]
                    cols.forEach(col => {
                        worksheet.getCell(col+i).border = this.borderOutline;
                    });
                    if (i > 7 && j < monthData.data.length) {
                        if (monthData.data[j].detailRow != 0 && undefined != monthData.data[j].detailRow) {
                            worksheet.getRow(i).height = 16*(monthData.data[j].detailRow);
                        }
                        j++;
                    }
                }
            }
        });
        workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], { type: EXCEL_TYPE }); 
            FileSaver.saveAs(blob, EXCEL_FILENAME + sheetName + EXCEL_EXTENSION); 
        });
    }


    async exportAsXLSXFileForPS(json: XlsxExportModel, projectCode: string, sheetName: string , zip : JSZip) {
        var workbook: ExcelProper.Workbook = new Excel.Workbook();


         json.data.map(monthData => {

            let worksheet = workbook.addWorksheet(monthData.month);
            worksheet.pageSetup.margins = {
                left: 0.5, right: 0.5, top: 0.75, bottom: 0.75, header: 0, footer: 0
            };
            worksheet.pageSetup.paperSize = 9;
            worksheet.pageSetup.horizontalCentered = true;
            worksheet.addRow(['PROJECT:', projectCode]);
            worksheet.addRow(['Operator:', json.name + " " + json.lastName]);
            worksheet.addRow(['Month:', monthData.month + ' ' + json.year]);
            worksheet.addRow([""]);
            worksheet.columns = [
                { key: 'date', width: 9.7 },
                { key: 'detail', width: 60.7 },
                { key: 'start', width: 7.7 },
                { key: 'end', width: 7.7 },
                { key: 'total', width: 7.7 },
                { key: 'projectName', width: 20.7 },
                { key: 'feature', width: 20.7 },
                { key: 'ot', width: 20.7 },
                { key: 'specialCase', width: 20.7 },
            ];
            
            worksheet.mergeCells('A5:E6');
            worksheet.getCell('A5').value = "TIME SHEET";
            worksheet.getCell('A5').fill = this.headerFooterStyles;
            worksheet.addRow(["DATE","JOB DETAIL","START","END","TOTAL","PROJECT NAME","JOB FEATURE","OT","Special Case"]);
            
            worksheet.addRows(monthData.data);
            worksheet.columns.forEach(column => {
                worksheet.getColumn(column.key).font = this.fontContent;
                if (column.key == 'detail') {
                    worksheet.getColumn(column.key).alignment = this.alignmentTopLeft;
                }else {
                    worksheet.getColumn(column.key).alignment = this.alignmentTopCenter;
                }
            });
            for (let i = 5; i < 8; i++) {
                worksheet.getRow(i).font = this.fontHeader;
                worksheet.getRow(i).alignment = this.alignmentCenter;
            }
            for (let i = 1; i < 4; i++) {
                worksheet.getRow(i).font = this.fontContent;
                worksheet.getCell('A'+i).font = this.fontHeader;
                worksheet.mergeCells( 'B' + i + ':E' + i );
                worksheet.getCell('B'+i).border = this.borderbottomline;
                worksheet.getRow(i).alignment = this.alignmentBottomLeft;
            }
            let sizeData = monthData.data.length
            let addrCell = 8 + sizeData;
            for (let i = 0; i < 2; i++) {
                worksheet.mergeCells( 'A' + (addrCell+i) + ':D' + (addrCell+i) );
                worksheet.getRow((addrCell+i)).font = this.fontHeader;
                worksheet.getCell( 'A' + (addrCell+i) ).fill = this.headerFooterStyles;
                worksheet.getCell( 'D' + (addrCell+i) ).fill = this.headerFooterStyles;
                worksheet.getRow((addrCell+i)).alignment = this.alignmentCenter;
            }
            worksheet.getCell( 'A' + addrCell ).value = "TOTAL TIME";
            worksheet.getCell( 'E' + addrCell ).value = (monthData.sumTotal).toFixed(2);
            worksheet.getCell( 'E' + addrCell ).fill = this.headerFooterStyles;
            worksheet.getCell( 'A' + (addrCell+1) ).value = "TOTAL DAY";
            worksheet.getCell( 'E' + (addrCell+1) ).value = (monthData.sumTotal/8).toFixed(2);
            worksheet.getCell( 'E' + (addrCell+1) ).fill = this.headerFooterStyles;
            let cols = ["C", "D", "E"]
            let rowSign = worksheet.rowCount+5;
            cols.forEach(col => {
                worksheet.getCell( col+(rowSign) ).border = this.borderbottomline;
            });
            worksheet.mergeCells( 'C'+(rowSign+1) + ':E'+(worksheet.rowCount+1) );
            worksheet.getCell( 'C'+(rowSign+1) ).value = "(  SUPERVISOR SIGNATURE  )";
            worksheet.getRow(rowSign+1).font = this.fontHeader;
            worksheet.getRow(rowSign+1).alignment = this.alignmentCenter;
            rowSign = worksheet.rowCount+3;
            cols.forEach(col => {
                worksheet.getCell( col+(rowSign) ).border = this.borderbottomline;
            });
            worksheet.mergeCells( 'C'+(rowSign+1) + ':E'+(worksheet.rowCount+1) );
            worksheet.getCell( 'C'+(rowSign+1) ).value = "DATE";
            worksheet.getRow(rowSign+1).font = this.fontHeader;
            worksheet.getRow(rowSign+1).alignment = this.alignmentCenter;

            let j = 0;
            for (let i = 1; i <= worksheet.rowCount; i++) {
                worksheet.getRow(i).height = 16;
                if (i > 4 && i <= addrCell+1) {
                    let cols = ["A", "B", "C", "D", "E", "F", "G","H","I"]
                    cols.forEach(col => {
                        worksheet.getCell(col+i).border = this.borderOutline;
                    });
                    if (i > 7 && j < monthData.data.length) {
                        if (monthData.data[j].detailRow != 0 && undefined != monthData.data[j].detailRow) {
                            worksheet.getRow(i).height = 16*(monthData.data[j].detailRow);
                        }
                        j++;
                    }
                }
            }
        });
        const buf = await workbook.xlsx.writeBuffer()
        const blob = new Blob([buf], { type: EXCEL_TYPE }); 

        zip.file(EXCEL_FILENAME + sheetName + EXCEL_EXTENSION,blob)
        // FileSaver.saveAs(blob, EXCEL_FILENAME + sheetName + EXCEL_EXTENSION); 
      
    }

    public exportAsXLSXFileLeave(json: Array<XlsxExportLeaveModel>, type: string, sheetName: string) {
        var workbook: ExcelProper.Workbook = new Excel.Workbook();

        console.log(json)
        // json.forEach(monthData => {
            let worksheet = workbook.addWorksheet('All');
            worksheet.pageSetup.margins = {
                left: 0.5, right: 0.5, top: 0.75, bottom: 0.75, header: 0, footer: 0
            };
            worksheet.pageSetup.paperSize = 9;
            worksheet.pageSetup.horizontalCentered = true;
            worksheet.addRow(['REPORT:', type]);
            worksheet.columns = [
                { key: 'name', width: 30.7 },
                { key: 'startTime', width: 10.7 },
                { key: 'startDate', width: 20.7 },
                { key: 'endTime', width: 10.7 },
                { key: 'endDate', width: 20.7 },
                { key: 'typeLeaveName', width: 20.7 }
            ];
            // worksheet.mergeCells('A5:F6');
            // worksheet.getCell('A5').value = "TIME SHEET";
            // worksheet.getCell('A5').fill = this.headerFooterStyles;
            worksheet.addRow(["NAME","START TIME", "START DATE","END TIME","END DATE","REASON"]);
            
            worksheet.addRows(json);
            // worksheet.columns.forEach(column => {
            //     worksheet.getColumn(column.key).font = this.fontContent;
            //     if (column.key == 'detail') {
            //         worksheet.getColumn(column.key).alignment = this.alignmentTopLeft;
            //     }else {
            //         worksheet.getColumn(column.key).alignment = this.alignmentTopCenter;
            //     }
            // });
            // for (let i = 5; i < 8; i++) {
            //     worksheet.getRow(i).font = this.fontHeader;
            //     worksheet.getRow(i).alignment = this.alignmentCenter;
            // }
            // for (let i = 1; i < 4; i++) {
            //     worksheet.getRow(i).font = this.fontContent;
            //     worksheet.getCell('A'+i).font = this.fontHeader;
            //     worksheet.mergeCells( 'B' + i + ':F' + i );
            //     worksheet.getCell('B'+i).border = this.borderbottomline;
            //     worksheet.getRow(i).alignment = this.alignmentBottomLeft;
            // }
            // let sizeData = monthData.data.length
            // let addrCell = 8 + sizeData;
            // for (let i = 0; i < 2; i++) {
            //     worksheet.mergeCells( 'A' + (addrCell+i) + ':E' + (addrCell+i) );
            //     worksheet.getRow((addrCell+i)).font = this.fontHeader;
            //     worksheet.getCell( 'A' + (addrCell+i) ).fill = this.headerFooterStyles;
            //     worksheet.getCell( 'F' + (addrCell+i) ).fill = this.headerFooterStyles;
            //     worksheet.getRow((addrCell+i)).alignment = this.alignmentCenter;
            // }
            // worksheet.getCell( 'A' + addrCell ).value = "TOTAL TIME";
            // worksheet.getCell( 'F' + addrCell ).value = (monthData.sumTotal).toFixed(2);
            // worksheet.getCell( 'A' + (addrCell+1) ).value = "TOTAL DAY";
            // worksheet.getCell( 'F' + (addrCell+1) ).value = (monthData.sumTotal/8).toFixed(2);

            // let cols = ["D", "E", "F"]
            // let rowSign = worksheet.rowCount+5;
            // cols.forEach(col => {
            //     worksheet.getCell( col+(rowSign) ).border = this.borderbottomline;
            // });
            // worksheet.mergeCells( 'D'+(rowSign+1) + ':F'+(worksheet.rowCount+1) );
            // worksheet.getCell( 'D'+(rowSign+1) ).value = "(  SUPERVISOR SIGNATURE  )";
            // worksheet.getRow(rowSign+1).font = this.fontHeader;
            // worksheet.getRow(rowSign+1).alignment = this.alignmentCenter;
            // rowSign = worksheet.rowCount+3;
            // cols.forEach(col => {
            //     worksheet.getCell( col+(rowSign) ).border = this.borderbottomline;
            // });
            // worksheet.mergeCells( 'D'+(rowSign+1) + ':F'+(worksheet.rowCount+1) );
            // worksheet.getCell( 'D'+(rowSign+1) ).value = "DATE";
            // worksheet.getRow(rowSign+1).font = this.fontHeader;
            // worksheet.getRow(rowSign+1).alignment = this.alignmentCenter;

            // let j = 0;
            // for (let i = 1; i <= worksheet.rowCount; i++) {
            //     worksheet.getRow(i).height = 16;
            //     if (i > 4 && i <= addrCell+1) {
            //         let cols = ["A", "B", "C", "D", "E", "F"]
            //         cols.forEach(col => {
            //             worksheet.getCell(col+i).border = this.borderOutline;
            //         });
            //         if (i > 7 && j < monthData.data.length) {
            //             if (monthData.data[j].detailRow != 0 && undefined != monthData.data[j].detailRow) {
            //                 worksheet.getRow(i).height = 16*(monthData.data[j].detailRow);
            //             }
            //             j++;
            //         }
            //     }
            // }
        // });
        workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], { type: EXCEL_TYPE }); 
            FileSaver.saveAs(blob, EXCEL_FILENAME + sheetName + EXCEL_EXTENSION); 
        });
    }
}