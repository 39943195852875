import { PsLeaveModel, FieldErrPsLeave } from './../model/psLeave.model';
import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbTimeStruct, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { UserModel } from '../model/users.model';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { LeaveWorkService } from '../service/leave-work.service';
import { EmployeeModel } from '../model/employee.model';
import { LovModel } from '../model/lov';
import { LovService } from '../service/lov.service';

@Component({
  selector: 'app-ps-leave-work',
  templateUrl: './ps-leave-work.component.html',
  styleUrls: ['./ps-leave-work.component.css']
})
export class PsLeaveWorkComponent implements OnInit {

  public psLeaveModel: PsLeaveModel = new PsLeaveModel();
  public psLeaveList: Array<PsLeaveModel> = new Array();
  public fieldErrList: FieldErrPsLeave = new FieldErrPsLeave();
  public isChange: boolean = false;
  public userLogin: UserModel = new UserModel();
  public leaveTypeList: Array<LovModel> = new Array();
  public modal = {
    title: "",
    msg: "",
    msg2: "",
    confirm: "",
    cancel: ""
  }
  public selectTime: NgbTimeStruct = { hour: 0, minute: 0, second: 0 }
  public minuteStep = 15;
  public loading: boolean = false;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private leaveWorkService: LeaveWorkService,
    private lovService: LovService,
  ) { }

  ngOnInit() {
    this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
    this.psLeaveModel.idUser = this.userLogin.idUser;
    this.getLeave();
    this.getLeaveType();
  }

  getLeave() {
    let leave = new PsLeaveModel()
    leave.idUser = this.userLogin.idUser;
    this.leaveWorkService.getLeaveList(leave).then(res => {
      if (res.responseCode == 200) {
        this.psLeaveList = res.data;
      }
    });
  }

  getLeaveType() {
    let lov = new LovModel()
    lov.lovType_key2 = 'LEAVE_TYPE'
    this.lovService.getLov(lov).then(res => {
      if (res.responseCode == 200) {
        this.leaveTypeList = res.data;
      }
    });
  }

  change() {
    this.isChange = true;
  }

  searchLeave(leave: PsLeaveModel) {
    this.loading = true;
    // let leave = new PsLeaveModel()
    // leave.startDate = moment(this.psLeaveModel.selectDateFrom).format('DD-MM-YYYY')
    // leave.endDate = moment(this.psLeaveModel.selectDateTo).format('DD-MM-YYYY')
    leave.idUser = this.userLogin.idUser;
    this.leaveWorkService.getLeaveList(leave).then(res => {
      if (res.responseCode == 200) {
        this.psLeaveList = res.data;
        this.loading = false;
      }
    });
  }

  newLeave() {
    this.router.navigate(['/new-leave-ps']);
  }

  deleteLeave(content, id) {
    this.modal.title = "Confirm Delete.";
    this.modal.msg = "";
    this.modal.msg2 = "Are you sure to delete this?";
    this.modal.confirm = "Yes"
    this.modal.cancel = "No"
    this.modalService.open(content, { backdrop: 'static' }).result.then((result) => {
      if (result == 'Y') {
        this.loading = true;
        let leave = new PsLeaveModel();
        leave._id = id;
        // leave.idUser = this.userLogin.idUser;
        this.leaveWorkService.delLeave(leave).subscribe(result => {
          if (result.responseCode == 200) {
            alert('success')
            this.getLeave()
            // this.getRoutine(this.userLogin, this.search.month, this.search.year, this.search.projectCode);
            this.loading = false;
          }

        });
      }
    }, (reason) => { });
  }


}
