import { Injectable } from '@angular/core';
import { RoutineService } from '../service/routine.service';
import { LovModel } from '../model/lov';
import { LovService } from '../service/lov.service';

@Injectable({
  providedIn: 'root'
})
export class CreateNewProjectService {

  constructor(
    private lovService: LovService,
    private routineService: RoutineService
  ) { }

  checkDup(lov: LovModel) {
    return this.lovService.getLov(lov);
  }
}
