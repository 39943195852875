import { Component, OnInit } from '@angular/core';
import { LovModel } from '../model/lov';
import { LovService } from '../service/lov.service';
import { ActivatedRoute, Router } from '../../../node_modules/@angular/router';
import { FieldRequireCreateTSModel } from '../model/fieldRequired';
import { UserModel } from '../model/users.model';
import { EmployeeService } from '../service/employee.service';

@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.css']
})
export class EditProjectComponent implements OnInit {
  public lovProject: LovModel = new LovModel();
  public routeParams: any;
  public routineId: any;
  public isOkForProjectCode: boolean = false;
  public fieldErrList: FieldRequireCreateTSModel = new FieldRequireCreateTSModel();
  public memberList: Array<UserModel> = new Array<UserModel>();

  constructor(
    private LovService: LovService,
    private route: ActivatedRoute,
    private router: Router,
    private employeeService: EmployeeService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.routeParams = params;
      this.routineId = this.routeParams.id;
      this.getProjectName(this.routineId);
      this.getMemberList();
    });
  }

  getProjectName(id) {
    let lov = new LovModel();
    lov.lovType_key2 = "PROJECT_NAME";
    lov._id = id;
    this.LovService.getLov(lov).then(res => {
      if (res.responseCode == 200) {
        this.lovProject = res.data[0];
      }
    })
  }

  checkDup(lovCode_key) {
    let lov = new LovModel();
    lov.lovType_key2 = "PROJECT_NAME";
    lov.activeFlg_key1 = "Y";
    if (lovCode_key == null || lovCode_key == "") {
      alert("Please insert project code.")
    } else {
      lov.lovCode_key0 = lovCode_key.toUpperCase();
      this.LovService.getLov(lov).then(res => {
        if (res.responseCode == 200) {
          if (res.data.length > 0) {
            this.isOkForProjectCode = false;
            // this.fieldErrList.projectCode = true;
            alert('Is duplicate!!!')
          }
        } else {
          this.isOkForProjectCode = true;
          alert("It's ok.")
        }
      });
    }

  }

  cancel(content) {
    this.router.navigate(['/home']);
  }

  putProject(project) {
    this.LovService.putLov(project).then(res => {
      alert('success')
      this.router.navigate(['/home']);
    })
  }

  getMemberList() {
    let user = new UserModel();
    user.position = 'PM'
    user.active = 'Y';
    this.employeeService.getEmployeeByCriteria(user).then(res => {
      if (res.responseCode == 200) {
        this.memberList = res.data;
        this.memberList.forEach(ele => {
          if (ele.active == 'Y') {
            ele.active = 'ACTIVE';
            ele.skillArray = '';
            ele.skill.forEach(item => {
              ele.skillArray = ele.skillArray + item.name.toString() + ',';
            });
          } else {
            ele.active = 'INACTIVE';
          }
        });
      }
    })
  }

}
