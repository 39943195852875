import { UserModel } from './../model/users.model';
import { Router } from '@angular/router';
import { ViewTimeSheetService } from './view-time-sheet.service';
import { Component, OnInit } from '@angular/core';
import { LovModel } from '../model/lov';
import * as _ from 'underscore'
import * as __ from 'lodash'
import { RoutineModel } from '../model/routine.model';
import * as moment from 'moment'
import { DomSanitizer } from '../../../node_modules/@angular/platform-browser';
import { XlsxExportModel, DetailModel, MonthDetailModel } from '../model/excel.model';
import { ExcelService } from '../service/excel.service';
import { PdfService } from "../service/pdf.service"
import { ExcelServiceFunction } from '../service/excel-with-function.service';
import * as JSZip from "jszip"
import * as FileSaver from 'file-saver';
import { async } from '@angular/core/testing';
import { RoutineService } from '../service/routine.service';
// import { XlsxExportModel, MonthDetailModel, DetailModel } from ;
// import { resolve } from 'path';

@Component({
    selector: 'app-view-time-sheet',
    templateUrl: './view-time-sheet.component.html',
    styleUrls: ['./view-time-sheet.component.css']
})
export class ViewTimeSheetComponent implements OnInit {
    public loading = false;
    public routineList: Array<RoutineModel> = new Array<RoutineModel>();
    public projectList: Array<LovModel> = new Array<LovModel>();
    public years: Array<string> = new Array();
    public months: Array<LovModel> = new Array();
    public timeSheetList: any[];
    public memberList: Array<UserModel> = new Array<UserModel>();
    public isSelectFilters: boolean = true;
    public projectCode: string = "";
    public projectName: string = "";
    public memberId: string = "";
    public selectMonth: string = "";
    public selectRole: string = "GENERAL";
    public selectYear: string = "";
    public listProject: any[];
    public listProjByName: any[];
    public listProjYear: any[];
    public nameList: any[];
    public monthList: Array<LovModel> = new Array<LovModel>();
    public yearsObj: any = {};
    public searchName: string = "";
    public month: string = "";
    public year: string = "";
    public monthCode: string = "ALL";
    public addVacationTimeCheck: boolean = false;
    public isCheck: boolean = false;

    public userLogin: UserModel = new UserModel();
    public isAdmin: boolean = true;
    public isSelectFiltersProjectCode: boolean = false;
    public isFeature = false;
    public typeFile : string = '';
    public exportForPS : boolean;
    public exportForPSBusy : boolean;

    public types: Array<string> = [
        "All", "Normal", "OT"
    ];
    public selectType: string = "All";

    constructor(
        private viewTimeSheetService: ViewTimeSheetService,
        private router: Router,
        private sanitizer: DomSanitizer,
        private excelService: ExcelService,
        private pdfService: PdfService,
        private excelFunctionService: ExcelServiceFunction
    ) {
        this.selectYear = new Date().getFullYear().toString();// "2019";
    }

    ngOnInit() {
        this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
        if (this.userLogin.role.toUpperCase() != "ADMIN") {
            this.isAdmin = false;
        }
        this.getProjectName()
        this.getMonth();
        this.getDropdownYear();
        this.getMemberList('');
    }

    cancel() {
        this.router.navigate(['/home']);
    }

    getDropdownYear() {
        var start = 2010;
        var end = new Date().getFullYear();
        for (var year = start; year <= end; year++) {
            this.years.push(year.toString());
        }
        this.years.reverse();
    }

    getMonth() {
        let lov = new LovModel();
        lov.lovType_key2 = "MONTH";
        lov.activeFlg_key1 = "Y";
        this.viewTimeSheetService.getLov(lov).then(res => {
            if (res.responseCode == 200) {
                this.months = _.sortBy(res.data, "lovId_key3");
                let month = moment().subtract(0, "month").startOf("month").format('MMMM');
                this.selectMonth = month;
            }
        })
    }

    getMemberList(type) {
        var user = new UserModel();
        user.active = 'Y'
        if (type != "" && type != undefined) {
            user.role = type
        }
        this.viewTimeSheetService.getMemberList(user).then(res => {
            if (res.responseCode == 200) {
                this.memberList = res.data;
            }
        })
    }


    getDistrintProjectCode(idUser) {
        return this.viewTimeSheetService.getDistinctProjectCode(idUser).then(res => {
            return res;
        });
    }

    getProjectName() {
        this.loading = true;
        if (this.isAdmin) {
            let lov = new LovModel();
            lov.lovType_key2 = "PROJECT_NAME";
            lov.activeFlg_key1 = "Y";
            this.viewTimeSheetService.getLov(lov).then(res => {
                if (res.responseCode == 200) {
                    this.projectList = res.data;
                }
                this.loading = false;
            })
        } else {
            let lov = new LovModel();
            lov.lovType_key2 = "PROJECT_NAME";
            lov.activeFlg_key1 = "Y";
            this.viewTimeSheetService.getLov(lov).then(res => {
                if (res.responseCode == 200) {
                    this.projectList = res.data;
                }
            }).then(res => {
                let arrayCode = this.getDistrintProjectCode(this.userLogin.idUser);
                arrayCode.then(res => {
                    let arrayTempCode = res.data;
                    let b = []
                    for (let temp of arrayTempCode) {
                        let a = _.findWhere(this.projectList, { lovCode_key0: temp });
                        if (a != undefined) {
                            b.push(a)
                        }
                    }
                    this.projectList = new Array<LovModel>();
                    this.projectList = b;
                })
                this.loading = false;
            })
        }

    }

    searchRoutine() {
        this.isSelectFilters = false;
        this.isSelectFiltersProjectCode = false;
        let routine: RoutineModel = new RoutineModel();
        if (undefined != this.projectCode && this.projectCode) {
            this.isSelectFilters = true;
            routine.projectCode = this.projectCode;
        } else {
            if (!this.isAdmin) {
                this.isSelectFiltersProjectCode = true;
            }
        }
        if (undefined != this.memberId && this.memberId) {
            this.isSelectFilters = true;
            routine.idUser = this.memberId;
        }
        if (undefined != this.selectMonth && this.selectMonth) {
            this.isSelectFilters = true;
            routine.month = this.selectMonth;
        }
        routine.year = this.selectYear;
        if ((this.isAdmin && this.isSelectFilters) || (!this.isAdmin && !this.isSelectFiltersProjectCode)) {
            this.loading = true;
            if (this.selectType === "All") {
                this.viewTimeSheetService.getListRoutineViewForAdmin(routine , this.selectRole).then(res => {
                    if (res.responseCode == 200) {
                        this.timeSheetList = res.data;
                        this.routineList = res.data;
                        this.routineList.forEach(ele => {
                            this.projectName = ele.projectName;
                            if (((ele.time).toString()).includes(".")) {
                                let mins = ele.time.split(".")[1];
                                if (0 < parseInt(mins.substring(0, 1))) {
                                    ele.time = (parseInt(ele.time) + 1).toString();
                                } else {
                                    ele.time = parseInt(ele.time).toString();
                                }
                            }
                            ele.time = this.calTime(ele.time).toString();
                            ele.detail = ele.detail.replace(/(\\r\\n\\)|([\r\n])/gmi, '<br/>');
                            if (ele.ot) {
                                ele.timesOt = this.calTime(ele.timesOt).toString();
                                ele.monthOrder = moment(ele.date, "DD-MM-YYYY").format('MM');
                            }
                        });
    
                        let listProjectOBJ = _.groupBy(this.routineList, 'date');
                        this.listProject = _.map(listProjectOBJ, function (group) {
                            return {
                                date: group[0].date,
                                details: group,
                                position: group[0].position,
                                fName: group[0].fName,
                                img: group[0].img ? group[0].img : null,
                                month: group[0].month,
                                c: moment(group[0].date, 'DD-MM-YYYY').format('ddd'),
                                monthOrder: group[0].monthOrder
                            }
                        });
                        let listGroupMonth = _.groupBy(this.listProject, 'month');
                        this.listProject = _.map(listGroupMonth, function (detial) {
    
                            return {
                                month: detial[0].month,
                                details: _.sortBy(detial, 'date').reverse(),//detial.reverse(),//.sort(Sort_Data.predicateByDes("date")),
                                date: detial[0].date,
                                c: moment(detial[0].date, 'DD-MM-YYYY').format('ddd'),
                                monthOrder: detial[0].monthOrder
                            }
                        });
                        this.listProject = _.sortBy(this.listProject, "monthOrder");
                        this.listProject.reverse();
    
                        // console.log(this.listProject);
                        // if (this.addVacationTime) {
                        //     this.addVacationTime();
                        // }
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
    
                })
            } else {
                if (this.selectType == "OT") {
                    routine.ot = true
                } else {
                    routine.ot = false
                }
                this.viewTimeSheetService.getRoutineByTypeSheet(routine, this.selectRole)
                    .then(res => {
                        if (res.responseCode == 200 && res.data) {
                            this.timeSheetList = res.data;
                            this.routineList = res.data;
                            this.routineList.forEach(ele => {
                                this.projectName = ele.projectName;
                                if (((ele.time).toString()).includes(".")) {
                                    let mins = ele.time.split(".")[1];
                                    if (0 < parseInt(mins.substring(0, 1))) {
                                        ele.time = (parseInt(ele.time) + 1).toString();
                                    } else {
                                        ele.time = parseInt(ele.time).toString();
                                    }
                                }
                                ele.time = this.calTime(ele.time).toString();
                                ele.detail = ele.detail.replace(/(\\r\\n\\)|([\r\n])/gmi, '<br/>');
                                if (ele.ot) {
                                    ele.timesOt = this.calTime(ele.timesOt).toString();
                                    ele.monthOrder = moment(ele.date, "DD-MM-YYYY").format('MM');
                                }
                            });
        
                            let listProjectOBJ = _.groupBy(this.routineList, 'date');
                            this.listProject = _.map(listProjectOBJ, function (group) {
                                return {
                                    date: group[0].date,
                                    details: group,
                                    position: group[0].position,
                                    fName: group[0].fName,
                                    img: group[0].img ? group[0].img : null,
                                    month: group[0].month,
                                    c: moment(group[0].date, 'DD-MM-YYYY').format('ddd'),
                                    monthOrder: group[0].monthOrder
                                }
                            });
                            let listGroupMonth = _.groupBy(this.listProject, 'month');
                            this.listProject = _.map(listGroupMonth, function (detial) {
        
                                return {
                                    month: detial[0].month,
                                    details: _.sortBy(detial, 'date').reverse(),//detial.reverse(),//.sort(Sort_Data.predicateByDes("date")),
                                    date: detial[0].date,
                                    c: moment(detial[0].date, 'DD-MM-YYYY').format('ddd'),
                                    monthOrder: detial[0].monthOrder
                                }
                            });
                            this.listProject = _.sortBy(this.listProject, "monthOrder");
                            this.listProject.reverse();
        
                            // console.log(this.listProject);
                            // if (this.addVacationTime) {
                            //     this.addVacationTime();
                            // }
                            this.loading = false;
                        } else {
                            this.timeSheetList = [];
                            this.routineList = [];
                            this.listProject = [];
                            this.loading = false;
                        }
                    }
                );
            }

        } else {
            if (!this.isAdmin) {
                this.isSelectFiltersProjectCode = true;
            }
            this.listProject = [];
        }
    }

    calTime(mins) {
        let h = Math.floor(mins / 60);
        let m = mins % 60;
        h = h < 10 ? 0 + h : h;
        m = m < 10 ? 0 + m : m;
        return `${h}.${m}`;
    }

    exportAsFile(): void {
        let exportDataList: Array<XlsxExportModel> = new Array<XlsxExportModel>();
        // console.log(this.listProject)
        let dataDetailArray: Array<DetailModel> = new Array<DetailModel>();
        this.listProject.forEach(grpMonth => {
            (grpMonth.details).forEach(grpDay => {
                if (grpDay.details.length <= 0) {
                    let dataDetail: DetailModel = new DetailModel();

                    dataDetail.date = moment(grpDay.date, 'DD-MM-YYYY').format('DD/MM/YYYY');
                    dataDetail.start = '-';
                    dataDetail.end = '-';
                    dataDetail.total = '0';
                    // let detail = (data.detail).split('\n');
                    let detail = '-';
                    dataDetail.detailRow = 0
                    dataDetail.detail = detail[0];
                    // let exportData: XlsxExportModel = new XlsxExportModel();
                    let monthDetail: MonthDetailModel = new MonthDetailModel();
                    monthDetail.data.push(dataDetail)
                    ////************** */
                    // console.log(dataDetail)
                    // exportDataList.forEach(exportNameData => {
                    //     exportNameData.data.forEach(ele => {
                    //         ele.data.push(dataDetail)
                    //     })
                    // });

                    // let monthDetail: MonthDetailModel = new MonthDetailModel();
                    // dataDetailArray.push(dataDetail)
                    // monthDetail.push(dataDetail);
                    // exportData.name = 'gg'
                    // exportData.lastName = 'gg'
                    // exportData.year = '2020'
                    // exportData.data = new Array();
                    // monthDetail.month = '-'
                    // monthDetail.orderBy = 1
                    // monthDetail.data = new Array();
                    // monthDetail.sumTotal += parseFloat(dataDetail.total);
                    // monthDetail.data.push(dataDetail);
                    // exportData.data.push(monthDetail);
                    // exportData.data = _.sortBy(exportData.data, 'orderBy');
                    // exportDataList.push(exportData);

                    // let a;
                    // let exportData: XlsxExportModel = new XlsxExportModel();
                    // let dataDetail: DetailModel = new DetailModel();
                    // // console.log(data)
                    // let monthDetail: MonthDetailModel = new MonthDetailModel();
                    // dataDetail.date = moment(grpDay.date, 'DD-MM-YYYY').format('DD/MM/YYYY');
                    // dataDetail.start = '-'//data.startTime;
                    // dataDetail.end = '-'//data.endTime;
                    // dataDetail.total = '0'//data.time;
                    // // let detail = (data.detail).split('\n');
                    // let detail = '-'//(data.detail).split('<br/>');
                    // dataDetail.detailRow = 0//detail.length;
                    // dataDetail.detail = detail[0]//detail[0];
                    // // dataDetail.orderBy = parseInt(dataDetail.date.substring(0, 2));
                    // // let orderMonth = parseInt(dataDetail.date.substring(3, 5));
                    // //    exportData.name = data.fName;
                    // //     exportData.lastName = data.lName;
                    // for (let i = 1; i < detail.length; i++) {
                    //     dataDetail.detail += "\r\n" + detail[i];
                    // }
                    // // exportData.
                    // // var monthObj = { data: [] };
                    // exportData.name = 'temp'
                    // exportData.lastName = 'temp'
                    // exportData.year = 'temp'
                    // exportData.data = new Array();
                    // // exportData.data
                    // exportData.data.push(grpDay.details);
                    // exportDataList.push(exportData);

                } else {
                    (grpDay.details).forEach(data => {
                        let dataDetail: DetailModel = new DetailModel();
                        dataDetail.date = moment(data.date, 'DD-MM-YYYY').format('DD/MM/YYYY');
                        dataDetail.projectName = this.projectName;
                        dataDetail.feature = data.funcName;
                        dataDetail.start = data.startTime;
                        dataDetail.end = data.endTime;
                        dataDetail.total = data.time;
                        
                        dataDetail.ot = data.ot == "true" ? "OT" : "";
                        dataDetail.specialCase = data.specialCaseCode;

                        
                        // let detail = (data.detail).split('\n');
                        let detail = (data.detail).split('<br/>');
                        dataDetail.detailRow = detail.length;
                        dataDetail.detail = detail[0];
                        dataDetail.orderBy = parseInt(dataDetail.date.substring(0, 2));
                        let orderMonth = parseInt(dataDetail.date.substring(3, 5));
                        for (let i = 1; i < detail.length; i++) {
                            dataDetail.detail += "\r\n" + detail[i];
                        }
                        let nameObj = _.findWhere(exportDataList, { name: data.fName }) || "";
                        if (undefined != nameObj && nameObj) {
                            let monthObj = _.findWhere(nameObj.data, { month: data.month }) || "";
                            if (undefined != monthObj && monthObj) {
                                monthObj.data.push(dataDetail);
                                monthObj.data = _.sortBy(monthObj.data, 'orderBy')
                                monthObj.sumTotal += parseFloat(dataDetail.total);
                            } else {
                                let monthDetail: MonthDetailModel = new MonthDetailModel();
                                monthDetail.month = data.month;
                                monthDetail.orderBy = orderMonth;
                                monthDetail.data = new Array();
                                monthDetail.data.push(dataDetail);
                                monthDetail.sumTotal += parseFloat(dataDetail.total);
                                nameObj.data.push(monthDetail);
                                nameObj.data = _.sortBy(nameObj.data, 'orderBy');
                            }
                        } else {
                            let exportData: XlsxExportModel = new XlsxExportModel();
                            let monthDetail: MonthDetailModel = new MonthDetailModel();
                            exportData.name = data.fName;
                            exportData.lastName = data.lName;
                            exportData.year = (dataDetail.date).split("/")[2];
                            exportData.data = new Array();
                            monthDetail.month = data.month;
                            monthDetail.orderBy = orderMonth;
                            monthDetail.data = new Array();
                            monthDetail.sumTotal += parseFloat(dataDetail.total);
                            monthDetail.data.push(dataDetail);
                            exportData.data.push(monthDetail);
                            exportData.data = _.sortBy(exportData.data, 'orderBy');
                            exportDataList.push(exportData);
                        }
                    });
                }
            });
        });

        let zip : JSZip = new JSZip()
        console.log("exportDataList.length => ",exportDataList.length)
        exportDataList.forEach(async exportNameData => {
            let sheetName = this.projectName + "_" + exportNameData.name + "_" + exportNameData.lastName.substring(0, 1) + "_" + this.monthCode
            if (!this.isFeature && this.typeFile === "PDF") {
                // this.excelService.exportAsXLSXFile(exportNameData, this.projectName, sheetName);
                this.pdfService.exportPDF(exportNameData,this.projectName,sheetName);
            }else if(this.isFeature && !this.exportForPS && this.typeFile === "XLSX" ){
                this.excelFunctionService.exportAsXLSXFile(exportNameData, this.projectName, sheetName);
            }else if(this.isFeature && this.exportForPS && this.typeFile === "XLSX"){
                
                await this.excelFunctionService.exportAsXLSXFileForPS(exportNameData, this.projectName, sheetName , zip)
            }
        });

        let _this = this
        if(this.isFeature && this.exportForPS && this.typeFile === "XLSX"){
            this.exportForPSBusy = true
            setTimeout(() => {
                zip.generateAsync({type:"blob"}).then(function(content) {
                    FileSaver.saveAs(content,`PS_${_this.selectMonth}_${_this.selectYear}`); 
                    _this.exportForPSBusy = false
                });
            },5000)
        }
        
    }

    checkValue(event: any) {
        if (event) {
            this.addVacationTimeCheck = true;
        } else {
            this.addVacationTimeCheck = false;
        }
    }

    addVacationTime() {
        // console.log(this.listProject)
        if (this.addVacationTimeCheck) {
            this.listProject.forEach(ele => {
                let monthTemp = moment().day(1).month(ele.month).year(parseInt(this.selectYear));
                let startOfMonth = moment(monthTemp, 'YYYY-MMM-DD').startOf('month');
                let startDate = moment(startOfMonth).endOf('month').format('DD');
                let temp = [];
                for (let i = parseInt(startDate); i >= 1; i--) {
                    let day = moment().day(i).month(ele.month).year(parseInt(this.selectYear)).format('DD-MM-YYYY')
                    let isHoliday = moment(day, 'DD-MM-YYYY').format('ddd')
                    let a = _.findWhere(ele.details, { date: day });
                    if (a != undefined) {
                        if (isHoliday == 'Sun' || isHoliday == 'Sat') {
                            a.holiday = true;
                        }
                        temp.push(a)
                    } else {
                        let vacation = {
                            date: day,
                            details: [],
                            c: moment(day, 'DD-MM-YYYY').format('ddd'),
                            holiday: (isHoliday == 'Sun' || isHoliday == 'Sat') ? true : false
                        }
                        temp.push(vacation)
                    }
                }
                temp.reverse();
                ele.details = temp
                ele.details = _.sortBy(temp, 'date').reverse()
            });
            console.log(this.listProject);
        }
    }

    editMyProject(){
        this.router.navigate(['/edit-myproject']);
    }
}
