import { Component, OnInit } from '@angular/core';
import { LovModel } from '../model/lov';
import { ProjectFunctionModel } from '../model/projectFunction';
import { CreateNewFunctionsService } from '../create-new-functions/create-new-functions.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { EditFunctionService } from './edit-function.service';

@Component({
  selector: 'app-edit-function',
  templateUrl: './edit-function.component.html',
  styleUrls: ['./edit-function.component.css']
})
export class EditFunctionComponent implements OnInit {
  public projectList: Array<LovModel> = new Array<LovModel>();
  public loading: boolean = false;
  public func = new ProjectFunctionModel();
  public modal = {
    title: "",
    msg: "",
    msg2: "",
    confirm: "",
    cancel: ""
  }
  public functionId: string = '';
  constructor(
    private editFunctionService: EditFunctionService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.functionId = params.id;
    });
    // this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
    this.getFunction(this.functionId);
    this.getProjectName();
  }

  getProjectName() {
    this.loading = true;

    let lov = new LovModel();
    lov.lovType_key2 = "PROJECT_NAME";
    lov.activeFlg_key1 = "Y";
    this.editFunctionService.getLov(lov).then(res => {
      if (res.responseCode == 200) {
        this.projectList = res.data;
      }
    });
    this.loading = false;
  }

  getFunction(functionId) {
    let func: ProjectFunctionModel = new ProjectFunctionModel();
    func._id = functionId;
    this.editFunctionService.searchFunction(func).then(res => {
      if (res.responseCode == 200) {
        this.func = res.data[0];
      }
    });
  }

  putFunction(func) {
    this.editFunctionService.putFunction(func).then(res => {
      if (res.responseCode == 200) {
        this.router.navigate(['/home-user']);
      }
    });
  }

  cancel(content) {
    this.router.navigate(['/home-user']);
  }


}
