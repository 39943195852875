import { environment } from './../../environments/environment';

const PREFIX = environment.API_PREFIX;

export const API = {

    TS_LOGIN: PREFIX + 'api/getUser',
    GET_LOV: PREFIX + 'api/getLovCriteria',
    POST_ROUTINE: PREFIX + 'api/postRoutine',
    GET_ROUTINE: PREFIX + 'api/getRoutineCriteria',
    GET_ROUTINE_ORIGIN: PREFIX + 'api/getRoutineOrigin',
    GET_EMPLOYEE: PREFIX + 'api/getEmployee',
    GET_EMPLOYEE_ALL: PREFIX + 'api/getEmployee-all',
    GET_EMPLOYEE_PROJECT: PREFIX + 'api/getEmployee-project-list',
    DEL_ROUTINE_ORIGIN: PREFIX + 'api/delRoutineOrigin',
    GET_ROUTINE_VIEW: PREFIX + 'api/getRoutine-view',
    PUT_ROUTINE: PREFIX + 'api/putRoutine',
    POST_LOV: PREFIX + 'api/postLov',
    PUT_EMPLOYEE: PREFIX + 'api/putEmployee',
    POST_EMPLOYEE: PREFIX + 'api/postEmployee',
    PUT_LOV: PREFIX + 'api/putLov',
    POST_PIC: PREFIX + 'api/upload-pic',
    GET_PIC: PREFIX + 'api/get-upload-pic',
    GET_DISTINCT_CODE: PREFIX + 'api/distinct-project-code',
    GET_DISTINCT_USER: PREFIX + 'api/distinct-user-id',
    GET_USER_ID: PREFIX + 'api/max-user-id',
    GET_CONFIG: PREFIX + 'api/getConfigCriteria',
    GET_PROJECT_FUNCTION: PREFIX + 'api/function-project',
    GET_MAX_FUNCTION: PREFIX + 'api/max-function-id',
    POST_FUNCTION: PREFIX + 'api/postfunction',
    PUT_FUNCTION: PREFIX + 'api/putfunction',
    DEL_FUNCTION: PREFIX + 'api/delfunction',

    GET_AUTHFIELD: '',
    GET_RESOURCE: '',
    GET_USERSDETAIL: '',

    GET_CHECK_IN: PREFIX + 'api/check-in',
    POST_CHECK_IN: PREFIX + 'api/check-in',

    GET_PS_ROUTINE: PREFIX + 'api/get-ps-routine',
    POST_PS_ROUTINE: PREFIX + 'api/postPsRoutine',
    GET_PS_ROUTINE_ORIGIN: PREFIX + 'api/getPsRoutineOrigin',
    PUT_PS_ROUTINE: PREFIX + 'api/putPsRoutine',
    DEL_PS_ROUTINE_ORIGIN: PREFIX + 'api/delPsRoutineOrigin',
    POST_LEAVE_WORK: PREFIX + 'api/postLeave',
    GET_LEAVE_WORK: PREFIX + 'api/getLeave',
    DEL_PS_LEAVE : PREFIX + 'api/delLeave',
    GET_LEAVE_WORK_REPORT: PREFIX + 'api/getLeave-report',
    GET_PS_ROUTINE_CRITERIA: PREFIX + 'api/find-ps-routine',
    
    
};
export const TOKEN = { 
    LOGIN : 'U2FsdGVkX19HSn/7Wo4gAsy/5VJOnrJgrEY47mrVmdw=U2FsdGVkX182SztA0tx6'
            +'PCS7UCilH+21y8WfH5jIrlU=U2FsdGVkX18x/O5aWlxrGHTQw7Lkh1N7b/r6M12N'
}

