import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class RoutineModel {
    public _id: any;
    public idRoutine: string = "";
    public projectCode: string = "";
    public idUser: string = "";
    public date: string = "";
    public startTime: string = "";
    public endTime: string = "";
    public detail: string = "";
    public ot: boolean = false;
    public otDetail: Array<RoutineOTModel> = new Array();;
    public month: string = "";
    public time: string = "";
    public timesOt: string = "";
    public monthOrder: string = "";
    public year: string = "";

    public createBy: string;
    public createDate: Date;

    public c: string = "";
    public selectDate: NgbDateStruct;
    public projectName: string;
    public name: string;
    public monthArray: Array<String>;
    public img: any;
    public functionCode: string = "";
    public specialCaseCode: string = "";
    public checkinId : string

    public funcList: Array<Object>;
    public disabledFieldFunc: boolean = true;
    public functionAll: any;
    public funcName: any;

}

export class RoutineOTModel {
    public startTimeOt: string = "";
    public endTimeOt: string = "";
    public detailOt: string = "";
}

export class UpdateRoutineModel {
    public _id: any;
    public date: string;
    public startTime: string;
    public endTime: string;
    public detail: string;
    public month: string;
    public time: string;
    public updateBy: string;
    public updateDate: Date;
    public functionCode: string;
}
