import { Injectable } from '@angular/core';
import { LovService } from '../service/lov.service';
import { LovModel } from '../model/lov';

@Injectable()
export class ProjectService {

    constructor(
        private lovService: LovService,
    ) { }

    getLov(lov: LovModel) {
      return this.lovService.getLov(lov);
    }
}
