import { LovModel } from './../model/lov';
import { Component, OnInit } from '@angular/core';
import { CreateNewFunctionsService } from './create-new-functions.service';
import { ProjectFunctionModel } from '../model/projectFunction';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { EmployeeModel } from '../model/employee.model';
import { UserModel } from '../model/users.model';

@Component({
  selector: 'app-create-new-functions',
  templateUrl: './create-new-functions.component.html',
  styleUrls: ['./create-new-functions.component.css']
})
export class CreateNewFunctionsComponent implements OnInit {
  public projectList: Array<LovModel> = new Array<LovModel>();
  public loading: boolean = false;
  public func = new ProjectFunctionModel();
  public userLogin: UserModel = new UserModel();
  public personalProjectList: Array<LovModel> = new Array();
  public modal = {
    title: "",
    msg: "",
    msg2: "",
    confirm: "",
    cancel: ""
  }
  constructor(
    private createNewFunctionsService: CreateNewFunctionsService,
    private modalService: NgbModal,
    private router: Router,
  ) { }

  ngOnInit() {
    this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
    this.getListProject()
    // this.getProjectName();
  }

  async getListProject() {
    let emp = new EmployeeModel()
    emp.idUser = this.userLogin.idUser;
    this.createNewFunctionsService.getEmployeeProjectList(emp).then(res => {
      if (res.responseCode == 200) {
        this.personalProjectList = res.data;
      }
    });
  }

  getProjectName() {
    this.loading = true;

    let lov = new LovModel();
    lov.lovType_key2 = "PROJECT_NAME";
    lov.activeFlg_key1 = "Y";
    this.createNewFunctionsService.getLov(lov).then(res => {
      if (res.responseCode == 200) {
        this.projectList = res.data;
      }
    });
    this.loading = false;
  }

  async changeProject(projectCode) {
    return await this.createNewFunctionsService.getMaxFunctionId(projectCode).then(res => {
      // if (res.responseCode == 200) {
      console.log(res)
      let maxFunctionId = (res.functionCode);
      console.log(maxFunctionId)
      this.func.functionCode = maxFunctionId;
      return maxFunctionId
      // }
    })
  }

  createFunction(func) {
    this.createNewFunctionsService.createFunction(func).then(res => {
      console.log(res)
      if (res.responseCode == 200) {
        if (this.userLogin.role.toUpperCase() == "PROFESSIONAL SERVICE") {
          this.router.navigate(['/home-ps']);
        } else {
          this.router.navigate(['/home-user']);
        }
      }
    });
  }

  cancel(content) {
    // if (this.isChange) {
    this.modal.title = "Confirm Navigation";
    this.modal.msg = "You have unsave changes. If you leave this page, your changes will be lost.";
    this.modal.msg2 = "Are You sure you want to leave this page?";
    this.modal.confirm = "Leave this page"
    this.modal.cancel = "Stay on this page"
    this.modalService.open(content, { backdrop: 'static' }).result.then((result) => {
      if (result == 'Y') {
        if (this.userLogin.role.toUpperCase() == "PROFESSIONAL SERVICE") {
          this.router.navigate(['/home-ps']);
        } else {
          this.router.navigate(['/home-user']);
        }
      }
    }, (reason) => { });
    // } else {
    //   this.router.navigate(['/home']);
    // }
  }

}
