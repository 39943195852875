import { XlsxExportModel, MonthDetailModel, XlsxExportLeaveModel } from './../model/excel.model';
import { EmployeeService } from './../service/employee.service';
import { UserModel } from './../model/users.model';
import { PsLeaveModel } from './../model/psLeave.model';
import { Component, OnInit } from '@angular/core';
import { ConfigModel } from '../model/config.model';
import { ConfigService } from '../service/config.service';
import { Router } from '../../../node_modules/@angular/router';
import { LeaveWorkService } from '../service/leave-work.service';
import * as _ from "underscore";
import { ExcelServiceFunction } from '../service/excel-with-function.service';

@Component({
  selector: 'app-configulation',
  templateUrl: './configulation.component.html',
  styleUrls: ['./configulation.component.css']
})
export class ConfigulationComponent implements OnInit {

  public loading: boolean = false;
  public psArray: Array<PsLeaveModel> = new Array<PsLeaveModel>();
  public memberList: Array<UserModel> = new Array<UserModel>();
  private memberId: string;
  constructor(
    private configService: ConfigService,
    private psLeaveService: LeaveWorkService,
    private employeeService: EmployeeService,
    private router: Router,
    private excelFunctionService: ExcelServiceFunction,
  ) { }

  ngOnInit() {
    // this.getConfig();
    this.getMemberList('');
  }

  getMemberList(role) {
    var user = new UserModel();
    user.active = 'Y'
    user.role = 'Professional Service'
    this.employeeService.getEmployeeByCriteria(user).then(res => {
      if (res.responseCode == 200) {
        this.memberList = res.data;
      }
    })
  }

  searchPsLeave() {
    let leave = new PsLeaveModel();
    leave.idUser = this.memberId;
    this.psLeaveService.getPsLeaveListReport(leave).then(res => {
      if (res.responseCode == 200) {
        this.psArray = _.sortBy(res.data, 'fName').reverse()// res.data;
      }
    })
  }

  newConfig() {
    this.router.navigate(['/new-config']);
  }

  editConfig() {
    this.router.navigate(['/edit-config']);
  }

  async exportAsFile(): Promise<void> {
    let exportDataList: Array<XlsxExportLeaveModel> = new Array<XlsxExportLeaveModel>();
    this.psArray.forEach(grpMonth => {
      let exportData: XlsxExportLeaveModel = new XlsxExportLeaveModel();
      exportData.idUser = grpMonth.idUser;
      exportData.name = grpMonth.fName + ' ' +  grpMonth.lName;
      exportData.startTime = grpMonth.startTime;
      exportData.startDate = grpMonth.startDate;
      exportData.endTime = grpMonth.endTime;
      exportData.endDate = grpMonth.endDate;
      exportData.typeLeaveName = grpMonth.typeLeaveName;
      exportDataList.push(exportData);
    });

    console.log("exportDataList.length => ", exportDataList.length)
    await this.excelFunctionService.exportAsXLSXFileLeave(exportDataList, 'LEAVE', 'LEAVE')

  }

}
