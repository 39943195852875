import { Injectable } from "@angular/core";

import { XlsxExportModel } from "../model/excel.model";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "../../assets/pdfmake/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: "root",
})
export class PdfService {
  constructor() {}

  exportPDF(json: XlsxExportModel, projectCode: string, sheetName: string) {
    json.data.forEach((monthData) => {
      pdfMake.fonts = {
        THSarabunNew: {
          normal: "THSarabunNew.ttf",
          bold: "THSarabunNew Bold.ttf",
          italics: "THSarabunNew Italic.ttf",
          bolditalics: "THSarabunNew BoldItalic.ttf",
        },
        Roboto: {
          normal: "Roboto-Regular.ttf",
          bold: "Roboto-Medium.ttf",
          italics: "Roboto-Italic.ttf",
          bolditalics: "Roboto-MediumItalic.ttf",
        },
      };

      var dd = {
        content: [
          {
            table: {
              widths: ["auto", "*"],
              body: [
                [
                  {
                    text: "PROJECT : ",
                    bold: true,
                    border: [false, false, false, false],
                  },
                  { text: projectCode, border: [false, false, false, true] },
                ],
                [
                  {
                    text: "Operator : ",
                    bold: true,
                    border: [false, false, false, false],
                  },
                  {
                    text: json.name + " " + json.lastName,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: "Month :",
                    bold: true,
                    border: [false, false, false, false],
                  },
                  {
                    text: monthData.month + " " + json.year,
                    border: [false, false, false, true],
                  },
                ],
              ],
            },
          },
          {
            text: "\n",
          },
          {
            style: "tableExample",
            table: {
              widths: ["auto", "*", "auto", "auto", "auto"],
              headerRows: 1,
              body: [
                [
                  {
                    text: "TIME SHEET",
                    colSpan: 5,
                    alignment: "center",
                    bold: true,
                    fillColor: "#ccc",
                  },
                  {},
                  {},
                  {},
                  {},
                ],
                [
                  { text: "DATE", style: "tableHeader" },
                  { text: "JOB DETAIL", style: "tableHeader" },
                  { text: "START", style: "tableHeader" },
                  { text: "END", style: "tableHeader" },
                  { text: "TOTAL", style: "tableHeader" },
                ],

                // Data here ..
                ...monthData.data.map((item) => {
                  return [
                    { text: item.date },
                    { text: item.detail },
                    { text: item.start, alignment: "center" },
                    { text: item.end, alignment: "center" },
                    { text: item.total, alignment: "center" },
                  ];
                }),

                [
                  {
                    text: "TOTAL TIME",
                    colSpan: 4,
                    alignment: "center",
                    bold: true,
                    fillColor: "#ccc",
                  },
                  {},
                  {},
                  {},
                  {
                    text: monthData.sumTotal.toFixed(2),
                    alignment: "center",
                    bold: true,
                  },
                ],
                [
                  {
                    text: "TOTAL DAY",
                    colSpan: 4,
                    alignment: "center",
                    bold: true,
                    fillColor: "#ccc",
                  },
                  {},
                  {},
                  {},
                  {
                    text: (monthData.sumTotal / 8).toFixed(2),
                    alignment: "center",
                    bold: true,
                  },
                ],
              ],
            },
          },

          {
            columns: [
              { width: "*", text: "" },
              {
                width: "auto",

                table: {
                  headerRows: 1,
                  body: [
                    ["_________________________________"],
                    [
                      {
                        text: "(  SUPERVISOR SIGNATURE  )",
                        bold: true,
                        alignment: "center",
                      },
                    ],
                    ["\n"],
                    ["\n"],
                    ["_________________________________"],
                    [{ text: "DATE", bold: true, alignment: "center" }],
                  ],
                },
                layout: "noBorders",
              },
            ],
          },
        ],
        styles: {
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: "black",
            alignment: "center",
          },
          tableExample: {
            margin: [0, 0, 0, 50],
          },
        },
        defaultStyle: {
          font: "THSarabunNew",
        },
      };

      pdfMake.createPdf(dd).download(`${sheetName}.pdf`);
    });
  }
}
