export class LovModel {
  public _id: string;
  public activeFlg_key1: string;
  public createdBy_data: string;
  public created_data: string;
  public lastUpdBy_data: string;
  public lastUpd_data: string;
  public lovCode_key0: string;
  public lovId_key3: string;
  public lovName_data: string;
  public lovType_key2: string;
  public lovVal01_data: string;
  public lovVal02_data: string;
  public lovVal11_data: string;
  public lovVal12_data: string;
  public lovVal21_data: string;
  public lovVal22_data:  string;
  public lovVal23_data: string;
  public lovVal24_data: string;
  public lovVal25_data: string;
  public lovVal26_data: string;
  public lovVal31_data: string;
  public lovVal32_data: string;
  public lovVal41_data: string;
  public lovVal42_data: string;
  public lovVal51_data: string;
  public lovVal52_data: string;
  public lovVal61_data: string;
  public lovVal62_data: string;
  public lovVal71_data: string;
  public orderBy_data: string;
  public textDesc_data: string;
  public lovCode_key2: string;
}
