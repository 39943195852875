import { EmployeeModel } from './../model/employee.model';
import { Injectable } from '@angular/core';
import { LovService } from '../service/lov.service';
import { LovModel } from '../model/lov';
import { ProjectFunctionService } from '../service/project-function.service';
import { ProjectFunctionModel } from '../model/projectFunction';
import { EmployeeService } from '../service/employee.service';

@Injectable({
  providedIn: 'root'
})
export class CreateTimeSheetFuncService {

  constructor(
    private lovService: LovService,
    private projectFunctionService : ProjectFunctionService,
    private employeeService : EmployeeService
  ) { }

  getLov(lov: LovModel) {
    return this.lovService.getLov(lov);
  }

  searchFunction(func: ProjectFunctionModel) {
    return this.projectFunctionService.searchFunction(func);
  }

  deleteFunction(id){
    return this.projectFunctionService.deleteFunction(id)
  }

  getEmployeeProjectList(emp: EmployeeModel) {
    return this.employeeService.getEmployeeProjectList(emp);
  }

  
}
