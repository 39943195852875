import { EmployeeModel } from './../model/employee.model';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { LovService } from '../service/lov.service';
import { LovModel } from '../model/lov';
import { UserModel } from '../model/users.model';
import * as _ from 'underscore'
import { EmployeeService } from '../service/employee.service';
@Component({
  selector: 'app-ps-project-list',
  templateUrl: './ps-project-list.component.html',
  styleUrls: ['./ps-project-list.component.css']
})
export class PsProjectListComponent implements OnInit {

  public modal = {
    title: "",
    msg: "",
    msg2: "",
    confirm: "",
    cancel: ""
  }
  public dynamicArray: Array<any> = [];
  public newDynamic: any = {};
  public projectList: Array<LovModel> = new Array();
  public userLogin: UserModel = new UserModel();
  public modeEditSkill: boolean = false;
  public successSkill: boolean = false;
  public loading: boolean = false;
  public personalProjectList: Array<EmployeeModel> = new Array();
  public personal = new EmployeeModel();
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private lovService: LovService,
    private employeeService: EmployeeService
  ) { }

  ngOnInit() {
    this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
    this.getProjectName();
    this.getViewProfile()
  }

  cancel(content) {
    // if (this.isChange) {
    // this.modal.title = "Confirm Navigation";
    // this.modal.msg = "You have unsave changes. If you leave this page, your changes will be lost.";
    // this.modal.msg2 = "Are You sure you want to leave this page?";
    // this.modal.confirm = "Leave this page"
    // this.modal.cancel = "Stay on this page"
    // this.modalService.open(content, { backdrop: 'static' }).result.then((result) => {
    //   if (result == 'Y') {
    this.router.navigate(['/home-user']);
    //   }
    // }, (reason) => { });
    // } else {
    //     this.router.navigate(['/home-user']);
    // }
  }

  addRow(index) {
    this.newDynamic = { name: "", value: "" };
    this.dynamicArray.push(this.newDynamic);
    // console.log(this.dynamicArray);
    return true;
  }

  deleteRow(index) {
    if (this.dynamicArray.length == 1) {
      this.dynamicArray.splice(index, 1);
      return true;
    } else {
      this.dynamicArray.splice(index, 1);
      // console.log(this.dynamicArray);
      return true;
    }
  }

  getProjectName() {
    let lov = new LovModel();
    lov.lovType_key2 = "PROJECT_NAME";
    lov.activeFlg_key1 = "Y";
    this.lovService.getLov(lov).then(res => {
      if (res.responseCode == 200) {
        this.projectList = res.data;
      }
    })
  }

  async saveList() {
    this.successSkill = false;
    this.loading = true;
    let emp = new EmployeeModel();
    emp._id = this.userLogin._id;
    emp.projectList = _.reject(this.dynamicArray, function (num) { return num.name == ''; });
    this.employeeService.putEmployee(emp).then(async res => {
      if (res.responseCode == 200) {
        await this.getViewProfile()
        this.loading = false;
      }
      this.successSkill = true;
      this.modeEditSkill = false;
      setTimeout(() => {
        this.successSkill = false;
      }, 1000);

    });
  }


  async getViewProfile() {
    let empModel = new EmployeeModel()
    empModel.idUser = this.userLogin.idUser;
    this.employeeService.getEmployeeProjectList(empModel).then(res => {
      if (res.responseCode == 200) {
        this.personalProjectList = res.data;
      }
    });
  }

  getEditProfile() {
    let userModel = new UserModel()
    userModel.idUser = this.userLogin.idUser;
    this.employeeService.getEmployeeByCriteria(userModel).then(res => {
      if (res.responseCode == 200) {
        this.personal = res.data[0];
        this.dynamicArray = this.personal.projectList
      }
    });
  }

  editProject() {
    this.modeEditSkill = true;
    this.getEditProfile();
  }

}
