import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { UserModel } from '../model/users.model';
import { API } from '../shared/url-constant';

@Injectable()
export class LoginService {

    constructor(
        public http: HttpClient
    ) { }

    getUserLogin(user: UserModel) {
        let httpParams = new HttpParams()
            .set('username', user.username.toUpperCase());
        const requestOptions = {
            params: httpParams
        };
        return this.http.get<any>(API.TS_LOGIN, requestOptions);
    }

}
