import { LovService } from './../service/lov.service';
import { CreateNewProjectService } from './create-new-project.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbTimeStruct, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FieldRequiredTSModel, FieldRequireCreateTSModel } from '../model/fieldRequired';
import { LovModel } from '../model/lov';
import * as moment from 'moment'
import { UserModel } from '../model/users.model';

@Component({
  selector: 'app-create-new-project',
  templateUrl: './create-new-project.component.html',
  styleUrls: ['./create-new-project.component.css']
})
export class CreateNewProjectComponent implements OnInit {
  public modal = {
    title: "",
    msg: "",
    msg2: "",
    confirm: "",
    cancel: ""
  }
  public isOkForProjectCode: boolean = false;
  public editCode: boolean = true;
  public isChange: boolean = false;
  public fieldErrList: FieldRequireCreateTSModel = new FieldRequireCreateTSModel();
  public lovProject: LovModel = new LovModel();
  public userLogin: UserModel = new UserModel();
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private createNewProjectService: CreateNewProjectService,
    private LovService: LovService
  ) { }

  ngOnInit() {
    this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
  }

  checkDup(lovCode_key0) {
    let lov = new LovModel();
    lov.lovType_key2 = "PROJECT_NAME";
    lov.activeFlg_key1 = "Y";
    if (lovCode_key0 == null || lovCode_key0 == "") {
      alert("Please insert project code.")
      return;
    } else {
      lov.lovCode_key0 = lovCode_key0.toUpperCase();
      return this.createNewProjectService.checkDup(lov).then(res => {
        if (res.responseCode == 200) {
          if (res.data.length > 0) {
            this.isOkForProjectCode = false;
            this.fieldErrList.projectCode = true;
            alert('Is duplicate!!!')
            return false
          }
        } else {
          this.isChange = true;
          this.isOkForProjectCode = true;
          alert("It's ok.")
          return true
        }
      });
    }

  }

  cancel(content) {

    if (this.isChange) {
      this.modal.title = "Confirm Navigation";
      this.modal.msg = "You have unsave changes. If you leave this page, your changes will be lost.";
      this.modal.msg2 = "Are You sure you want to leave this page?";
      this.modal.confirm = "Leave this page"
      this.modal.cancel = "Stay on this page"
      this.modalService.open(content, { backdrop: 'static' }).result.then((result) => {
        if (result == 'Y') {
          this.router.navigate(['/home']);
        }
      }, (reason) => { });
    } else {
      this.router.navigate(['/home']);
    }
  }

  async createProject(lovProject: LovModel, content) {
    if(! await this.checkDup(lovProject.lovCode_key0)) return;

    var checkErr = true;
    if (lovProject.lovName_data == "" || lovProject.lovName_data == null) {
      checkErr = false;
      this.fieldErrList.projectName = true;
    }
    if (lovProject.lovCode_key0 == "" || lovProject.lovCode_key0 == null) {
      checkErr = false;
      this.fieldErrList.projectCode = true;
    }
    if (lovProject.textDesc_data == "" || lovProject.textDesc_data == null) {
      checkErr = false;
      this.fieldErrList.projectDest = true;
    }

    if (checkErr) {
      lovProject.activeFlg_key1 = 'Y';
      lovProject.lovCode_key0 = lovProject.lovCode_key0.toUpperCase();
      lovProject.lovType_key2 = 'PROJECT_NAME';
      lovProject.created_data = moment(new Date(), "DD-MM-YYYY HH:mm").format('DD-MM-YYYY HH:mm');
      lovProject.lastUpd_data = moment(new Date(), "DD-MM-YYYY HH:mm").format('DD-MM-YYYY HH:mm');
      lovProject.createdBy_data = this.userLogin.fName
      lovProject.lastUpdBy_data = this.userLogin.fName
      this.LovService.postLov(lovProject).then(res => {
        console.log("Success");
        this.modal.title = "Success";
        this.modal.msg = "Create Project Success.";
        this.modal.confirm = "OK"
        this.modalService.open(content, { size: 'sm', backdrop: 'static' }).result.then((result) => {
          if (result == 'Y') {
            this.router.navigate(['/home']);
          }
        }, (reason) => { });
        // alert('insert success!!');
        // this.router.navigate(['/home']);
      })
    }
  }
}


