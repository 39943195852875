import { UserModel } from './../model/users.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from '../shared/url-constant';
import { Response } from '../model/response';
import { EmployeeModel } from '../model/employee.model';

@Injectable({
    providedIn: 'root'
})
export class EmployeeService {

    constructor(
        public http: HttpClient
    ) { }

    getEmployee(user: UserModel) {
        let httpParams = new HttpParams();
        if (undefined != user.active && user.active) {
            httpParams = httpParams.set('active', user.active);
        }
        return this.http.get<Response>(API.GET_EMPLOYEE).toPromise();
    }

    getEmployeeByCriteria(user: UserModel) {
        let httpParams = new HttpParams();
        if (undefined != user.position && user.position) {
            httpParams = httpParams.set('position', user.position);
        }
        if (undefined != user.username && user.username) {
            httpParams = httpParams.set('username', user.username);
        }
        if (undefined != user.active && user.active) {
            httpParams = httpParams.set('active', user.active);
        }
        if (undefined != user.idUser && user.idUser) {
            httpParams = httpParams.set('idUser', user.idUser);
        }
        if (undefined != user.skill && user.skill) {
            httpParams = httpParams.set('skill', user.skill);
        }
        if (undefined != user.role && user.role) {
            httpParams = httpParams.set('role', user.role);
        }
        const reqOptions = {
            params: httpParams
        };
        return this.http.get<Response>(API.GET_EMPLOYEE_ALL, reqOptions).toPromise();
    }

    putEmployee(emp) {
        return this.http.put<Response>(API.PUT_EMPLOYEE, { message: emp }).toPromise();
    }

    getIdUser() {
        return this.http.get<Response>(API.GET_USER_ID).toPromise();
    }

    postEmployee(emp) {
        return this.http.post<Response>(API.POST_EMPLOYEE, { message: emp }).toPromise();
    }

    getUserLogin(user: UserModel) {
        let httpParams = new HttpParams()
        if (undefined != user.username && user.username) {
            httpParams = httpParams.set('username', user.username.toUpperCase());
        }
        if (undefined != user.active && user.active) {
            httpParams = httpParams.set('active', user.active);
        }
        if (undefined != user.idUser && user.idUser) {
            httpParams = httpParams.set('idUser', user.idUser);
        }
        const requestOptions = {
            params: httpParams
        };
        return this.http.get<any>(API.TS_LOGIN, requestOptions).toPromise();
    }

    getEmployeeProjectList(emp: EmployeeModel) {
        let httpParams = new HttpParams();
        if (undefined != emp.position && emp.position) {
            httpParams = httpParams.set('position', emp.position);
        }
        if (undefined != emp.username && emp.username) {
            httpParams = httpParams.set('username', emp.username);
        }
        if (undefined != emp.active && emp.active) {
            httpParams = httpParams.set('active', emp.active);
        }
        if (undefined != emp.idUser && emp.idUser) {
            httpParams = httpParams.set('idUser', emp.idUser);
        }
        if (undefined != emp.skill && emp.skill) {
            httpParams = httpParams.set('skill', emp.skill);
        }
        if (undefined != emp.role && emp.role) {
            httpParams = httpParams.set('role', emp.role);
        }
        const reqOptions = {
            params: httpParams
        };
        return this.http.get<Response>(API.GET_EMPLOYEE_PROJECT, reqOptions).toPromise();
    }

}
