import { Component, OnInit } from '@angular/core';
import { EmployeeService } from '../service/employee.service';
import { UserModel } from '../model/users.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-member-all',
  templateUrl: './member-all.component.html',
  styleUrls: ['./member-all.component.css']
})
export class MemberAllComponent implements OnInit {
  public memberList: Array<UserModel> = new Array<UserModel>();
  public loading: boolean = false;
  public search = {
    userName: '',
    position: 'A'
  };
  constructor(
    private employeeService: EmployeeService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getMemberList('A')
  }

  getMemberList(position) {
    this.loading = true;
    let user = new UserModel();
    if (position !== 'A') {
      user.position = position
    }
    this.employeeService.getEmployee(user).then(res => {
      if (res.responseCode == 200) {
        this.memberList = res.data;
        this.memberList.forEach(ele => {
          if (ele.img != undefined && ele.img != "") {
          } else {
            ele.img = null
          }
        })
        this.loading = false;
      } else {
        this.loading = false;
      }
    })
  }

  viewProfile(id) {
    this.router.navigate(['/view-profile/' + id]);
  }
}
