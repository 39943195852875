import { Component, OnInit } from '@angular/core';
import { UserModel } from './../model/users.model';
import { Router } from '@angular/router';
import { RepasswordService } from './repassword.service';
import* as CryptoJS from 'crypto-js'; 
// import { TOKEN } from 'src/environments/environment';
import { TOKEN } from '../shared/url-constant';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeModel } from './../model/employee.model';

@Component({
    selector: 'app-repassword',
    templateUrl: './repassword.component.html',
    styleUrls: ['./repassword.component.css']
})
export class RepasswordComponent implements OnInit {
    public userLogin: UserModel = new UserModel();
    public userInfo: UserModel = new UserModel();
    public password: string = "";
    public newPassword: string = "";
    public confPassword: string = "";
    public error = {
        username: false,
        currentPassword: {
            notMatch: false,
            isEmpty: false
        },
        newPassword: false,
        confPassword: {
            notMatch: false,
            isEmpty: false
        },
    };
    public isCorrect = {
        currentPassword: false,
        newPassword: false,
        confPassword: false
    };
    public modal = {
        title: "",
        msg: "",
        msg2: "",
        confirm: "",
        cancel: ""
    }
    
    constructor(
        private router: Router,
        private repasswordService: RepasswordService,
        private modalService: NgbModal,
    ) { }

    ngOnInit() {
        this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
        if (undefined == this.userLogin || !this.userLogin) {
            this.router.navigate(['/login']);
        }else {
            this.getUser(this.userLogin);
        }
    }

    getUser(user) {
        let userData: UserModel = new UserModel();
        userData.username = user.username;
        this.repasswordService.getUserLogin(userData).then(result => {
            if (result.responseCode == 200) {
                this.userInfo = result.data[0];
                let dePass = (CryptoJS.AES.decrypt(this.userInfo.password, TOKEN.LOGIN)).toString(CryptoJS.enc.Utf8);
                this.userInfo.password = dePass;
            }else{
                this.error.username = true;
            }
        });
    }

    checkPassword(password) {
        if (undefined != password && password) {
            this.error.currentPassword.isEmpty = false;
            if (password == this.userInfo.password) {
                this.error.currentPassword.notMatch = false;
                this.isCorrect.currentPassword = true;
            }else {
                this.isCorrect.currentPassword = false;
                this.error.currentPassword.notMatch = true;
            }
        }else {
            this.error.currentPassword.notMatch = false;
            this.error.currentPassword.isEmpty = true;
        }
    }

    checkNewPassword(password) {
        if ((undefined != password && password)
            && password.length > 5) {
                this.error.newPassword = false;
                this.isCorrect.newPassword = true;
                if (undefined != this.confPassword && this.confPassword) {
                    if (this.newPassword == this.confPassword) {
                        this.error.confPassword.notMatch = false;
                        this.isCorrect.confPassword = true;
                    }else {
                        this.error.confPassword.notMatch = true;
                        this.isCorrect.confPassword = false;
                    }
                }
        }else {
            this.isCorrect.newPassword = false;
            this.error.newPassword = true;
        }
    }

    checkConfPassword() {
        this.error.confPassword.isEmpty = false;
        this.error.confPassword.notMatch = false;
        this.isCorrect.confPassword = false;
        if (undefined != this.confPassword && this.confPassword) {
            if (undefined != this.newPassword && this.newPassword) {
                if (this.newPassword == this.confPassword) {
                    this.error.confPassword.notMatch = false;
                    this.isCorrect.confPassword = true;
                }else {
                    this.error.confPassword.notMatch = true;
                    this.isCorrect.confPassword = false;
                }
            }
        }else {
            this.error.confPassword.isEmpty = true;
        }
    }
    

    cancel(content) {
        if (this.confPassword || this.newPassword || this.password) {
            this.modal.title = "Confirm Navigation";
            this.modal.msg = "You have unsave changes. If you leave this page, your changes will be lost.";
            this.modal.msg2 = "Are You sure you want to leave this page?";
            this.modal.confirm = "Leave this page"
            this.modal.cancel = "Stay on this page"
            this.modalService.open(content, { backdrop: 'static' }).result.then((result) => {
                if(result == 'Y'){
                  this.router.navigate(['/home-user']);
                }
            }, (reason) => {});
        }else {
            this.router.navigate(['/home-user']);
        }
    }

    changePassword(contentConf, contentWarn) {
        let canSave = true;
        this.checkPassword(this.password);
        this.checkNewPassword(this.newPassword);
        this.checkConfPassword();
        if (this.error.currentPassword.isEmpty || this.error.currentPassword.notMatch 
            || this.error.newPassword || this.error.confPassword.isEmpty || this.error.confPassword.notMatch) {
                canSave = false;
        }

        if (canSave) {
            this.modal.title = "Confirm Change Password";
            this.modal.msg = "Are you sure you want to change your password?";
            this.modal.msg2 = "";
            this.modal.confirm = "Change Password"
            this.modal.cancel = "Cancel"
            this.modalService.open(contentConf, { backdrop: 'static' }).result.then((result) => {
                if(result == 'Y'){
                    let changePassword = new EmployeeModel();
                    changePassword._id = this.userInfo._id;
                    changePassword.password = CryptoJS.AES.encrypt(this.newPassword, TOKEN.LOGIN).toString();
                    this.repasswordService.changePassword(changePassword).then( result => {
                        if (result.responseCode == 200) {
                            this.modal.title = "Success";
                            this.modal.msg = "Your password has been changed, please sign in again.";
                            this.modal.confirm = "OK"
                            this.modalService.open(contentWarn, { backdrop: 'static' }).result.then((result) => {
                                if(result == 'Y'){
                                    localStorage.removeItem('curentUserLogin');
                                    this.router.navigate(['/login']);
                                }
                            }, (reason) => {});
                        }else {
                            this.modal.title = "Error";
                            this.modal.msg = result.responseMessage;
                            this.modal.confirm = "OK"
                            this.modalService.open(contentWarn, { backdrop: 'static' })
                        }
                    });
                }
            }, (reason) => {});
        }
    }

}
