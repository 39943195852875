import { Component, OnInit } from '@angular/core';
import { RouterExtService } from '../service/routerExtService.service';
import { UserModel } from '../model/users.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-user',
  templateUrl: './home-user.component.html',
  styleUrls: ['./home-user.component.css']
})
export class HomeUserComponent implements OnInit {
  public selectedwallet = 'timeSheet'
  public userLogin: UserModel = new UserModel();
  public previousUrl: string;
  constructor(
    private routerService: RouterExtService,
    private router: Router,
  ) {

  }

  ngOnInit() {
    this.previousUrl = this.routerService.getPreviousUrl();
    console.log(this.previousUrl)
    this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
    if (undefined == this.userLogin || !this.userLogin) {
      this.router.navigate(['/login']);
    } else {
      if (this.userLogin.role.toUpperCase() === "PROFESSIONAL SERVICE") {
        this.router.navigate(['/home-ps'],{queryParams:{previousUrl:this.previousUrl},skipLocationChange: true});
      }
    }
    if (this.previousUrl.startsWith('/new-function')) {
      this.selectedtab('timeSheetfunc')
    }
    if (this.previousUrl.startsWith('/edit-function')) {
      this.selectedtab('timeSheetfunc')
    }
    if (this.previousUrl.startsWith('/view-timesheets-all')) {
      this.selectedtab('timeSheet')
    }
    if (this.previousUrl.startsWith('/view-profile')) {
      this.selectedtab('member')
    }
  }

  selectedtab(tab) {
    this.selectedwallet = tab;
  }

}
