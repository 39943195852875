import { EmployeeModel } from './../model/employee.model';
import { RoutineModel, RoutineOTModel } from './../model/routine.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserModel } from '../model/users.model';
import { LovModel } from '../model/lov';
import { CreateTimeSheetService } from './create-time-sheet.service';
import { NgbModal, NgbTimeStruct, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import * as _ from "underscore";
import * as __ from "lodash";
import { FieldRequiredTSOtModel, FieldRequiredTSModel } from '../model/fieldRequired';
import { ProjectFunctionModel } from '../model/projectFunction';
import { ValidationService } from '../service/validation.service';


@Component({
    selector: 'app-create-time-sheet',
    templateUrl: './create-time-sheet.component.html',
    styleUrls: ['./create-time-sheet.component.css']
})
export class CreateTimeSheetComponent implements OnInit {

    public timeSheetList: Array<RoutineModel> = new Array();
    public timeSheet: RoutineModel = new RoutineModel();
    public userLogin: UserModel = new UserModel();
    public projectList: Array<LovModel> = new Array();
    public specialCaseList: Array<LovModel> = [];
    public fieldErrList: Array<FieldRequiredTSModel> = new Array();
    public projectFunctionArr: Array<ProjectFunctionModel> = new Array();
    public requiredObj: FieldRequiredTSModel = new FieldRequiredTSModel();
    public defaultCode: string = '';
    public isCheck: boolean = false;
    public isSelecting: boolean = false;
    public selectDateMore: NgbDateStruct;
    public modal = {
        title: "",
        msg: "",
        msg2: "",
        confirm: "",
        cancel: ""
    }

    public isChange: boolean = false;
    public selectTime: NgbTimeStruct = { hour: 0, minute: 0, second: 0 }
    public minuteStep = 1;
    public startTime = "09:00";
    public endTime = "18:00";
    public loading: boolean = false;
    public personalProjectList: Array<LovModel> = new Array();
    constructor(
        private router: Router,
        private createTimeSheetService: CreateTimeSheetService,
        private modalService: NgbModal,
        private validationService: ValidationService,
    ) {
        let today = new Date();
        this.timeSheet.selectDate = {
            year: parseInt(today.getFullYear().toString()),
            month: parseInt((today.getMonth() + 1).toString()),
            day: parseInt(today.getDate().toString())
        };
        // this.selectDateMore = {
        //     year: parseInt(today.getFullYear().toString()),
        //     month: parseInt((today.getMonth() + 1).toString()),
        //     day: parseInt(today.getDate().toString())
        // }
        this.timeSheet.startTime = this.startTime;
        this.timeSheet.endTime = this.endTime;
        this.timeSheetList.push(this.timeSheet);
        this.fieldErrList.push(this.requiredObj);
    }

    ngOnInit() {
        this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
        if (undefined == this.userLogin || !this.userLogin) {
            this.router.navigate(['/login']);
        }
        // this.getProjectName();
        if (this.userLogin.role === "Professional Service") {
            this.startTime = "08:30";
            this.endTime = "17:30";
            this.timeSheetList[0].startTime = this.startTime;
            this.timeSheetList[0].endTime = this.endTime;
        }
        this.getListProject();
        this.getSpecialCase();
        
    }

    getProjectName() {
        let lov = new LovModel();
        lov.lovType_key2 = "PROJECT_NAME";
        lov.activeFlg_key1 = "Y";
        this.createTimeSheetService.getLov(lov).then(res => {
            if (res.responseCode == 200) {
                this.projectList = res.data;
            }
        })
    }

    getSpecialCase() {
        let lov = new LovModel();
        lov.lovType_key2 = "SPECIAL_CASE";
        lov.activeFlg_key1 = "Y";
        this.createTimeSheetService.getLov(lov).then(res => {
            if(res.responseCode == 200) {
                this.specialCaseList = res.data;
                
            }
        })
    }

    getFuncProject(sheetInfo,index?:number,elementName?:string) {
        
        sheetInfo.disabledFieldFunc = false;
        let func = new ProjectFunctionModel();
        if(this.defaultCode.length > 0) {
            func.projectCode = this.defaultCode;
        }else{
            func.projectCode = sheetInfo.projectCode;
        }
        
        func.activeFlg = "Y";
        this.createTimeSheetService.searchFunction(func).then(res => {
            if (res.responseCode == 200) {
                this.projectFunctionArr = res.data;
            } else {
                this.projectFunctionArr = new Array();
            }
            sheetInfo.funcList = this.projectFunctionArr;
            
        })
        //console.log('Time Sheet : ',this.timeSheetList[sheetInfo.index]);
    }

    addMoreSheet() {
        let sheet = new RoutineModel();
        sheet.startTime = this.startTime;
        sheet.endTime = this.endTime;
        sheet.projectCode = this.defaultCode;
        sheet.functionCode = "";
        sheet.disabledFieldFunc = !this.isCheck;
        if(this.defaultCode.length > 0) {
            this.getFuncProject(sheet);
        }
        
        // this.selectDateMore.day = this.selectDateMore.day + 1;
        // sheet.selectDate = {
        //     year: parseInt(this.selectDateMore.year.toString()),
        //     month: parseInt(this.selectDateMore.month.toString()),
        //     day: parseInt((this.selectDateMore.day).toString())
        // }

        let fieldReq = new FieldRequiredTSModel();
        this.timeSheetList.push(sheet);
        this.fieldErrList.push(fieldReq);
    }

    deleteSheet(index) {
        this.timeSheetList.splice(index, 1);
        this.fieldErrList.splice(index, 1);
    }

    change() {
        this.isChange = true;
    }

    checkValue(event: any, code) {
        
        if (event) {

            this.defaultCode = code;
            
            this.timeSheetList.forEach((ele,index) => {
                let funcListEmpty = this.validationService.isEmpty(ele.funcList);
                ele.projectCode = code;
                ele.functionCode = '';
                ele.disabledFieldFunc = false;
                
                if(funcListEmpty) {
                    this.getFuncProject(ele);
                }
            })
            


            

        } else {
            this.defaultCode = '';
            //var i = 0;
            this.timeSheetList.forEach((ele,index) => {
                if (index !== 0) {
                    ele.projectCode = '';
                    ele.functionCode = '';
                    ele.funcList = [];
                    ele.disabledFieldFunc = true;
                }
            })
        }

    }

    onCheckboxChagen(event, code, i) {

        if (event.checked) {

            this.isCheck = true;
        }
        if (!event.checked) {

            this.isCheck = false;
        }
        this.defaultCode = code;
        this.timeSheetList.forEach(ele => {
            ele.projectCode = code
        })

    }

    cancel(content) {
        if (this.isChange) {
            this.modal.title = "Confirm Navigation";
            this.modal.msg = "You have unsave changes. If you leave this page, your changes will be lost.";
            this.modal.msg2 = "Are You sure you want to leave this page?";
            this.modal.confirm = "Leave this page"
            this.modal.cancel = "Stay on this page"
            this.modalService.open(content, { backdrop: 'static' }).result.then((result) => {
                if (result === 'Y') {
                    this.router.navigate(['/home-user']);
                }
            }, (reason) => { });
        } else {
            this.router.navigate(['/home-user']);
        }
    }

    checkRequiredTs(index, value, elementName) {
        
        const isCheckValue = this.validationService.isEmpty(value);
        
        if(isCheckValue) {
            this.fieldErrList[index][elementName] = true;
        }else {
            this.fieldErrList[index][elementName] = false;
        }

        // if (undefined == value || !value || value.length === 0) {
        //     this.fieldErrList[index][elementName] = true;
        // } else {
        //     this.fieldErrList[index][elementName] = false;
        // }
        // if(!this.fieldErrList[index][elementName]) {
            
        // }else{
            
        // }
        
        
        let textArea = document.getElementById("textarea");
        textArea.style.overflow = 'hidden';
        textArea.style.height = '0px';
        textArea.style.height = textArea.scrollHeight + 'px';
        if (this.isCheck && elementName == 'projectName') {
            this.checkValue(true, value)
        }

    }

    checkChooseTime(startTime, endTime, index) {
        if ((undefined != startTime && startTime) && (undefined != endTime && endTime)) {
            let compareTime = moment(endTime, 'HH:mm').isSameOrBefore(moment(startTime, 'HH:mm'));
            if (compareTime) {
                this.fieldErrList[index].endTimeErr = true;
            } else {
                this.fieldErrList[index].endTimeErr = false;
            }

            let datetimeA = new Date('01/01/2000 ' + startTime);
            let datetimeB = new Date('01/01/2000 ' + endTime);
            let times = moment(datetimeB).diff(datetimeA, 'minutes');
            let finallyTime = 480;
            if (this.userLogin.role === "Professional Service") {
                finallyTime = this.calWorkTimePs(times);
            }else {
                finallyTime = this.calWorkTime(times);
            }
            this.timeSheetList[index].time = finallyTime.toString();
        }
    }

    calWorkTime(times) {

        let calTimesHr = parseInt((parseInt(times) / 60).toString());
        let calTimesMin = parseInt(times) % 60;
        let calTimes;
        if (calTimesMin < 10) {
            calTimes = calTimesHr + ".0" + calTimesMin;
        } else {
            calTimes = calTimesHr + "." + calTimesMin;
        }
        let decimal = calTimes.split('.');
        let time = parseInt(decimal[0]);
        let totalTime = 0;
        if (time > 4) {
            time = time - 5;
            totalTime = 4;
            if (time > 4) {
                time = time - 5;
                totalTime += 4;
                if (time > 6) {
                    time = time - 7;
                    totalTime += 6;
                    if (time > 2) {
                        totalTime += 2;
                    } else {
                        totalTime += time;
                    }
                } else {
                    totalTime += time;
                }
            } else {
                totalTime += time;
            }
        } else {
            totalTime = time;
        }

        if (totalTime == 16) {
            if (undefined != decimal[1] && decimal[1] && parseInt(decimal[1]) != 0) {
                decimal[1] = '0';
            }
        }
        totalTime = totalTime * 60;
        if (undefined != decimal[1] && decimal[1] && parseInt(decimal[1]) != 0) {
            // totalTime = parseFloat(totalTime.toString() + '.' + decimal[1]);
            totalTime = totalTime + parseInt(decimal[1]);
        }
        return totalTime;
    }
    
    calWorkTimePs(times) {
        let calTimesHr = parseInt((parseInt(times) / 60).toString());
        let calTimesMin = parseInt(times) % 60;
        let calTimes;
        if (calTimesMin < 10) {
            calTimes = calTimesHr + ".0" + calTimesMin;
        } else {
            calTimes = calTimesHr + "." + calTimesMin;
        }
        let decimal = calTimes.split('.');
        let time = parseInt(decimal[0]);
        let totalTime = 0;
        if (time > 4) {
            time = time - 5;
            totalTime = 4;
            if (time > 4) {
                time = time - 5;
                totalTime += 4;
                if (time > 6) {
                    time = time - 7;
                    totalTime += 6;
                    if (time > 4) {
                        totalTime += 4;
                    } else {
                        totalTime += time;
                    }
                } else {
                    totalTime += time;
                }
            } else {
                totalTime += time;
            }
        } else {
            totalTime = time;
        }
    
        if (totalTime == 16) {
            if (undefined != decimal[1] && decimal[1] && parseInt(decimal[1]) != 0) {
                decimal[1] = '0';
            }
        }
        totalTime = totalTime * 60;
        if (undefined != decimal[1] && decimal[1] && parseInt(decimal[1]) != 0) {
            // totalTime = parseFloat(totalTime.toString() + '.' + decimal[1]);
            totalTime = totalTime + parseInt(decimal[1]);
        }
        return totalTime;
    }

    async createTimeSheet(content) {
        this.loading = true;
        let cannotCreate = false
        let draftCreate: Array<RoutineModel> = __.cloneDeep(this.timeSheetList);
        let idUser = this.userLogin.idUser;
        let userName = this.userLogin.username;
        let today = new Date();

        await draftCreate.forEach((sheet, index) => {
            sheet.idUser = idUser;
            delete sheet.funcList;
            delete sheet.disabledFieldFunc;
            //------------------------------------ CHECK REQUIRED --------------------------------------//
            if (undefined == sheet.projectCode || !sheet.projectCode) {
                cannotCreate = true;
                this.fieldErrList[index].projectName = true;
            }
            if (undefined == sheet.selectDate || !sheet.selectDate) {
                cannotCreate = true;
                this.fieldErrList[index].date = true;
            } else {
                this.fieldErrList[index].date = false;
                let selectDate: NgbDateStruct = _.clone(sheet.selectDate);
                selectDate.month = selectDate.month - 1;
                sheet.date = moment(selectDate).format("DD-MM-YYYY");
                delete sheet.selectDate;
                sheet.month = moment(selectDate).format("MMMM");
            }
            if (undefined == sheet.startTime || !sheet.startTime) {
                cannotCreate = true;
                this.fieldErrList[index].startTime = true;
            }
            if (undefined == sheet.endTime || !sheet.endTime) {
                cannotCreate = true;
                this.fieldErrList[index].endTime = true;
            }
            if (undefined == sheet.detail || !sheet.detail) {
                cannotCreate = true;
                this.fieldErrList[index].desc = true;
            }
            if (undefined == sheet.functionCode || !sheet.functionCode) {
                cannotCreate = true;
                this.fieldErrList[index].functionCode = true;
            }
            if (this.fieldErrList[index].endTimeErr) {
                cannotCreate = true;
            }
            //------------------------------------ END CHECK REQUIRED --------------------------------------//
            //---------------------------------------- CHECK TIME ------------------------------------------//
            this.checkChooseTime(sheet.startTime, sheet.endTime, index);
            sheet.time = this.timeSheetList[index].time;
            //-------------------------------------- END CHECK TIME ----------------------------------------//
            //-------------------------------------- ADD NAME STAMP ----------------------------------------//
            sheet.createBy = userName;
            sheet.createDate = today;
            //------------------------------------ END ADD NAME STAMP --------------------------------------//




            // if (sheet.ot) {
            //     sheet.otDetail.forEach((ot, ind) => {
            //         if (undefined == ot.startTimeOt || !ot.startTimeOt) {
            //             cannotCreate = true;
            //             this.fieldErrList[index].ot[ind].startTimeOt = true;
            //         }
            //         if (undefined == ot.endTimeOt || !ot.endTimeOt) {
            //             cannotCreate = true;
            //             this.fieldErrList[index].ot[ind].endTimeOt = true;
            //         }
            //         if (undefined == ot.detailOt || !ot.detailOt) {
            //             cannotCreate = true;
            //             this.fieldErrList[index].ot[ind].descOt = true;
            //         }
            //         if (this.fieldErrList[index].ot[ind].endTimeOtErr) {
            //             cannotCreate = true;
            //         }
            //     });
            // }
        });
        if (!cannotCreate) {
            
            this.createTimeSheetService.postRoutine(draftCreate).then(res => {
                if (res.responseCode == 200) {

                    this.modal.title = "Success";
                    this.modal.msg = "Create Time Sheet Success.";
                    this.modal.confirm = "OK"
                    this.modalService.open(content, { size: 'sm', backdrop: 'static' }).result.then((result) => {
                        if (result == 'Y') {
                            this.router.navigate(['/home-user']);
                        }
                    }, (reason) => { });
                }
                this.loading = false;
            })
            
        } else {
            this.loading = false;
        }
    }

    checkRequiredDate(index) {

        if (undefined != this.timeSheetList[index].selectDate && this.timeSheetList[index].selectDate) {
            this.fieldErrList[index].date = false;
            this.selectDateMore = this.timeSheetList[index].selectDate;
        } else {
            this.fieldErrList[index].date = true;
        }
    }

    /********************************** TIME PICKER ************************************/
    parseTimeFomat(time: NgbTimeStruct): string {
        let hour = time.hour.toString();
        let minute = time.minute.toString();
        if (hour.length == 1) {
            hour = "0" + hour;
        }
        if (minute.length == 1) {
            minute = "0" + minute;
        }
        let timeFormat = hour + ":" + minute;
        return timeFormat;
    }

    openTimeStart(content, index) {
        let timeFormat = "";
        if (undefined != this.timeSheetList[index].startTime
            && this.timeSheetList[index].startTime) {
            let start = (this.timeSheetList[index].startTime).split(':');
            this.selectTime.hour = parseInt(start[0]);
            this.selectTime.minute = parseInt(start[1]);
        } else {
            this.selectTime.hour = 9;
            this.selectTime.minute = 0;
        }
        this.modal.title = "Start Time";
        this.modalService.open(content, { size: 'sm', backdrop: 'static' }).result.then((result) => {
            if (result == 'Y') {
                timeFormat = this.parseTimeFomat(this.selectTime);
                this.timeSheetList[index].startTime = timeFormat;
                this.checkRequiredTs(index, this.timeSheetList[index].startTime, "startTime");
                this.checkChooseTime(this.timeSheetList[index].startTime, this.timeSheetList[index].endTime, index)
            } 
        }, (reason) => { });
    }

    openTimeEnd(content, index) {
        let timeFormat = "";
        if (undefined != this.timeSheetList[index].endTime
            && this.timeSheetList[index].endTime) {
            let start = (this.timeSheetList[index].endTime).split(':');
            this.selectTime.hour = parseInt(start[0]);
            this.selectTime.minute = parseInt(start[1]);
        } else {
            this.selectTime.hour = 18;
            this.selectTime.minute = 0;
        }
        this.modal.title = "End Time";
        this.modalService.open(content, { size: 'sm', backdrop: 'static' }).result.then((result) => {
            if (result == 'Y') {
                timeFormat = this.parseTimeFomat(this.selectTime)
                this.timeSheetList[index].endTime = timeFormat;
                this.checkRequiredTs(index, this.timeSheetList[index].endTime, "endTime");
                this.checkChooseTime(this.timeSheetList[index].startTime, this.timeSheetList[index].endTime, index);
            }
        }, (reason) => { });
    }

    autogrow() {
        let textArea = document.getElementById("textarea")
        textArea.style.overflow = 'hidden';
        textArea.style.height = '0px';
        textArea.style.height = textArea.scrollHeight + 'px';
    }
    /********************************** TIME PICKER ************************************/

    async getListProject() {
        let emp = new EmployeeModel()
        emp.idUser = this.userLogin.idUser;
        this.createTimeSheetService.getEmployeeProjectList(emp).then(res => {
            if (res.responseCode == 200) {
                this.personalProjectList = res.data;
            }
        });
    }

    /******************************* OT UNUSE **********************************/
    checkRequiredOt(index, indexOt, value, elementName) {
        if (undefined == value || !value) {
            this.fieldErrList[index].ot[indexOt][elementName] = true;
        }else {
            this.fieldErrList[index].ot[indexOt][elementName] = false;
        }
    }

    addMoreOT(index) {
        let ot: RoutineOTModel = new RoutineOTModel();
        let fieldReqOt: FieldRequiredTSOtModel = new FieldRequiredTSOtModel();
        this.timeSheetList[index].otDetail.push(ot);
        this.fieldErrList[index].ot.push(fieldReqOt);
    }

    addOT(hasOt: boolean, index) {
        if (hasOt) {
            let ot: RoutineOTModel = new RoutineOTModel();
            let fieldReqOt: FieldRequiredTSOtModel = new FieldRequiredTSOtModel();
            this.timeSheetList[index].otDetail.push(ot);
            this.fieldErrList[index].ot.push(fieldReqOt);
            
        }else {
            this.timeSheetList[index].otDetail = new Array();
            this.fieldErrList[index].ot = new Array();
        }
    }

    deleteOT(index, indexOt) {
        this.timeSheetList[index].otDetail.splice(indexOt, 1);
        this.fieldErrList[index].ot.splice(indexOt, 1);
    }

    checkChooseTimeOt(startTime, endTime, index, indexOt) {
        if ((undefined != startTime && startTime) && (undefined != endTime && endTime)) {
            let compareTime = moment(endTime, 'HH:mm').isSameOrBefore(moment(startTime, 'HH:mm'));
            if (compareTime) {
                this.fieldErrList[index].ot[indexOt].endTimeOtErr = true;
            }else {
                this.fieldErrList[index].ot[indexOt].endTimeOtErr = false;
            }
        }
    }

    openTimeStartOt(content, index, indexOt) {
        let timeFormat = "";
        if (undefined != this.timeSheetList[index].otDetail[indexOt].startTimeOt
            && this.timeSheetList[index].otDetail[indexOt].startTimeOt) {
            let start = (this.timeSheetList[index].otDetail[indexOt].startTimeOt).split(':');
            this.selectTime.hour = parseInt(start[0]);
            this.selectTime.minute = parseInt(start[1]);
        }else {
            this.selectTime.hour = 19;
            this.selectTime.minute = 0;
        }
        this.modal.title = "OT Start Time";
        this.modalService.open(content, { size: 'sm', backdrop: 'static' }).result.then((result) => {
            if(result == 'Y'){
                timeFormat = this.parseTimeFomat(this.selectTime)
                this.timeSheetList[index].otDetail[indexOt].startTimeOt = timeFormat;
                this.checkRequiredOt(index, indexOt, this.timeSheetList[index].otDetail[indexOt].startTimeOt, "startTimeOt");
                this.checkChooseTimeOt(this.timeSheetList[index].otDetail[indexOt].startTimeOt, this.timeSheetList[index].otDetail[indexOt].endTimeOt, index, indexOt);
            }
        }, (reason) => {});
    }

    openTimeEndOt(content, index, indexOt) {
        let timeFormat = "";
        if (undefined != this.timeSheetList[index].otDetail[indexOt].endTimeOt
            && this.timeSheetList[index].otDetail[indexOt].endTimeOt) {
            let start = (this.timeSheetList[index].otDetail[indexOt].endTimeOt).split(':');
            this.selectTime.hour = parseInt(start[0]);
            this.selectTime.minute = parseInt(start[1]);
        }else {
            this.selectTime.hour = 23;
            this.selectTime.minute = 0;
        }
        this.modal.title = "OT End Time";
        this.modalService.open(content, { size: 'sm', backdrop: 'static' }).result.then((result) => {
            if(result == 'Y'){
                timeFormat = this.parseTimeFomat(this.selectTime)
                this.timeSheetList[index].otDetail[indexOt].endTimeOt = timeFormat;
                this.checkRequiredOt(index, indexOt, this.timeSheetList[index].otDetail[indexOt].endTimeOt, "endTimeOt");
                this.checkChooseTimeOt(this.timeSheetList[index].otDetail[indexOt].startTimeOt, this.timeSheetList[index].otDetail[indexOt].endTimeOt, index, indexOt);
            }
        }, (reason) => {});
    }
    /******************************* OT UNUSE **********************************/

}
