import { Injectable } from '@angular/core';
import { ProjectFunctionService } from '../service/project-function.service';
import { LovModel } from '../model/lov';
import { LovService } from '../service/lov.service';
import { ProjectFunctionModel } from '../model/projectFunction';

@Injectable({
  providedIn: 'root'
})
export class EditFunctionService {

  constructor(
    private projectFunctionService : ProjectFunctionService,
    private lovService: LovService
  ) { }

  getLov(lov: LovModel) {
    return this.lovService.getLov(lov);
  }

  searchFunction(func: ProjectFunctionModel) {
    return this.projectFunctionService.searchFunction(func);
  }

  putFunction(func: ProjectFunctionModel) {
    return this.projectFunctionService.putFunction(func);
  }
}
