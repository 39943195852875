export class CheckinModel {
    public _id: any;
    public idUser: string;
    public startTime: string;
    public date: string;
    public password: string;
    public role: string;
    public position: string;
    public active: string;
    public passwordToken: string;
    public skill: any;
    public tel: string;
    public email: string;
    public line: string;
    public hobbies: any;
}