import { UserModel } from './../model/users.model';
import { RouterExtService } from './../service/routerExtService.service';
import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import * as __ from 'lodash';

@Component({
  selector: 'app-home-ps',
  templateUrl: './home-ps.component.html',
  styleUrls: ['./home-ps.component.css']
})
export class HomePsComponent implements OnInit {
  public selectedwallet = 'checkin'
  public userLogin: UserModel = new UserModel();
  public previousUrl: string;
  constructor(
    private routerService: RouterExtService,
    private router: Router,
    private activatedRoute:ActivatedRoute,
  ) { }

  ngOnInit() {
    this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
    if (undefined == this.userLogin || !this.userLogin) {
      this.router.navigate(['/login']);
    } else {
      if (this.userLogin.role.toLocaleUpperCase() === 'GENERAL') {
        this.router.navigate(['/home-user']);
      }else{
        if(this.activatedRoute.snapshot.queryParams.previousUrl) {
          this.previousUrl = this.activatedRoute.snapshot.queryParams.previousUrl;
        }else{
          this.previousUrl = this.routerService.getPreviousUrl();
        }
        
      }
    }
    
    
    if (this.previousUrl.startsWith('/edit-timesheets') ||  this.previousUrl.startsWith('/new-timesheets')) {
      this.selectedtab('timeSheet')
    }
    
    if (this.previousUrl.startsWith('/new-function')) {
      this.selectedtab('timeSheetfunc')
    }
    if (this.previousUrl.startsWith('/edit-function')) {
      this.selectedtab('timeSheetfunc')
    }
    if (this.previousUrl.startsWith('/view-profile')) {
      this.selectedtab('member')
    }
    if (this.previousUrl.startsWith('/new-leave-ps')) {
      this.selectedtab('leaveWork')
    }
  }
  selectedtab(tab) {
    this.selectedwallet = tab;
  }

}
