import { ViewAllTimeSheetService } from './view-all-time-sheet.service';
import { RoutineModel } from './../model/routine.model';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserModel } from '../model/users.model';
import * as moment from 'moment'
import * as _ from "underscore";
import { NgbModal, NgbTimeStruct, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { LovModel } from '../model/lov';
import { EmployeeModel } from '../model/employee.model';
@Component({
  selector: 'app-view-all-time-sheet',
  templateUrl: './view-all-time-sheet.component.html',
  styleUrls: ['./view-all-time-sheet.component.css'],
  providers: [NgbTooltipConfig]
})
export class ViewAllTimeSheetComponent implements OnInit {
  public loading = false;
  public userLogin: UserModel = new UserModel();
  public routineList: Array<RoutineModel> = new Array<RoutineModel>();
  public years: Array<string> = new Array();
  public listProject: any[];
  public isCheck: boolean = false;
  public addVacationTimeCheck: boolean = false;
  public roleUser: boolean = true;
  public projectList: Array<LovModel> = new Array<LovModel>();
  public personalProjectList: Array<LovModel> = new Array();
  public search = {
    month: "",
    year: "",
    projectCode: ""
  };
  public monthList = [
    { code: '01', name: 'January' },
    { code: '02', name: 'February' },
    { code: '03', name: 'March' },
    { code: '04', name: 'April' },
    { code: '05', name: 'May' },
    { code: '06', name: 'June' },
    { code: '07', name: 'July' },
    { code: '08', name: 'August' },
    { code: '09', name: 'September' },
    { code: '10', name: 'October' },
    { code: '11', name: 'November' },
    { code: '12', name: 'December' },

  ]
  public modal = {
    title: "",
    msg: "",
    msg2: "",
    confirm: "",
    cancel: ""
  }
  constructor(
    private router: Router,
    private routineService: ViewAllTimeSheetService,
    private modalService: NgbModal,
    public config: NgbTooltipConfig
  ) { }

  ngOnInit() {
    this.loading = true;
    this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));

    if (undefined == this.userLogin || !this.userLogin) {
      this.router.navigate(['/login']);
    }
    if (this.userLogin.role.toLocaleUpperCase() === "PROFESSIONAL SERVICE") {
      this.roleUser = false;
    }

    // console.log(this.userLogin)
    // this.getProjectName()
    this.getListProject()
    this.getDropdownYear();
    this.search.year = new Date().getFullYear().toString();// "2019";
    let month = moment().subtract(0, "month").startOf("month").format('MMMM');
    this.search.month = month;
    let searchTemp = JSON.parse(localStorage.getItem('searchRoutine'));
    if (searchTemp != undefined && searchTemp != "" && searchTemp != null) {
      this.search = searchTemp
    }
    this.getRoutine(this.userLogin, this.search.month, this.search.year, '');
  }

  async getListProject() {
    let emp = new EmployeeModel()
    emp.idUser = this.userLogin.idUser;
    this.routineService.getEmployeeProjectList(emp).then(res => {
      if (res.responseCode == 200) {
        this.personalProjectList = res.data;
      }
    });
  }

  back() {
    this.router.navigate(['/home']);
  }

  searchRoutine(search) {
    localStorage.setItem('searchRoutine', JSON.stringify(search));
    this.getRoutine(this.userLogin, search.month, search.year, search.projectCode)
  }

  getDropdownYear() {
    var start = 2010;
    var end = new Date().getFullYear();
    for (var year = start; year <= end; year++) {
      this.years.push(year.toString());
    }
    this.years.reverse();
  }

  getRoutine(user: UserModel, month, year, projectCode) {
    this.loading = true;
    this.routineList = new Array<RoutineModel>();
    let routine: RoutineModel = new RoutineModel();
    routine.idUser = user.idUser;
    routine.month = month;
    routine.year = year;
    if (projectCode != "" && projectCode != undefined) {
      routine.projectCode = projectCode;
    }
    this.routineService.getListRoutineView(routine).then(result => {
      if (result.responseCode == 200) {
        this.routineList = result.data;
        // console.log("this.routineList", this.routineList)
        this.routineList.forEach(ele => {



          // ele.functionAll.forEach(func => {
          //   if (func.functionCode === ele.functionCode) {
          //     ele.funcName = func.functionDesc
          //   }
          // });




          ele.detail = ele.detail.replace(/(\\r\\n\\)|([\r\n])/gmi, '<br/>');
          ele.time = this.calTime(ele.time).toString();
          ele.date = moment(ele.date, "DD-MM-YYYY").format('DD-MM-YYYY');
          if (ele.ot) {
            ele.timesOt = this.calTime(ele.timesOt).toString();
            ele.monthOrder = moment(ele.date, "DD-MM-YYYY").format('MM');
          }
        });
        this.listProject = _.chain(this.routineList)
          .groupBy("monthOrder")
          .pairs()
          .map(function (currentItem) {
            currentItem.push(moment(currentItem[0], 'MM').format('MMMM'));
            return _.object(_.zip(["monthOrder", "details", "month"], currentItem));
          })
          .value();

        this.listProject = _.sortBy(this.listProject, "monthOrder")

        let detail: any[];
        this.listProject.forEach(ele => {
          detail = _.chain(ele.details).groupBy("date")
            .pairs()
            .map(function (currentItem) {
              currentItem.push(moment(currentItem[0], 'DD-MM-YYYY').format('ddd'));
              return _.object(_.zip(["date", "details", "c"], currentItem));
            })
            .value();
          ele.details = _.sortBy(detail, 'date').reverse()//detail.reverse();
        })
        this.listProject.reverse();
        // if (this.addVacationTime) {
        //   this.addVacationTime()
        // }
        this.loading = true;
      } else {
        this.routineList = new Array<RoutineModel>();
        this.listProject = [];
      }
      this.loading = false;
    });
  }

  calTime(mins) {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = h < 10 ? 0 + h : h;
    m = m < 10 ? 0 + m : m;
    return `${h}.${m}`;
  }

  deleteRoutine(content, id) {
    this.modal.title = "Confirm Delete.";
    this.modal.msg = "";
    this.modal.msg2 = "Are you sure to delete this?";
    this.modal.confirm = "Yes"
    this.modal.cancel = "No"
    this.modalService.open(content, { backdrop: 'static' }).result.then((result) => {
      if (result == 'Y') {
        this.loading = true;
        let routine = new RoutineModel();
        routine._id = id;
        routine.idUser = this.userLogin.idUser;
        this.routineService.delRoutine(routine).subscribe(result => {
          if (result.responseCode == 200) {
            alert('success')
            this.getRoutine(this.userLogin, this.search.month, this.search.year, this.search.projectCode);
            this.loading = false;
          }

        });
      }
    }, (reason) => { });
  }

  editRoutine(detail) {
    this.router.navigate(['/edit-timesheets/' + detail._id]);
  }

  change(code) {
    // this.getRoutine(this.userLogin, code)
  }

  newTimeSheet() {
    localStorage.removeItem('searchRoutine')
    this.router.navigate(['/new-timesheets']);
  }

  checkValue(event: any) {
    if (event) {
      this.addVacationTimeCheck = true;
    } else {
      this.addVacationTimeCheck = false;
    }
  }

  addVacationTime() {
    // console.log(this.listProject)
    if (this.addVacationTimeCheck) {
      this.listProject.forEach(ele => {
        let monthTemp = moment().day(1).month(ele.month).year(parseInt(this.search.year));
        let startOfMonth = moment(monthTemp, 'YYYY-MMM-DD').startOf('month');
        let startDate = moment(startOfMonth).endOf('month').format('DD');
        let temp = [];
        for (let i = parseInt(startDate); i >= 1; i--) {

          let day = moment().day(i).month(ele.month).year(parseInt(this.search.year)).format('DD-MM-YYYY')
          let isHoliday = moment(day, 'DD-MM-YYYY').format('ddd')

          let a = _.findWhere(ele.details, { date: day });
          if (a != undefined) {
            if (isHoliday == 'Sun' || isHoliday == 'Sat') {
              a.holiday = true;
            }
            temp.push(a)
          } else {

            let vacation = {
              date: day,
              details: [],
              c: moment(day, 'DD-MM-YYYY').format('ddd'),
              holiday: (isHoliday == 'Sun' || isHoliday == 'Sat') ? true : false
            }
            temp.push(vacation)
          }
        }
        temp.reverse();
        ele.details = temp
        ele.details = _.sortBy(temp, 'date').reverse()
      });
      // console.log(this.listProject);
    }
  }

  getProjectName() {
    this.loading = true;
    let lov = new LovModel();
    lov.lovType_key2 = "PROJECT_NAME";
    lov.activeFlg_key1 = "Y";
    this.routineService.getLov(lov).then(res => {
      if (res.responseCode == 200) {
        this.projectList = res.data;
      }
      this.loading = false;
    });
  }
}
