import { async } from '@angular/core/testing';
import { EditTimesheetService } from './edit-timesheet-service.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '../../../node_modules/@angular/router';
import { RoutineModel, UpdateRoutineModel } from './../model/routine.model';
import { Router } from '@angular/router';
import { UserModel } from '../model/users.model';
import * as moment from 'moment'
import * as _ from "underscore";
import * as __ from "lodash";
import { NgbModal, NgbTimeStruct, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { ViewAllTimeSheetService } from '../view-all-time-sheet/view-all-time-sheet.service';
import { LovModel } from '../model/lov';
import { FieldRequiredTSModel } from '../model/fieldRequired';
import { ProjectFunctionModel } from '../model/projectFunction';

@Component({
    selector: 'app-edit-timesheet',
    templateUrl: './edit-timesheet.component.html',
    styleUrls: ['./edit-timesheet.component.css']
})
export class EditTimesheetComponent implements OnInit {
    public routeParams: any;
    public routineId: any;
    public userLogin: UserModel = new UserModel();
    public routineList: Array<RoutineModel> = new Array<RoutineModel>();
    public specialCaseList: Array<LovModel> = [];
    public routine: RoutineModel = new RoutineModel();
    public backupRoutine: RoutineModel = new RoutineModel();
    public fieldErr: FieldRequiredTSModel = new FieldRequiredTSModel();
    public projectFunctionArr: Array<ProjectFunctionModel> = new Array();

    public selectDate: NgbDateStruct;
    public selectTime: NgbTimeStruct = { hour: 0, minute: 0, second: 0 }
    public minuteStep = 15;
    public startTime = "09:00";
    public endTime = "18:00";

    public projectList: Array<LovModel> = new Array();
    public isChange: boolean = false;

    public modal = {
        title: "",
        msg: "",
        msg2: "",
        confirm: "",
        cancel: ""
    }
    public warn = {
        date: false,
        startTime: false,
        endTime: false
    }
    public roleUser: boolean = true;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private editTimesheetService: EditTimesheetService,
        private modalService: NgbModal,
        public config: NgbTooltipConfig,
    ) { }

    ngOnInit() {
        this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
        if (undefined == this.userLogin || !this.userLogin) {
            this.router.navigate(['/login']);
        }
        if (this.userLogin.role.toLocaleUpperCase() === "PROFESSIONAL SERVICE") {
            this.roleUser = false;
        }
        this.route.params.subscribe(params => {
            this.routeParams = params;
            this.routineId = this.routeParams.id;
            this.getRoutine(this.routineId);
        });
        this.getProjectName();
        this.getSpecialCase();
    }

    ngAfterContentChecked(): void {
        this.checkRequiredTsDetail();
    }

    async getRoutine(id) {
        let routine: RoutineModel = new RoutineModel();
        routine._id = id;
        await this.editTimesheetService.getRoutine(routine).then(async result => {
            if (result.responseCode == 200) {
                if (result.data[0].idUser == this.userLogin.idUser) {
                    this.routine = result.data[0];

                    this.backupRoutine = __.cloneDeep(this.routine);

                    if (undefined != this.routine.date && this.routine.date) {
                        if (this.routine.date == "Invalid date") {
                            this.warn.date = true;
                        } else {
                            let dateString = moment(this.routine.date, "DD-MM-YYYY").format("MM-DD-YYYY");
                            let date = new Date(dateString);
                            this.selectDate = {
                                year: parseInt(date.getFullYear().toString()),
                                month: parseInt((date.getMonth() + 1).toString()),
                                day: parseInt(date.getDate().toString())
                            };
                        }
                    }
                    this.getFuncProject(this.routine.projectCode)
                    if (this.routine.startTime == "Invalid date") {
                        this.warn.startTime = true;
                    }
                    if (this.routine.endTime == "Invalid date") {
                        this.warn.endTime = true;
                    }
                } else {
                    if (this.userLogin.role.toUpperCase() == "PROFESSIONAL SERVICE") {
                        this.router.navigate(['/home-ps']);
                    }else{
                        this.router.navigate(['/home-user']);
                    }
                }

            } else {
                if (this.userLogin.role.toUpperCase() == "PROFESSIONAL SERVICE") {
                    this.router.navigate(['/home-ps']);
                }else{
                    this.router.navigate(['/home-user']);
                }
            }
        });
    }

    getProjectName() {
        let lov = new LovModel();
        lov.lovType_key2 = "PROJECT_NAME";
        lov.activeFlg_key1 = "Y";
        this.editTimesheetService.getLov(lov).then(res => {
            if (res.responseCode == 200) {
                this.projectList = res.data;
            }
        })
    }

    getSpecialCase() {
        let lov = new LovModel();
        lov.lovType_key2 = "SPECIAL_CASE";
        lov.activeFlg_key1 = "Y";
        this.editTimesheetService.getLov(lov).then(res => {
            if(res.responseCode == 200) {
                this.specialCaseList = res.data;
                
            }
        })
    }

    change() {
        this.isChange = true;
    }

    cancel(content) {
        if (this.isChange) {
            this.modal.title = "Confirm Navigation";
            this.modal.msg = "You have unsave changes. If you leave this page, your changes will be lost.";
            this.modal.msg2 = "Are You sure you want to leave this page?";
            this.modal.confirm = "Leave this page"
            this.modal.cancel = "Stay on this page"
            this.modalService.open(content, { backdrop: 'static' }).result.then((result) => {
                if (result == 'Y') {
                    if (this.userLogin.role.toUpperCase() == "PROFESSIONAL SERVICE") {
                        this.router.navigate(['/home-ps']);
                    }else{
                        this.router.navigate(['/home-user']);
                    }
                  
                }
            }, (reason) => { });
        } else {
            if (this.userLogin.role.toUpperCase() == "PROFESSIONAL SERVICE") {
                this.router.navigate(['/home-ps']);
            }else{
                this.router.navigate(['/home-user']);
            }
            
        }
    }

    dateChange() {
        if (this.routine.date == "Invalid date" && (undefined == this.selectDate || !this.selectDate)) {
            this.warn.date = true;
        } else {
            this.warn.date = false;
            let tranformDate: NgbDateStruct = _.clone(this.selectDate);
            tranformDate.month = tranformDate.month - 1;
            this.routine.date = moment(tranformDate).format("DD-MM-YYYY");
            this.routine.month = moment(tranformDate).format("MMMM");
        }
    }

    async checkRequiredTsDetail() {
        let textArea = document.getElementById("textarea");
        textArea.style.overflow = 'hidden';
        textArea.style.height = '0px';
        textArea.style.height = textArea.scrollHeight + 'px';
    }

    checkRequiredTs(value, name) {
        if (undefined == value || !value) {
            this.fieldErr[name] = true;
        }
    }

    checkChooseTime(startTime, endTime) {
        if ((undefined != startTime && startTime) && (undefined != endTime && endTime)) {
            let compareTime = moment(endTime, 'HH:mm').isSameOrBefore(moment(startTime, 'HH:mm'));
            if (compareTime) {
                this.fieldErr.endTimeErr = true;
            } else {
                this.fieldErr.endTimeErr = false;
            }

            let datetimeA = new Date('01/01/2000 ' + startTime);
            let datetimeB = new Date('01/01/2000 ' + endTime);
            let times = moment(datetimeB).diff(datetimeA, 'minutes');
            let finallyTime = this.calWorkTime(times);
            this.routine.time = finallyTime.toString();
        }
    }

    checkOT(ot:any) : boolean {

        let typeOfOT = typeof(ot);
        
        if(typeOfOT === 'string') {
            if(ot === 'true') {
                return true;
            }

            if(ot === 'false') {
                return false;
            }
        }

    }

    calWorkTime(times) {
        let calTimes = (parseFloat(times)) / 60;
        let decimal = (calTimes.toString()).split('.');
        let time = parseInt(decimal[0]);
        let totalTime = 0;
        if (time > 4) {
            time = time - 5;
            totalTime = 4;
            if (time > 4) {
                time = time - 5;
                totalTime += 4;
                if (time > 6) {
                    time = time - 7;
                    totalTime += 6;
                    if (time > 2) {
                        totalTime += 2;
                    } else {
                        totalTime += time;
                    }
                } else {
                    totalTime += time;
                }
            } else {
                totalTime += time;
            }
        } else {
            totalTime = time;
        }

        if (totalTime == 16) {
            if (undefined != decimal[1] && decimal[1] && parseInt(decimal[1]) != 0) {
                decimal[1] = '0';
            }
        }
        if (undefined != decimal[1] && decimal[1] && parseInt(decimal[1]) != 0) {
            totalTime = parseFloat(totalTime.toString() + '.' + decimal[1]);
        }
        return totalTime * 60;
    }

    /********************************** TIME PICKER ************************************/
    parseTimeFomat(time: NgbTimeStruct): string {
        let hour = time.hour.toString();
        let minute = time.minute.toString();
        if (hour.length == 1) {
            hour = "0" + hour;
        }
        if (minute.length == 1) {
            minute = "0" + minute;
        }
        let timeFormat = hour + ":" + minute;
        return timeFormat;
    }

    openTimeStart(content) {
        let timeFormat = "";
        if (undefined != this.routine.startTime
            && this.routine.startTime) {
            if (this.routine.startTime == "Invalid date") {
                this.routine.startTime = "09:00";
                this.warn.startTime = true;
            }
            let start = (this.routine.startTime).split(':');
            this.selectTime.hour = parseInt(start[0]);
            this.selectTime.minute = parseInt(start[1]);
        } else {
            this.selectTime.hour = 9;
            this.selectTime.minute = 0;
        }
        this.modal.title = "Start Time";
        this.modalService.open(content, { size: 'sm', backdrop: 'static' }).result.then((result) => {
            if (result == 'Y') {
                timeFormat = this.parseTimeFomat(this.selectTime);
                this.routine.startTime = timeFormat;
                this.warn.startTime = false;
                this.checkRequiredTs(this.routine.startTime, "startTime");
                this.checkChooseTime(this.routine.startTime, this.routine.endTime);
            }
        }, (reason) => { });
    }

    openTimeEnd(content) {
        let timeFormat = "";
        if (undefined != this.routine.endTime
            && this.routine.endTime) {
            if (this.routine.endTime == "Invalid date") {
                this.routine.endTime = "18:00";
                this.warn.endTime = true;
            }
            let start = (this.routine.endTime).split(':');
            this.selectTime.hour = parseInt(start[0]);
            this.selectTime.minute = parseInt(start[1]);
        } else {
            this.selectTime.hour = 18;
            this.selectTime.minute = 0;
        }
        this.modal.title = "End Time";
        this.modalService.open(content, { size: 'sm', backdrop: 'static' }).result.then((result) => {
            if (result == 'Y') {
                timeFormat = this.parseTimeFomat(this.selectTime);
                this.routine.endTime = timeFormat;
                this.warn.endTime = false;
                this.checkRequiredTs(this.routine.endTime, "endTime");
                this.checkChooseTime(this.routine.startTime, this.routine.endTime);
            }
        }, (reason) => { });
    }
    /********************************** TIME PICKER ************************************/

    saveSheet(content) {
        let canSave = true;
        console.log(this.routine)
        console.log(this.backupRoutine)
        if (JSON.stringify(this.routine) == JSON.stringify(this.backupRoutine)) {
            this.modal.title = "Warning";
            this.modal.msg = "Please edit data before save.";
            this.modal.confirm = "OK";
            this.modalService.open(content, { size: 'sm', backdrop: 'static' }).result.then((result) => {
                if (result == 'Y') {
                    canSave = false;
                }
            }, (reason) => { });
        } else {
            if (undefined == this.routine.date || !this.routine.date) {
                this.fieldErr.date = true;
                canSave = false;
            } else if (this.routine.date == "Invalid date") {
                this.warn.date = true;
                canSave = false;
            }
            if (undefined == this.routine.startTime || !this.routine.startTime) {
                this.fieldErr.startTime = true;
                canSave = false;
            }
            if (undefined == this.routine.endTime || !this.routine.endTime) {
                this.fieldErr.endTime = true;
                canSave = false;
            }
            if (undefined == this.routine.detail || !this.routine.detail) {
                this.fieldErr.desc = true;
                canSave = false;
            }

            if (canSave) {
                let updateSheet = new UpdateRoutineModel();
                updateSheet._id = this.routine._id;
                updateSheet.date = this.routine.date;
                updateSheet.functionCode = this.routine.functionCode;
                updateSheet.startTime = this.routine.startTime;
                updateSheet.endTime = this.routine.endTime;
                updateSheet.detail = this.routine.detail;
                updateSheet.time = this.routine.time;
                updateSheet.month = this.routine.month;
                updateSheet.updateBy = this.userLogin.username;
                updateSheet.updateDate = new Date();
                console.log("updateSheet", updateSheet);

                this.editTimesheetService.updateRoutine(updateSheet).then(res => {
                    if (res.responseCode == 200) {
                        console.log("Success");
                        this.modal.title = "Success";
                        this.modal.msg = "Update Time Sheet Success.";
                        this.modal.confirm = "OK"
                        this.modalService.open(content, { size: 'sm', backdrop: 'static' }).result.then((result) => {
                            if (result == 'Y') {
                                if (this.userLogin.role.toUpperCase() == "PROFESSIONAL SERVICE") {
                                    this.router.navigate(['/home-ps']);
                                }else{
                                    this.router.navigate(['/home-user']);
                                }
                            }
                        }, (reason) => { });
                    } else {
                        this.modal.title = "Warning";
                        this.modal.msg = res.responseMessage;
                        this.modal.confirm = "OK"
                        this.modalService.open(content, { size: 'sm', backdrop: 'static' })
                    }
                })
            }
        }
    }

    getFuncProject(projectCode) {
        console.log('ssss')
        let func = new ProjectFunctionModel();
        func.projectCode = projectCode;
        func.activeFlg = "Y";
        this.editTimesheetService.searchFunction(func).then(res => {
            if (res.responseCode == 200) {
                this.projectFunctionArr = res.data;
            } else {
                this.projectFunctionArr = new Array();
            }
        })
    }
}
