import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ProjectFunctionModel } from '../model/projectFunction';
import { API } from '../shared/url-constant';

@Injectable({
  providedIn: 'root'
})
export class ProjectFunctionService {

  constructor(
    public http: HttpClient
  ) { }

  searchFunction(func: ProjectFunctionModel) {
    let httpParams = new HttpParams();
    if (undefined != func.projectCode && func.projectCode) {
      httpParams = httpParams.set('projectCode', func.projectCode);
    }
    if (undefined != func._id && func._id) {
      httpParams = httpParams.set('_id', func._id);
    }
    if (undefined != func.activeFlg && func.activeFlg) {
      httpParams = httpParams.set('activeFlg', func.activeFlg);
    }
    const reqOptions = {
      params: httpParams
    };
    return this.http.get<any>(API.GET_PROJECT_FUNCTION, reqOptions).toPromise();
  }

  getMaxFunctionId(projectCode) {
    let httpParams = new HttpParams();
    if (undefined != projectCode) {
      httpParams = httpParams.set('projectCode', projectCode);
    }
    const reqOptions = {
      params: httpParams
    };
    return this.http.get<any>(API.GET_MAX_FUNCTION, reqOptions).toPromise();
  }

  createFunction(func: ProjectFunctionModel) {
    return this.http.post<any>(API.POST_FUNCTION, { message: func }).toPromise();
  }

  putFunction(func: ProjectFunctionModel) {
    return this.http.put<any>(API.PUT_FUNCTION, { message: func }).toPromise();
  }

  deleteFunction(id){
    return this.http.delete<any>(API.DEL_FUNCTION, { params: {_id:id} }).toPromise();
  }
}
