import { EmployeeService } from './../service/employee.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UserModel } from '../model/users.model';

@Component({
  selector: 'app-view-profile',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.css']
})
export class ViewProfileComponent implements OnInit {
  public loading = false;
  public routeParams: any;
  public routineId: any;
  public member = new UserModel();
  public userLogin: UserModel = new UserModel();
  constructor(
    private route: ActivatedRoute,
    private employeeService: EmployeeService,
    private router: Router
  ) { }

  ngOnInit() {
    this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
    this.loading = true;
    this.route.params.subscribe(params => {
      let userModel = new UserModel()
      this.routeParams = params;
      userModel.idUser = this.routeParams.id;
      this.employeeService.getUserLogin(userModel).then(res => {
        if (res.responseCode == 200) {
          console.log(res.data[0])
          this.member = res.data[0];
          if (this.member.img == "" || this.member.img == undefined) {
            this.member.img = null

          }
          this.member.skill.forEach(element => {
            if (element.value == '') {
              element.star = 0;
            } else {
              element.star = Array(element.value).fill(1).map((x, i) => i);
            }
          });
        }
        this.loading = false;
      });

    });
  }
  cancel() {
    if (this.userLogin.role === 'Admin') {
      this.router.navigate(['/home']);
    } else {
      this.router.navigate(['/home-user']);
    }

  }
}
