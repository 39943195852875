import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbTimeStruct, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '../../../node_modules/@angular/router';

@Component({
  selector: 'app-create-config',
  templateUrl: './create-config.component.html',
  styleUrls: ['./create-config.component.css']
})
export class CreateConfigComponent implements OnInit {
  public modal = {
    title: "",
    msg: "",
    msg2: "",
    confirm: "",
    cancel: ""
  }
  constructor(
    private modalService: NgbModal,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  cancel(content) {
    this.modal.title = "Confirm Navigation";
    this.modal.msg = "You have unsave changes. If you leave this page, your changes will be lost.";
    this.modal.msg2 = "Are You sure you want to leave this page?";
    this.modal.confirm = "Leave this page"
    this.modal.cancel = "Stay on this page"
    this.modalService.open(content, { backdrop: 'static' }).result.then((result) => {
      if (result == 'Y') {
        this.router.navigate(['/home']);
      }
    }, (reason) => { });
  }



}
