import { EmployeeModel } from './../model/employee.model';
import { EmployeeService } from './../service/employee.service';
import { RoutineService } from '../service/routine.service';
import { LovModel } from '../model/lov';
import { Injectable } from '@angular/core';
import { LovService } from '../service/lov.service';
import { ProjectFunctionModel } from '../model/projectFunction';
import { ProjectFunctionService } from '../service/project-function.service';

@Injectable()
export class CreateTimeSheetService {
    constructor(
        private lovService: LovService,
        private routineService: RoutineService,
        private projectFunctionService: ProjectFunctionService,
        private employeeService : EmployeeService

    ) { }

    getLov(lov: LovModel) {
        return this.lovService.getLov(lov);
    }

    postRoutine(routine) {
        return this.routineService.postRoutine(routine);
    }

    getRoutineOrigin(routine) {
        return this.routineService.getRoutineOrigin(routine);
    }
    
    searchFunction(func: ProjectFunctionModel) {
        return this.projectFunctionService.searchFunction(func);
    }

    getEmployeeProjectList(emp: EmployeeModel) {
        return this.employeeService.getEmployeeProjectList(emp);
    }
    
}
