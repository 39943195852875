import { Component, OnInit } from '@angular/core';
import { UserModel } from '../model/users.model';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { RouterExtService } from '../service/routerExtService.service';

import { Location } from '@angular/common';
import { PassedParamsService } from '../service/passed-params';

@Component({
    selector: 'app-project',
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {

    public userLogin: UserModel = new UserModel();
    public isAdmin: boolean = false;
    public visibleActive: boolean = true;
    public previousUrl: string;
    public currentUrl: string;
    public selectedwallet = "";

    constructor(
        private router: Router,
        public route: ActivatedRoute,
        private routerService: RouterExtService,
        private location: Location,
        private paramsService: PassedParamsService
    ) {

    }
    public referrer = this.routerService.getPreviousUrl();

    ngOnInit() {
        this.selectedwallet = this.paramsService.getAdminMenu();
        this.previousUrl = this.routerService.getPreviousUrl();
        this.visibleActive = true
        this.userLogin = JSON.parse(localStorage.getItem('curentUserLogin'));
        if (undefined != this.userLogin && this.userLogin) {
            if (this.userLogin.role.toUpperCase() == "ADMIN") {
                this.isAdmin = true;
                if (this.previousUrl.startsWith('/new-projects')) {
                    this.selectedtab('profile')
                }
                if (this.previousUrl.startsWith('/view-profile')) {
                    this.selectedtab('users')
                }
                if (this.previousUrl.startsWith('/new-config')) {
                    this.selectedtab('config')
                }
                if (this.previousUrl.startsWith('/edit-project')) {
                    this.selectedtab('profile')
                }
                if (this.previousUrl.startsWith('/edit-users')) {
                    this.selectedtab('users')
                }
                if (this.previousUrl.startsWith('/new-users')) {
                    this.selectedtab('users')
                }
            } else {
                if (this.previousUrl.startsWith('/new-projects')) {
                    this.selectedtab('profile')
                }
                if (this.previousUrl.startsWith('/new-users')) {
                    this.selectedtab('users')
                }
               
            }
        } else {
            this.router.navigate(['/login']);
        }
    }

    selectedtab(tab) {
        this.selectedwallet = tab;
        this.paramsService.setAdminMenu(tab);
    }

    routerNewTimeSheet() {
        this.router.navigate(['/new-timesheets']);
    }

    routerViewAllTimeSheet() {
        if (this.isAdmin) {
            this.router.navigate(['/view-timesheets']);
        } else {
            this.router.navigate(['/view-timesheets-all']);
        }
    }

    routerCreateProject() {
        this.router.navigate(['/new-projects']);
        this.router.navigate(['/view-all-project']);
    }

    routerCreateUser() {
        this.router.navigate(['/new-users']);
    }

}
