import { FieldRequireCreateUser } from './../model/fieldRequired';
import { EmployeeModel } from './../model/employee.model';
import { LovService } from './../service/lov.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbTimeStruct, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FieldRequiredTSModel, FieldRequireCreateTSModel } from '../model/fieldRequired';
import { LovModel } from '../model/lov';
import * as moment from 'moment'
import { UserModel } from '../model/users.model';
import { EmployeeService } from '../service/employee.service';
import * as CryptoJS from 'crypto-js';
import { TOKEN } from '../shared/url-constant';
// import { TOKEN } from 'src/environments/environment';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {
  public modal = {
    title: "",
    msg: "",
    msg2: "",
    confirm: "",
    cancel: ""
  }
  public maxUserId: number = 0;
  public isChange: boolean = false;
  public employeeModel = new EmployeeModel();
  public fieldErrList: FieldRequireCreateUser = new FieldRequireCreateUser();
  public loading = false;
  public isOkForUsername: boolean = true;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private employeeService: EmployeeService
  ) { }

  async ngOnInit() {
    this.maxUserId = await this.getIdUser();
    // console.log(this.maxUserId)
    this.employeeModel.idUser = this.maxUserId.toString();
    this.employeeModel.position = '';
  }

  cancel(content) {
    if (this.isChange) {
      this.modal.title = "Confirm Navigation";
      this.modal.msg = "You have unsave changes. If you leave this page, your changes will be lost.";
      this.modal.msg2 = "Are You sure you want to leave this page?";
      this.modal.confirm = "Leave this page"
      this.modal.cancel = "Stay on this page"
      this.modalService.open(content, { backdrop: 'static' }).result.then((result) => {
        if (result == 'Y') {
          this.router.navigate(['/home']);
        }
      }, (reason) => { });
    } else {
      this.router.navigate(['/home']);
    }
  }

  async getIdUser() {
    return await this.employeeService.getIdUser().then(res => {
      if (res.responseCode == 200) {
        let maxQuantity = parseInt(res.data[0].idUser) + 1;
        // console.log(maxQuantity)
        return maxQuantity
      }
    })
  }

  async createUser(employee: EmployeeModel) {
    this.loading = true;
    var checkErr = true;
    if (employee.fName == "" || employee.fName == null) {
      checkErr = false;
      this.fieldErrList.fName = true;
    }
    if (employee.lName == "" || employee.lName == null) {
      checkErr = false;
      this.fieldErrList.lName = true;
    }
    if (employee.username == "" || employee.username == null) {
      checkErr = false;
      this.fieldErrList.username = true;
    } else {
      employee.username = employee.username.toUpperCase();
    }
    if (employee.password == "" || employee.password == null) {
      checkErr = false;
      this.fieldErrList.password = true;
    } else {
      employee.passwordToken = CryptoJS.AES.encrypt(employee.password, TOKEN.LOGIN).toString();
    }
    if (employee.position == "" || employee.position == null) {
      checkErr = false;
      this.fieldErrList.position = true;
    }
    if (employee.role == "" || employee.role == null) {
      checkErr = false;
      this.fieldErrList.role = true;
    }
    // if (employee.position == 'PM') {
    //   employee.role = 'Admin'
    // } else {
    //   employee.role = 'General'
    // }
    if (checkErr && await this.checkDup(employee.username)) {
      employee.password = employee.passwordToken;
      employee.active = 'Y';
      await this.employeeService.postEmployee(employee);
      this.loading = false;
      this.employeeModel = new EmployeeModel();
      alert('success')
      this.router.navigate(['/home']);
    } else {
      this.loading = false;
    }

  }

  async checkDup(username) {
    let emp = new UserModel();
    emp.username = username;
    emp.active = "Y";
    if (username == null || username == "" || username == undefined) {
      return this.fieldErrList.username = true;
    } else {
      emp.username = emp.username.toUpperCase();
      this.employeeModel.username = emp.username.toUpperCase();
      return this.employeeService.getUserLogin(emp).then(res => {
        if (res.responseCode == 200) {
          if (res.data.length > 0) {
            return this.isOkForUsername = false;
          }
        } else {
          return this.isOkForUsername = true;
        }
      });
    }
  }

}
