import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LovModel } from '../model/lov';
import { LovService } from '../service/lov.service';
import { NgbModal } from '../../../node_modules/@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-project-all',
  templateUrl: './view-project-all.component.html',
  styleUrls: ['./view-project-all.component.css']
})
export class ViewProjectAllComponent implements OnInit {
  public loading = false;
  public projectList: Array<LovModel> = new Array<LovModel>();

  public search = {
    projectName: '',
    status: 'A'
  };
  public modal = {
    title: "",
    msg: "",
    msg2: "",
    confirm: "",
    cancel: ""
  }
  constructor(
    private router: Router,
    private LovService: LovService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.getProjectName();
  }

  back() {
    this.router.navigate(['/home']);
  }

  getProjectName() {
    this.loading = true;
    let lov = new LovModel();
    lov.lovType_key2 = "PROJECT_NAME";
    // lov.activeFlg_key1 = "Y";
    this.LovService.getLov(lov).then(res => {
      if (res.responseCode == 200) {
        this.projectList = res.data;
        this.projectList.forEach(ele => {
          if (ele.activeFlg_key1 == 'Y') {
            ele.activeFlg_key1 = 'ACTIVE'
          } else {
            ele.activeFlg_key1 = 'INACTIVE'
          }

        });
        this.loading = false;
      } else {
        this.loading = false;
      }
    })
  }

  deleteProject(content, id) {
    this.modal.title = "Confirm Delete.";
    this.modal.msg = "";
    this.modal.msg2 = "Are you sure to delete this?";
    this.modal.confirm = "Yes"
    this.modal.cancel = "No"
    this.modalService.open(content, { backdrop: 'static' }).result.then((result) => {
      if (result == 'Y') {
        let lov = new LovModel();
        lov._id = id;
        lov.activeFlg_key1 = 'N';
        this.LovService.deleteProjectLov(lov).then(res => {
          alert('success');
          this.getProjectName();
        });
      }
    }, (reason) => { });
  }

  editProject(id) {
    this.router.navigate(['/edit-project/' + id]);
  }

  newProject() {
    this.router.navigate(['/new-projects']);
  }

  searchProject(param) {
    this.loading = true;
    let lov = new LovModel();
    lov.lovType_key2 = "PROJECT_NAME";
    if (param.projectName != "" || param.projectName != undefined) {
      lov.lovName_data = param.projectName;
    }
    if (param.status != 'A') {
      lov.activeFlg_key1 = param.status;
    }
    this.LovService.getLov(lov).then(res => {
      if (res.responseCode == 200) {
        this.projectList = res.data;
        this.projectList.forEach(ele => {
          if (ele.activeFlg_key1 == 'Y') {
            ele.activeFlg_key1 = 'ACTIVE'
          } else {
            ele.activeFlg_key1 = 'INACTIVE'
          }
        });
        this.loading = false;
      } else {
        this.projectList = new Array<LovModel>();
        this.loading = false;
      }
    })
  }

}
