import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class PsLeaveModel {
    public _id: string = ""
    public idUser: string = "";
    public selectDateFrom: NgbDateStruct;
    public selectDateTo: NgbDateStruct;
    public startTime: string = "";
    public endTime: string = "";
    public year: string = "";
    public typeLeave: string = "";
    public typeLeaveName: string = "";
    public startDate: string = "";
    public endDate: string = "";
    public detail: string = "";
    public fName: string = "";
    public lName: string = "";
}

export class FieldErrPsLeave {
    public selectDateFromErr: boolean = false;
    public selectDateToErr: boolean = false;
    public startTimeErr: boolean = false;
    public endTimeToErr: boolean = false;
    public typeLeaveErr: boolean = false;
    public detail: boolean = false;
}
